export default function GetOptions(getSelectedInput) {
 
    const ALTO = 3
    const MEDIO = 2
    const BAJO = 1
    const options = {
        "clientDetailsLabel":[
            {"Corporativo":[
                {'key': 'tipo_de_cliente','value':'Tipo de cliente','position':'1'},
                {'key': 'tipo_de_indetificacion','value':'Tipo de identificación','position':'1'},
                {'key': 'numeracion_de_documento','value':'Numeración de documento','position':'1'},
                {'key': 'nombre','value':'Nombre','position':'1'},
                {'key': 'telefono','value':'Teléfono','position':'1'},
                {'key': 'direccion','value':'Dirección','position':'1'},
                {'key': 'correo_electronico','value':'Correo electrónico','position':'1'},
                {'key': 'actividad_economica','value':'Actividad económica','position':'1'},
                {'key': 'provincia','value':'Provincia','position':'1'},
                {'key': 'pep','value':'PEP','position':'1'},
                {'key': 'noticias_negativas','value':'Noticias Negativas','position':'1'},
                {'key': 'coincidencias_en_ofac','value':'Coincidencias en OFAC','position':'1'},
                {'key': 'coincidencias_en_onu','value':'Coincidencias en ONU','position':'1'},
                {'key': 'coincidencias_en_interpol','value':'Coincidencias en INTERPOL','position':'1'},
                {'key': 'fecha_de_constitucion','value':'Fecha de constitución','position':'1'},
                {'key': 'fecha_de_inicio_de_operaciones','value':'Fecha de inicio de operaciones','position':'1'},
                {'key': 'representante','value':'representante','position':'1'},
                {'key': 'pais_de_constitucion','value':'País de constitución','position':'1'},
                {'key': 'pais_de_operaciones','value':'País de operaciones','position':'1'},
                {'key': 'riesgo','value':'Riesgo','position':'4'},
                {'key': 'img_tipo_indentificacion','value':'Foto tipo de identificación','position':'2'},
                {'key': 'img_direccion','value':'Foto tipo de dirección','position':'2'},
                {'key': 'img_noticias_negativas','value':'Foto tipo de Noticia Negativa','position':'2'},
                {'key': 'img_concidencias_en_ofac','value':'Foto Coincidencia OFAC','position':'2'},
                {'key': 'img_concidencias_en_onu','value':'Foto Coincidencia ONU','position':'2'},
                {'key': 'img_concidencias_en_interpol','value':'Foto Coincidencia INTERPOL','position':'2'},
                {'key': 'forma_de_pago_inicial','value':'Forma de pago de inicial','position':'3'},
                {'key': 'moneda_pago_inicial','value':'Monto de inicial','position':'3'},
                {'key': 'valor_total_del_vehiculo','value':'Valor total del vehículo','position':'3'},
                {'key': 'moneda_pago_valor_total','value':'','position':'3'},
                {'key': 'marca_del_vehiculo','value':'Marca del vehículo','position':'3'},
                {'key': 'canal_de_venta','value':'Canal de venta','position':'3'},
                {'key': 'financiamiento','value':'Financiamiento','position':'3'},
                {'key': 'moneda_pago_financiamiento','value':'','position':'3'},
                {'key': 'forma_de_pago_del_financiamiento','value':'Forma de pago de financiamiento','position':'3'},
                
            ]},
            {"Individual":[
                {'key': 'tipo_de_cliente','value':'Tipo de cliente','position':'1'},
                {'key': 'tipo_de_indetificacion','value':'Tipo de identificación','position':'1'},
                {'key': 'numeracion_de_documento','value':'Numeración de documento','position':'1'},
                {'key': 'nombre','value':'Nombre','position':'1'},
                {'key': 'estado_civil','value':'Estado civil','position':'1'},
                {'key': 'fecha_de_nacimiento','value':'Fecha de nacimiento','position':'1'},
                {'key': 'telefono','value':'Teléfono','position':'1'},
                {'key': 'direccion','value':'Dirección','position':'1'},
                {'key': 'correo_electronico','value':'Correo electrónico','position':'1'},
                {'key': 'ocupacion','value':'Ocupación','position':'1'},
                {'key': 'situacion_laboral','value':'Situación laboral','position':'1'},
                {'key': 'ingresos_mensuales','value':'Ingresos mensuales','position':'1'},
                {'key': 'pais_de_residencia','value':'País de residencia','position':'1'},
                {'key': 'nacionalidad','value':'Nacionalidad','position':'1'},
                {'key': 'provincia','value':'Provincia','position':'1'},
                {'key': 'pep','value':'PEP','position':'1'},
                {'key': 'noticias_negativas','value':'Noticias Negativas','position':'1'},
                {'key': 'coincidencias_en_ofac','value':'Coincidencias en OFAC','position':'1'},
                {'key': 'coincidencias_en_onu','value':'Coincidencias en ONU','position':'1'},
                {'key': 'coincidencias_en_interpol','value':'Coincidencias en INTERPOL','position':'1'},
                {'key': 'riesgo','value':'Riesgo','position':'4'},
                {'key': 'img_tipo_indentificacion','value':'Foto tipo de identificación','position':'2'},
                {'key': 'img_direccion','value':'Foto tipo de dirección','position':'2'},
                {'key': 'img_noticias_negativas','value':'Foto tipo de Noticia Negativa','position':'2'},
                {'key': 'img_concidencias_en_ofac','value':'Foto Coincidencia OFAC','position':'2'},
                {'key': 'img_concidencias_en_onu','value':'Foto Coincidencia ONU','position':'2'},
                {'key': 'img_concidencias_en_interpol','value':'Foto Coincidencia INTERPOL','position':'2'},
                {'key': 'forma_de_pago_inicial','value':'Forma de pago de inicial','position':'3'},
                {'key': 'moneda_pago_inicial','value':'Monto de inicial','position':'3'},
                {'key': 'valor_total_del_vehiculo','value':'Valor total del vehículo','position':'3'},
                {'key': 'moneda_pago_valor_total','value':'','position':'3'},
                {'key': 'marca_del_vehiculo','value':'Marca del vehículo','position':'3'},
                {'key': 'canal_de_venta','value':'Canal de venta','position':'3'},
                {'key': 'financiamiento','value':'Financiamiento','position':'3'},
                {'key': 'moneda_pago_financiamiento','value':'','position':'3'},
                {'key': 'forma_de_pago_del_financiamiento','value':'Forma de pago de financiamiento','position':'3'},
            ]}],
        "Corporativo": [
            {
                'key': 'tipoClienteText',
                'value': 'Tipo de cliente'
            }, {
                'key': 'tipo_de_identificacionText',
                'value': 'Tipo de identificación'
            }, {
                'key': 'numeracion_de_documento',
                'value': 'Numeración de documento'
            }, {
                'key': 'nombre_corporativo',
                'value': 'nombre'
            }, {
                'key': 'fecha_de_constitucion',
                'value': 'Fecha de constitución'
            }, {
                'key': 'fecha_de_incio_de_operaciones',
                'value': 'Fecha de inicio de operaciones'
            }, {
                'key': 'telefono',
                'value': 'Teléfono'
            }, {
                'key': 'direccion',
                'value': 'Dirección'
            }, {
                'key': 'correo',
                'value': 'Correo electrónico'
            }, {
                'key': 'representante',
                'value': 'Representante'
            }, {
                'key': 'actividad_economicaText',
                'value': 'Actividad económica'
            }, {
                'key': 'pais_de_constitucionText',
                'value': 'País de constitución'
            }, {
                'key': 'pais_de_operacionesText',
                'value': 'País de operaciones'
            }, {
                'key': 'provinciaText',
                'value': 'Provincia'
            }, {
                'key': 'pepText',
                'value': 'PEP'
            }, {
                'key': 'forma_de_pago_de_incialText',
                'value': 'Forma de pago de inicial'
            }, {
                'key': 'monto_de_incialText',
                'value': 'Monto de inicial'
            }, {
                'key': 'valor_total_del_vehiculoText',
                'value': 'Valor total del vehículo'
            }, {
                'key': 'marca_del_vehiculoText',
                'value': 'Marca del vehículo'
            }, {
                'key': 'canal_de_ventaText',
                'value': 'Canal de venta'
            }, {
                'key': 'financiamientoText',
                'value': 'Financiamiento'
            }, {
                'key': 'forma_pago_financiamientoText',
                'value': 'Forma de pago de financiamiento'
            },
        ],
        "Individual": [
           {
               'key': 'tipoClienteText',
               'value': 'Tipo de cliente'
           }, {
               'key': 'tipo_de_identificacionText',
               'value': 'Tipo de identificación'
           }, {
               'key': 'numeracion_de_documento',
               'value': 'Numeración de documento'
           }, {
               'key': 'nombre_individual',
               'value': 'Nombre'
           }, {
               'key': 'estado_civilText',
               'value': 'Estado civil'
           }, {
               'key': 'fecha_de_nacimiento',
               'value': 'Fecha de nacimiento'
           }, {
               'key': 'telefono',
               'value': 'Teléfono'
           }, {
               'key': 'direccion',
               'value': 'Dirección'
           }, {
               'key': 'correo',
               'value': 'Correo electrónico'
           }, {
               'key': 'ocupacionText',
               'value': 'Ocupación'
           }, {
               'key': 'situacion_laboralText',
               'value': 'Situación laboral'
           }, {
               'key': 'ingresos_mensualesText',
               'value': 'Ingresos mensuales'
           }, {
               'key': 'pais_de_residenciaText',
               'value': 'País de residencia'
           }, {
               'key': 'nacionalidadText',
               'value': 'Nacionalidad'
           }, {
               'key': 'provinciaText',
               'value': 'Provincia'
           }, {
               'key': 'pepText',
               'value': 'PEP'
           }, {
               'key': 'forma_de_pago_de_incialText',
               'value': 'Forma de pago de inicial'
           }, {
               'key': 'monto_de_incialText',
               'value': 'Monto de inicial'
           }, {
               'key': 'valor_total_del_vehiculoText',
               'value': 'Valor total del vehículo'
           }, {
               'key': 'marca_del_vehiculoText',
               'value': 'Marca del vehículo'
           }, {
               'key': 'canal_de_ventaText',
               'value': 'Canal de venta'
           }, {
               'key': 'financiamientoText',
               'value': 'Financiamiento'
           }, {
               'key': 'forma_pago_financiamientoText',
               'value': 'Forma de pago de financiamiento'
           },

        ],
        "riesgo": [{
            "value": "1",
            "label": 'Bajo'
        }, {
            "value": "2",
            "label": 'Medio'
        }, {
            "value": "3",
            "label": 'Alto'
        }, ],
          "monto_de_incial": [{
                  "value": "1",
                  "label": 'De RD $0 a RD $250,000.00,'
              }, {
                  "value": "2",
                  "label": 'Entre RD $250,000.00 y RD $500,000.00'
              }, {
                  "value": "3",
                  "label": 'Mayor de RD $500,000.00'
              },
            ],
         "forma_de_pago_de_incial": [{
                 "value": "1",
                 "label": 'Transferencia'
             }, {
                 "value": "2",
                 "label": 'Cheque'
             }, {
                 "value": "3",
                 "label": 'Depósito'
             },
          

         ],
          "estado_civil": [{
                  "value": "1",
                  "label": 'Casado (a)'
              }, {
                  "value": "2",
                  "label": 'Unión libre'
              }, {
                  "value": "3",
                  "label": 'Soltero (a)'
              },
               {
                   "value": "3",
                   "label": 'Viudo (a)'
               },

          ],
        "moneda": [
            {
                "value": "RD",
                "label": 'pesos dominicanos'
            }, {
                "value": "US",
                "label": 'dólar estadounidense'
            }, {
                "value": "EU",
                "label": 'euros'
            }, {
                "value": "otra",
                "label": 'otra'
            },

        ],
        "boolean": [{
                "value": true,
                "label": 'SI'
            }, {
                "value": false,
                "label": 'NO'
            }, 
            ],
        "forma_pago_financiamiento": [
              {
                  "value": 3,
                  "label": 'Efectivo'
              }, {
                  "value": 3,
                  "label": 'Tarjeta de Credito'
              }, {
                  "value": 2,
                  "label": 'Tarjeta de Debito'
              }, {
                  "value": 2,
                  "label": 'Vehículo recibido'
              }, {
                  "value": 2,
                  "label": 'Deposito'
              }, {
                  "value": 1,
                  "label": 'Transferencias'
              }, {
                  "value": 1,
                  "label": 'Cheque'
              },
        ],
        "financiamiento": [
              {
                  "value": 3,
                  "label": 'No, la compra es de contado'
              }, {
                  "value": 2,
                  "label": 'Interno'
              }, {
                  "value": 1,
                  "label": 'Banco'
              }, {
                  "value": 1,
                  "label": 'Leasing'
              },
        ],
        "canal_de_venta": [{
                "value": 3,
                "label": 'Venta directa'
            },
            {
                "value": 2,
                "label": 'Venta por Consignatario'
            },
            {
                "value": 1,
                "label": 'Venta por Banco'
            },
        ],
        "marca_del_vehiculo": [{
                "value": 3,
                "label": 'NISSAN'
            },
            {
                "value": 3,
                "label": 'CHEVROLET'
            },
            {
                "value": 3,
                "label": 'CHANGAN'
            },
            {
                "value": 3,
                "label": 'HONDA'
            },
            {
                "value": 2,
                "label": 'SUZUKI'
            },
            {
                "value": 2,
                "label": 'TOYOTA'
            },
            {
                "value": 2,
                "label": 'HYUNDAI'
            },
            {
                "value": 2,
                "label": 'ISUZU'
            },
            {
                "value": 2,
                "label": 'MITSUBISHI'
            },
            {
                "value": 2,
                "label": 'CHANGAN '
            },
            {
                "value": 3,
                "label": 'FORD'
            },
            {
                "value": 1,
                "label": 'XCMG'
            },
            {
                "value": 1,
                "label": 'KIA'
            },
            {
                "value": 1,
                "label": 'LEXUS'
            },
            {
                "value": 1,
                "label": 'INFINITI'
            },
            {
                "value": 1,
                "label": 'VOLVO'
            },
            {
                "value": 1,
                "label": 'MAZDA'
            },
            {
                'label': 'BMW',
                'value': '3'
            }, {
                'label': 'JEEP',
                'value': '3'
            }, {
                'label': 'Land Rover',
                'value': '3'
            }, {
                'label': 'Lexus ',
                'value': '3'
            }, {
                'label': 'Mercedes Benz',
                'value': '3'
            }, {
                'label': 'Pontiac',
                'value': '3'
            }, {
                'label': 'Porsche',
                'value': '3'
            }, {
                'label': 'Toyota',
                'value': '3'
            }, {
                'label': 'Dodge',
                'value': '2'
            }, {
                'label': 'Ford ',
                'value': '2'
            }, {
                'label': 'Honda ',
                'value': '2'
            }, {
                'label': 'Kia ',
                'value': '2'
            }, {
                'label': 'Renault',
                'value': '2'
            }, {
                'label': 'Subaru',
                'value': '2'
            }, {
                'label': 'Volkswagen',
                'value': '2'
            }, {
                'label': 'Acura',
                'value': '1'
            }, {
                'label': 'Daihatsu',
                'value': '1'
            }, {
                'label': 'Mazda',
                'value': '1'
            }, {
                'label': 'Peugeot',
                'value': '1'
            },
        ],
        "valor_total_del_vehiculo": [{
                "value": 3,
                "label": 'Mayor a  RD $2,000,000'
            },
            {
                "value": 2,
                "label": 'Mayor o igual a RD $1,000,000  y menor a RD $2,000.000'
            },
            {
                "value": 1,
                "label": 'Menor a  RD $1,000,000'
            },
        ],
        "provincia": [{
                "value": ALTO,
                "label": 'DISTRITO NACIONAL'
            },
            {
                "value": MEDIO,
                "label": 'AZUA'
            },
            {
                "value": BAJO,
                "label": 'BAHORUCO'
            },
            {
                "value": MEDIO,
                "label": 'BARAHONA'
            },
            {
                "value": BAJO,
                "label": 'DAJABÓN'
            },
            {
                "value": ALTO,
                "label": 'DUARTE'
            },
            {
                "value": BAJO,
                "label": 'ELÍAS PIÑA'
            },
            {
                "value": MEDIO,
                "label": 'EL SEIBO'
            },
            {
                "value": ALTO,
                "label": 'ESPAILLAT'
            },
            {
                "value": BAJO,
                "label": 'INDEPENDENCIA'
            },
            {
                "value": ALTO,
                "label": 'LA ALTAGRACIA'
            },
            {
                "value": ALTO,
                "label": 'LA ROMANA'
            },
            {
                "value": ALTO,
                "label": 'LA VEGA'
            },
            {
                "value": MEDIO,
                "label": 'MARÍA TRINIDAD SÁNCHEZ'
            },
            {
                "value": MEDIO,
                "label": 'MONTE CRISTI'
            },
            {
                "value": BAJO,
                "label": 'PEDERNALES'
            },
            {
                "value": ALTO,
                "label": 'PERAVIA'
            },
            {
                "value": MEDIO,
                "label": 'PUERTO PLATA'
            },
            {
                "value": BAJO,
                "label": 'HERMANAS MIRABAL'
            },
            {
                "value": BAJO,
                "label": 'SAMANÁ'
            },
            {
                "value": ALTO,
                "label": 'SAN CRISTÓBAL'
            },
            {
                "value": MEDIO,
                "label": 'SAN JUAN'
            },
            {
                "value": ALTO,
                "label": 'SAN PEDRO DE MACORÍS'
            },
            {
                "value": MEDIO,
                "label": 'SANCHEZ RAMÍREZ'
            },
            {
                "value": ALTO,
                "label": 'SANTIAGO'
            },
            {
                "value": BAJO,
                "label": 'SANTIAGO RODRÍGUEZ'
            },
            {
                "value": MEDIO,
                "label": 'VALVERDE'
            },
            {
                "value": ALTO,
                "label": 'MONSEÑOR NOUEL'
            },
            {
                "value": MEDIO,
                "label": 'MONTE PLATA'
            },
            {
                "value": BAJO,
                "label": 'HATO MAYOR'
            },
            {
                "value": BAJO,
                "label": 'SAN JOSÉ DE OCOA'
            },
            {
                "value": ALTO,
                "label": 'SANTO DOMINGO'
            },
        ],
        "pais_de_residencia": [
            {
                "value": 1,
                "label": 'República Dominicana'
            },
               {
                   "value": 3,
                   "label": 'Afganistán (talibanes)'
               }, {
                   "value": 2,
                   "label": 'Islas Aland'
               }, {
                   "value": 3,
                   "label": 'Albania'
               }, {
                   "value": 3,
                   "label": 'Argelia'
               }, {
                   "value": 2,
                   "label": 'Samoa Americana'
               }, {
                   "value": 3,
                   "label": 'Andorra'
               }, {
                   "value": 3,
                   "label": 'Angola (UNITA)'
               }, {
                   "value": 3,
                   "label": 'Anguila'
               }, {
                   "value": 2,
                   "label": 'Antártida'
               }, {
                   "value": 3,
                   "label": 'Antigua y Barbuda'
               }, {
                   "value": 3,
                   "label": 'Argentina'
               }, {
                   "value": 3,
                   "label": 'Armenia'
               }, {
                   "value": 3,
                   "label": 'Aruba'
               }, {
                   "value": 2,
                   "label": 'Australia'
               }, {
                   "value": 2,
                   "label": 'Austria'
               }, {
                   "value": 3,
                   "label": 'Azerbaiyán'
               }, {
                   "value": 3,
                   "label": 'Bahamas (Las)'
               }, {
                   "value": 2,
                   "label": 'Bahrein'
               }, {
                   "value": 3,
                   "label": 'Bangladesh'
               }, {
                   "value": 3,
                   "label": 'Barbados'
               }, {
                   "value": 3,
                   "label": 'Bielorrusia'
               }, {
                   "value": 3,
                   "label": 'Bélgica'
               }, {
                   "value": 3,
                   "label": 'Belice'
               }, {
                   "value": 3,
                   "label": 'Benín'
               }, {
                   "value": 3,
                   "label": 'Bermudas'
               }, {
                   "value": 2,
                   "label": 'Bután'
               }, {
                   "value": 3,
                   "label": 'Bolivia'
               }, {
                   "value": 2,
                   "label": 'Bonaire, San Eustaquio y Saba'
               }, {
                   "value": 3,
                   "label": 'Bosnia y Herzegovina'
               }, {
                   "value": 3,
                   "label": 'Botsuana'
               }, {
                   "value": 2,
                   "label": 'Isla Bouvet'
               }, {
                   "value": 3,
                   "label": 'Brasil'
               }, {
                   "value": 2,
                   "label": 'Territorio Británico del Océano Índico'
               }, {
                   "value": 2,
                   "label": 'Brunei Darussalam'
               }, {
                   "value": 2,
                   "label": 'Bulgaria'
               }, {
                   "value": 3,
                   "label": 'Burkina Faso'
               }, {
                   "value": 3,
                   "label": 'Burundi'
               }, {
                   "value": 3,
                   "label": 'Cabo Verde- CAPE VERDE'
               }, {
                   "value": 3,
                   "label": 'Camboya'
               }, {
                   "value": 3,
                   "label": 'Camerún'
               }, {
                   "value": 2,
                   "label": 'Canadá'
               }, {
                   "value": 3,
                   "label": 'Islas Caimán'
               }, {
                   "value": 3,
                   "label": 'República Centroafricana'
               }, {
                   "value": 3,
                   "label": 'Chad'
               }, {
                   "value": 2,
                   "label": 'Chile'
               }, {
                   "value": 3,
                   "label": 'China'
               }, {
                   "value": 2,
                   "label": 'Isla de Navidad'
               }, {
                   "value": 2,
                   "label": 'Islas Cocos (Keeling)'
               }, {
                   "value": 3,
                   "label": 'Colombia'
               }, {
                   "value": 2,
                   "label": 'Comoras'
               }, {
                   "value": 3,
                   "label": 'Congo, República Democrática'
               }, {
                   "value": 3,
                   "label": 'Congo, República del'
               }, {
                   "value": 3,
                   "label": 'Islas Cook (Las)'
               }, {
                   "value": 3,
                   "label": 'Costa Rica'
               }, {
                   "value": 3,
                   "label": 'Costa de Marfil'
               }, {
                   "value": 2,
                   "label": 'Croacia'
               }, {
                   "value": 3,
                   "label": 'Cuba'
               }, {
                   "value": 3,
                   "label": 'Curazao'
               }, {
                   "value": 3,
                   "label": 'Chipre'
               }, {
                   "value": 2,
                   "label": 'República Checa'
               }, {
                   "value": 2,
                   "label": 'Dinamarca'
               }, {
                   "value": 3,
                   "label": 'Yibuti'
               }, {
                   "value": 3,
                   "label": 'Dominica'
               },  {
                   "value": 3,
                   "label": 'Ecuador'
               }, {
                   "value": 3,
                   "label": 'Egipto'
               }, {
                   "value": 3,
                   "label": 'El Salvador'
               }, {
                   "value": 3,
                   "label": 'Guinea Ecuatorial'
               }, {
                   "value": 3,
                   "label": 'Eritrea'
               }, {
                   "value": 2,
                   "label": 'Estonia'
               }, {
                   "value": 2,
                   "label": 'Eswatini'
               }, {
                   "value": 3,
                   "label": 'Etiopía'
               }, {
                   "value": 2,
                   "label": 'Islas Malvinas (Falkland Islands)'
               }, {
                   "value": 2,
                   "label": 'Islas Feroe (Dinamarca)'
               }, {
                   "value": 3,
                   "label": 'Fiyi'
               }, {
                   "value": 2,
                   "label": 'Finlandia'
               }, {
                   "value": 2,
                   "label": 'Francia'
               }, {
                   "value": 2,
                   "label": 'Guayana Francesa'
               }, {
                   "value": 2,
                   "label": 'Polinesia Francesa'
               }, {
                   "value": 2,
                   "label": 'Territorios Australes Franceses'
               }, {
                   "value": 3,
                   "label": 'Gabón'
               }, {
                   "value": 2,
                   "label": 'Gambia'
               }, {
                   "value": 3,
                   "label": 'Georgia'
               }, {
                   "value": 2,
                   "label": 'Alemania'
               }, {
                   "value": 3,
                   "label": 'Ghana'
               }, {
                   "value": 3,
                   "label": 'Gibraltar'
               }, {
                   "value": 2,
                   "label": 'Grecia'
               }, {
                   "value": 2,
                   "label": 'Groenlandia'
               }, {
                   "value": 2,
                   "label": 'Granada'
               }, {
                   "value": 2,
                   "label": 'Guadalupe'
               }, {
                   "value": 2,
                   "label": 'Guam (Territorio de Guam)'
               }, {
                   "value": 3,
                   "label": 'Guatemala'
               }, {
                   "value": 2,
                   "label": 'GUERNSEY pertenece Alderney (Channel Islands)'
               }, {
                   "value": 3,
                   "label": 'Guinea'
               }, {
                   "value": 3,
                   "label": 'Guinea -Bissau'
               }, {
                   "value": 3,
                   "label": 'Guyana'
               }, {
                   "value": 3,
                   "label": 'Haití'
               }, {
                   "value": 2,
                   "label": 'Isla Heard e Islas McDonald'
               }, {
                   "value": 3,
                   "label": 'Honduras'
               }, {
                   "value": 3,
                   "label": 'Hong Kong'
               }, {
                   "value": 2,
                   "label": 'Hungría'
               }, {
                   "value": 3,
                   "label": 'Islandia'
               }, {
                   "value": 3,
                   "label": 'India'
               }, {
                   "value": 3,
                   "label": 'Indonesia'
               }, {
                   "value": 3,
                   "label": 'Irán'
               }, {
                   "value": 3,
                   "label": 'Irak'
               }, {
                   "value": 2,
                   "label": 'Irlanda'
               }, {
                   "value": 3,
                   "label": 'Isla de Man (Reino Unido)'
               }, {
                   "value": 2,
                   "label": 'Israel'
               }, {
                   "value": 2,
                   "label": 'Italia'
               }, {
                   "value": 3,
                   "label": 'Jamaica'
               }, {
                   "value": 2,
                   "label": 'Japón'
               }, {
                   "value": 3,
                   "label": 'Jersey (Islas del Canal)'
               }, {
                   "value": 2,
                   "label": 'Jordania'
               }, {
                   "value": 3,
                   "label": 'Kazajstán'
               }, {
                   "value": 3,
                   "label": 'Kenia'
               }, {
                   "value": 2,
                   "label": 'Kiribati'
               }, {
                   "value": 2,
                   "label": 'Corea, República de'
               }, {
                   "value": 3,
                   "label": 'Kosovo'
               }, {
                   "value": 2,
                   "label": 'Kuwait'
               }, {
                   "value": 3,
                   "label": 'Kirguistán'
               }, {
                   "value": 3,
                   "label": 'República Democrática Popular de Laos'
               }, {
                   "value": 2,
                   "label": 'Letonia (Letonia) '
               }, {
                   "value": 3,
                   "label": 'Líbano'
               }, {
                   "value": 2,
                   "label": 'Lesotho'
               }, {
                   "value": 3,
                   "label": 'Liberia (Diamantes)'
               }, {
                   "value": 3,
                   "label": 'Libia'
               }, {
                   "value": 3,
                   "label": 'Liechtenstein'
               }, {
                   "value": 2,
                   "label": 'Lituania'
               }, {
                   "value": 3,
                   "label": 'Luxemburgo'
               }, {
                   "value": 3,
                   "label": 'Macao SAR'
               }, {
                   "value": 2,
                   "label": 'Macedonia Norte'
               }, {
                   "value": 2,
                   "label": 'Madagascar'
               }, {
                   "value": 2,
                   "label": 'Malawi'
               }, {
                   "value": 3,
                   "label": 'Malasia'
               }, {
                   "value": 2,
                   "label": 'Maldivas'
               }, {
                   "value": 3,
                   "label": 'Malí (República de)'
               }, {
                   "value": 3,
                   "label": 'Malta'
               }, {
                   "value": 2,
                   "label": 'Islas Marshall'
               }, {
                   "value": 2,
                   "label": 'Martinica'
               }, {
                   "value": 3,
                   "label": 'Mauritania'
               }, {
                   "value": 2,
                   "label": 'Mauricio'
               }, {
                   "value": 2,
                   "label": 'Mayotte'
               }, {
                   "value": 3,
                   "label": 'México'
               }, {
                   "value": 2,
                   "label": 'Micronesia, Estados Federados de '
               }, {
                   "value": 2,
                   "label": 'Moldavia La República de'
               }, {
                   "value": 3,
                   "label": 'Mónaco'
               }, {
                   "value": 3,
                   "label": 'Mongolia'
               }, {
                   "value": 2,
                   "label": 'Montenegro'
               }, {
                   "value": 3,
                   "label": 'Montserrat'
               }, {
                   "value": 3,
                   "label": 'Marruecos'
               }, {
                   "value": 3,
                   "label": 'Mozambique'
               }, {
                   "value": 2,
                   "label": 'Namibia'
               }, {
                   "value": 3,
                   "label": 'Nauru'
               }, {
                   "value": 3,
                   "label": 'Nepal'
               }, {
                   "value": 3,
                   "label": 'Países Bajos'
               }, {
                   "value": 2,
                   "label": 'Nueva Caledonia'
               }, {
                   "value": 2,
                   "label": 'Nueva Zelanda'
               }, {
                   "value": 3,
                   "label": 'Nicaragua'
               }, {
                   "value": 3,
                   "label": 'Níger'
               }, {
                   "value": 3,
                   "label": 'Nigeria'
               }, {
                   "value": 3,
                   "label": 'Niue'
               }, {
                   "value": 2,
                   "label": 'Isla de Norfolk'
               }, {
                   "value": 3,
                   "label": 'Corea del Norte (DPRK) - Corea, República Popular Democrática de'
               }, {
                   "value": 2,
                   "label": 'Islas Marianas del Norte (las) '
               }, {
                   "value": 2,
                   "label": 'Noruega'
               }, {
                   "value": 2,
                   "label": 'Omán'
               }, {
                   "value": 3,
                   "label": 'Pakistán'
               }, {
                   "value": 3,
                   "label": 'Palau'
               }, {
                   "value": 3,
                   "label": 'Palestina, Estado de '
               }, {
                   "value": 3,
                   "label": 'Panamá'
               }, {
                   "value": 3,
                   "label": 'Papúa Nueva Guinea'
               }, {
                   "value": 3,
                   "label": 'Paraguay'
               }, {
                   "value": 3,
                   "label": 'Perú'
               }, {
                   "value": 3,
                   "label": 'Filipinas (Las) '
               }, {
                   "value": 2,
                   "label": 'Pitcairn'
               }, {
                   "value": 2,
                   "label": 'Polonia'
               }, {
                   "value": 2,
                   "label": 'Portugal'
               }, {
                   "value": 2,
                   "label": 'Puerto Rico'
               }, {
                   "value": 2,
                   "label": 'Qatar'
               }, {
                   "value": 2,
                   "label": 'Reunión'
               }, {
                   "value": 3,
                   "label": 'Rumanía'
               }, {
                   "value": 3,
                   "label": 'Federación de Rusia'
               }, {
                   "value": 2,
                   "label": 'Ruanda'
               }, {
                   "value": 2,
                   "label": 'San Bartolomé'
               }, {
                   "value": 2,
                   "label": 'Santa Elena, Ascensión y Tristán da Cunha'
               }, {
                   "value": 2,
                   "label": 'San Martín (parte francesa)'
               }, {
                   "value": 2,
                   "label": 'San Pedro y Miquelón'
               }, {
                   "value": 3,
                   "label": 'Samoa'
               }, {
                   "value": 3,
                   "label": 'San Marino '
               }, {
                   "value": 3,
                   "label": 'SANTO TOMÉ Y PRÍNCIPE'
               }, {
                   "value": 2,
                   "label": 'Arabia Saudí'
               }, {
                   "value": 3,
                   "label": 'Senegal'
               }, {
                   "value": 3,
                   "label": 'Serbia'
               }, {
                   "value": 3,
                   "label": 'Seychelles'
               }, {
                   "value": 3,
                   "label": 'Sierra Leona'
               }, {
                   "value": 3,
                   "label": 'Singapur'
               }, {
                   "value": 3,
                   "label": 'Sint Maarten (parte holandesa)'
               }, {
                   "value": 2,
                   "label": 'Eslovaquia'
               }, {
                   "value": 2,
                   "label": 'Eslovenia'
               }, {
                   "value": 3,
                   "label": 'Islas Salomón'
               }, {
                   "value": 3,
                   "label": 'Somalia'
               }, {
                   "value": 3,
                   "label": 'Sudáfrica'
               }, {
                   "value": 3,
                   "label": 'Georgia del Sur y las Islas Sandwich del Sur'
               }, {
                   "value": 3,
                   "label": 'Sudán del Sur'
               }, {
                   "value": 2,
                   "label": 'España'
               }, {
                   "value": 3,
                   "label": 'Sri Lanka'
               }, {
                   "value": 3,
                   "label": 'San Cristóbal y Nieves'
               }, {
                   "value": 3,
                   "label": 'Santa Lucía'
               }, {
                   "value": 3,
                   "label": 'San Vicente y las Granadinas'
               }, {
                   "value": 3,
                   "label": 'Sudán'
               }, {
                   "value": 3,
                   "label": 'Surinam'
               }, {
                   "value": 2,
                   "label": 'Svalbard y Jan Mayen'
               }, {
                   "value": 2,
                   "label": 'Suecia'
               }, {
                   "value": 2,
                   "label": 'Suiza'
               }, {
                   "value": 3,
                   "label": 'República Árabe Siria'
               }, {
                   "value": 3,
                   "label": 'Provincia china de Taiwán'
               }, {
                   "value": 3,
                   "label": 'Tayikistán'
               }, {
                   "value": 3,
                   "label": 'Tanzania, República Unida de'
               }, {
                   "value": 3,
                   "label": 'Tailandia'
               }, {
                   "value": 3,
                   "label": 'Timor Oriental'
               }, {
                   "value": 2,
                   "label": 'Togo'
               }, {
                   "value": 2,
                   "label": 'Tokelau'
               }, {
                   "value": 2,
                   "label": 'Tonga'
               }, {
                   "value": 3,
                   "label": 'Trinidad y Tobago'
               }, {
                   "value": 3,
                   "label": 'Túnez'
               }, {
                   "value": 3,
                   "label": 'Turquía'
               }, {
                   "value": 3,
                   "label": 'Turkmenistán'
               }, {
                   "value": 3,
                   "label": 'Turcas y Caicos'
               }, {
                   "value": 3,
                   "label": 'Tuvalu'
               }, {
                   "value": 3,
                   "label": 'Uganda'
               }, {
                   "value": 3,
                   "label": 'Ucrania'
               }, {
                   "value": 3,
                   "label": 'UNIÓN DE MYANMAR (Birmania)'
               }, {
                   "value": 3,
                   "label": 'Emiratos Árabes Unidos'
               }, {
                   "value": 2,
                   "label": 'Reino Unido '
               }, {
                   "value": 2,
                   "label": 'Estados Unidos de América '
               }, {
                   "value": 2,
                   "label": 'Islas Menores de los Estados Unidos (las) [ac]'
               }, {
                   "value": 2,
                   "label": 'Uruguay'
               }, {
                   "value": 3,
                   "label": 'Uzbekistán'
               }, {
                   "value": 3,
                   "label": 'Vanuatu'
               }, {
                   "value": 3,
                   "label": 'CIUDAD DEL VATICANO (LA SANTA SEDE)'
               }, {
                   "value": 3,
                   "label": 'Venezuela (República Bolivariana de)'
               }, {
                   "value": 3,
                   "label": 'Vietnam'
               }, {
                   "value": 3,
                   "label": 'Islas Vírgenes (británicas)'
               }, {
                   "value": 3,
                   "label": 'Islas Vírgenes (EE.UU.)'
               }, {
                   "value": 2,
                   "label": 'Wallis y Futuna'
               }, {
                   "value": 2,
                   "label": 'Sahara Occidental'
               }, {
                   "value": 3,
                   "label": 'Yemen'
               }, {
                   "value": 3,
                   "label": 'Zambia'
               }, {
                   "value": 3,
                   "label": 'Zimbabue'
               },
        ],

        "ingresos_mensuales": [{
                "value": 1,
                "label": 'Mayor a RD $200,000'
            },
            {
                "value": 2,
                "label": 'Mayor o igual a RD $100,000 y menor a RD $200.000'
            },
            {
                "value": 3,
                "label": 'Menor a RD $100,000'
            },
        ],
        "situacion_laboral": [{
                "value": 1,
                "label": 'Asalariado Privado'
            },
            {
                "value": 1,
                "label": 'Asalariado Público'
            },
            {
                "value": 2,
                "label": 'Recibe Ingresos Familiares'
            },
            {
                "value": 2,
                "label": 'Recibe Remesas'
            },
            {
                "value": 2,
                "label": 'Desocupado'
            },
            {
                "value": 2,
                "label": 'Microempresa o Microempresario Constituido en Sociedad (Persona Física o Jurídica)'
            },
            {
                "value": 3,
                "label": 'Trabajador Cuenta Propia No Profesional (Técnicos, Vendedores, Etc.)'
            },
            {
                "value": 3,
                "label": 'Jubilado/a o Pensionado/a'
            },
            {
                "value": 3,
                "label": 'Recibe Rentas '
            },
            {
                "value": 3,
                "label": 'Trabajador Cuenta Propia Profesional (Médicos, Abogados, Ingenieros, Arquitectos, Contadores, Consultores, Etc.)'
            },
            {
                "value": 3,
                "label": 'Microempresario No Constituido en Sociedad (Patrón con dos o más empleados) '
            },
            {
                "value": 3,
                "label": 'Empresario PYME / Patrón de PYME'
            },
            {
                "value": 3,
                "label": 'Empresario Corporativo / Patrón Empresas o Grupo Corporativo'
            },
            {
                "value": 100,
                "label": 'PEP'
            },
        ],
        "actividad_economica":[
              {
                  "value": 3,
                  "label": 'AGRICULTURA, GANADERÍA, CAZA Y SILVICULTURA'
              }, {
                  "value": 3,
                  "label": 'Agricultura, ganadería, caza y actividades de servicios conexos.'
              }, {
                  "value": 3,
                  "label": 'Cultivos en General; cultivo de productos de mercado; Horticultura.'
              }, {
                  "value": 3,
                  "label": 'Cultivo de cereales y otros cultivos N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Cultivo de arroz'
              }, {
                  "value": 3,
                  "label": 'Cultivo de trigo'
              }, {
                  "value": 3,
                  "label": 'Cultivo de cebada'
              }, {
                  "value": 3,
                  "label": 'Cultivo de centeno'
              }, {
                  "value": 3,
                  "label": 'Cultivo de avena'
              }, {
                  "value": 3,
                  "label": 'Cultivo de cereales excepto los forrajeros y las semillas N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Cultivo de maíz'
              }, {
                  "value": 3,
                  "label": 'Cultivo de sorgo en grano'
              }, {
                  "value": 3,
                  "label": 'Cultivo de cereales forrajeros N. C. P. (incluye alpiste, cebada forrajera, mijo)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de semillas de soja'
              }, {
                  "value": 3,
                  "label": 'Cultivo de girasol'
              }, {
                  "value": 3,
                  "label": 'Cultivo de cacahuetes'
              }, {
                  "value": 3,
                  "label": 'Cultivo de granos de mostaza'
              }, {
                  "value": 3,
                  "label": 'Cultivo de colza'
              }, {
                  "value": 3,
                  "label": 'Cultivo de semilla de cártamo'
              }, {
                  "value": 3,
                  "label": 'Cultivo de ajonjolí'
              }, {
                  "value": 3,
                  "label": 'Cultivo de oleaginosas N. C. P. (incluye cultivo de oleaginosas para aceites comestibles y/o uso industrial)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de pastos forrajeros (incluye alfalfa, moho, sorgo azucarado y forrajero, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de zanahorias'
              }, {
                  "value": 3,
                  "label": 'Cultivo de nabos'
              }, {
                  "value": 3,
                  "label": 'Cultivo de ajo'
              }, {
                  "value": 3,
                  "label": 'Cultivo de cebolla'
              }, {
                  "value": 3,
                  "label": 'Cultivo de puerros y demás hortalizas albaceas'
              }, {
                  "value": 3,
                  "label": 'Otros cultivos de raíces y bulbos'
              }, {
                  "value": 3,
                  "label": 'Cultivo de setas y trufas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de otras hortalizas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de hortalizas, legumbres y productos de vivero'
              }, {
                  "value": 3,
                  "label": 'Cultivo de papas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de batatas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de yuca'
              }, {
                  "value": 3,
                  "label": 'Cultivo de ñame'
              }, {
                  "value": 3,
                  "label": 'Cultivo de otras raíces y tubérculos'
              }, {
                  "value": 3,
                  "label": 'Cultivo de tomates'
              }, {
                  "value": 3,
                  "label": 'Cultivo de pepinos y pepinillos'
              }, {
                  "value": 3,
                  "label": 'Cultivo de berenjenas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de sandias'
              }, {
                  "value": 3,
                  "label": 'Cultivo de melones y otros frutales'
              }, {
                  "value": 3,
                  "label": 'Cultivo de bulbos, brotes, raíces y hortalizas de frutos N. C. P. (incluye ají, ajo, alcaparra, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de hortalizas de hoja y de otras hortalizas frescas (incluye acelga, apio, choclo, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de alcachofas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de espárragos'
              }, {
                  "value": 3,
                  "label": 'Cultivo de coles'
              }, {
                  "value": 3,
                  "label": 'Cultivo de brócolis y coliflor'
              }, {
                  "value": 3,
                  "label": 'Cultivo de lechuga'
              }, {
                  "value": 3,
                  "label": 'Cultivo de espinaca'
              }, {
                  "value": 3,
                  "label": 'Otros cultivos de hojas o tallos vegetales'
              }, {
                  "value": 3,
                  "label": 'Cultivo de legumbres frescas (incluye arveja, garbanzos, poroto, judía, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de legumbres secas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de frijoles'
              }, {
                  "value": 3,
                  "label": 'Cultivo de habas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de guisantes'
              }, {
                  "value": 3,
                  "label": 'Cultivo de lentejas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de otras leguminosas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de flores'
              }, {
                  "value": 3,
                  "label": 'Cultivo de plantas de interior y viveros'
              }, {
                  "value": 3,
                  "label": 'Cultivo de vegetales para la plantación'
              }, {
                  "value": 3,
                  "label": 'Cultivo de plantas para fines ornamentales, incluyendo césped para trasplantar'
              }, {
                  "value": 3,
                  "label": 'Cultivo de plantas vivas para los bulbos, tubérculos, y raíces, esquejes e injertos'
              }, {
                  "value": 3,
                  "label": 'Cultivo de frutas, nueces, plantas cuyas hojas se utilizan para preparar bebidas y especies'
              }, {
                  "value": 3,
                  "label": 'Cultivo de manzana'
              }, {
                  "value": 3,
                  "label": 'Cultivo de albaricoque'
              }, {
                  "value": 3,
                  "label": 'Cultivo de pera'
              }, {
                  "value": 3,
                  "label": 'Cultivo de frutas con pepita N. C. P. (incluye membrillo, níspero,  etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de frutas de carozo (incluye ciruela, damasco, pelón, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de melocotones'
              }, {
                  "value": 3,
                  "label": 'Cultivo de ciruela'
              }, {
                  "value": 3,
                  "label": 'Otros cultivos de frutas con hueso y con pepa'
              }, {
                  "value": 3,
                  "label": 'Cultivo de frutas cítricas (incluye kinoto, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de toronjas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de limones y lima'
              }, {
                  "value": 3,
                  "label": 'Cultivo de naranjas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de mandarinas'
              }, {
                  "value": 3,
                  "label": 'Otros cultivos de frutas cítricas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de nueces y frutas secas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de almendra'
              }, {
                  "value": 3,
                  "label": 'Cultivo de nueces de anacardo'
              }, {
                  "value": 3,
                  "label": 'Cultivo de castaña de caju'
              }, {
                  "value": 3,
                  "label": 'Cultivo de avellanas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de pistacho'
              }, {
                  "value": 3,
                  "label": 'Cultivo de nueces'
              }, {
                  "value": 3,
                  "label": 'Cultivo de otras frutas secas comestible'
              }, {
                  "value": 3,
                  "label": 'Producción de bananas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de aguacate'
              }, {
                  "value": 3,
                  "label": 'Cultivo de higo'
              }, {
                  "value": 3,
                  "label": 'Cultivo de mago'
              }, {
                  "value": 3,
                  "label": 'Cultivo de lechosa'
              }, {
                  "value": 3,
                  "label": 'Cultivo de piña'
              }, {
                  "value": 3,
                  "label": 'Otros cultivos de frutas tropicales y subtropicales'
              }, {
                  "value": 3,
                  "label": 'Cultivo de uvas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de frutas N. C. P. (incluye cultivo de mamón)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de blueberries'
              }, {
                  "value": 3,
                  "label": 'Cultivo de corintos'
              }, {
                  "value": 3,
                  "label": 'Cultivo de grosella'
              }, {
                  "value": 3,
                  "label": 'Cultivo de kiwi'
              }, {
                  "value": 3,
                  "label": 'Cultivo de frambuesa'
              }, {
                  "value": 3,
                  "label": 'Cultivo de fresa'
              }, {
                  "value": 3,
                  "label": 'Cultivo de otras bayas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de algodón'
              }, {
                  "value": 3,
                  "label": 'Cultivo de yute, el kenaf y otras fibras textiles'
              }, {
                  "value": 3,
                  "label": 'Cultivo de lino y cáñamo'
              }, {
                  "value": 3,
                  "label": 'Cultivo de sisal y demás fibras textiles del genero agave'
              }, {
                  "value": 3,
                  "label": 'Cultivo de abacá, ramio y demás fibras textiles vegetales'
              }, {
                  "value": 3,
                  "label": 'Cultivo de otras fibras'
              }, {
                  "value": 3,
                  "label": 'Cultivo de plantas para la obtención de fibras N. C. P. (incluye cultivo de formio, maíz de guinea, ramio, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de caña de azúcar'
              }, {
                  "value": 3,
                  "label": 'Cultivo de plantas sacaríferas N. C. P. (incluye el cultivo de remolacha azucarera, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de vid para vinificar'
              }, {
                  "value": 3,
                  "label": 'Cultivo de plantas cuyas hojas se utilizan para preparar bebidas (infusiones)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de plantas para la elaboración de te'
              }, {
                  "value": 3,
                  "label": 'Cultivo de plantas para la elaboración de mate'
              }, {
                  "value": 3,
                  "label": 'Cultivo de otros productos para la preparación de bebidas'
              }, {
                  "value": 3,
                  "label": 'Cultivo de tabaco'
              }, {
                  "value": 3,
                  "label": 'Cultivo de especias (de hojas, de semillas, de flor y de fruto) y de plantas aromáticas y medicinales'
              }, {
                  "value": 3,
                  "label": 'Cultivo de pimienta'
              }, {
                  "value": 3,
                  "label": 'Cultivo de chiles y pimientos'
              }, {
                  "value": 3,
                  "label": 'Cultivo de nuez moscada'
              }, {
                  "value": 3,
                  "label": 'Cultivo de anís, badianas e hinojos'
              }, {
                  "value": 3,
                  "label": 'Cultivo de canela'
              }, {
                  "value": 3,
                  "label": 'Cultivo de jengibre'
              }, {
                  "value": 3,
                  "label": 'Cultivo de vainilla'
              }, {
                  "value": 3,
                  "label": 'Cultivo de café y cacao'
              }, {
                  "value": 3,
                  "label": 'Cultivo de cacao'
              }, {
                  "value": 3,
                  "label": 'Cultivos industriales N. C. P. (incluye el cultivo de olivo para conserva, palmitos, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cultivo de árboles de caucho'
              }, {
                  "value": 3,
                  "label": 'Cultivo de árboles de navidad'
              }, {
                  "value": 3,
                  "label": 'Cultivo de árboles para la extracción de savia'
              }, {
                  "value": 3,
                  "label": 'Cultivo de materias vegetales de las especies utilizadas principalmente para el trenzado'
              }, {
                  "value": 3,
                  "label": 'Producción de semillas hibridas de cereales y oleaginosas'
              }, {
                  "value": 3,
                  "label": 'Producción de semillas varietales o autofecundadas de cereales, oleaginosas y forrajeras'
              }, {
                  "value": 3,
                  "label": 'Producción de semillas de hortalizas y legumbres, flores y plantas ornamentales y árboles frutales'
              }, {
                  "value": 3,
                  "label": 'Cultivo de semillas de hortalizas, excepto la semilla de remolacha'
              }, {
                  "value": 3,
                  "label": 'Cultivo de semillas de remolacha (excluye la remolacha azucarera) y semillas de plantas forrajeras'
              }, {
                  "value": 3,
                  "label": 'Cultivo de semillas de frutas'
              }, {
                  "value": 3,
                  "label": 'Producción de semillas de cultivos agrícolas N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Producción de otras formas de propagación de cultivos agrícolas (incluye gajos, bulbos, estacas enraizadas o no, esquejes, plantines, etc.)'
              }, {
                  "value": 3,
                  "label": 'Productos agrícolas en general'
              }, {
                  "value": 3,
                  "label": 'Cría de animales'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado vacuno y de ovejas, cabras caballo, asnos, mulas y burgedanos, cría de ganado lechero'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado bovino –excepto en cabañas y para la producción de leche'
              }, {
                  "value": 3,
                  "label": 'Invernada de ganado bovino excepto el engorde en corrales (fed-lot)'
              }, {
                  "value": 3,
                  "label": 'Engorde en corrales (fed-lot)'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado ovino, excepto en cabañas y para la producción de lana'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado porcino, excepto en cabañas'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado equino, excepto en haras (incluye cría de equinos de trabajo)'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado caprino, excepto en cabañas para la producción de leche'
              }, {
                  "value": 3,
                  "label": 'Producción de lana ovina y caprina'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado bovino en cabañas (incluye la producción de semen)'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado ovino, porcino y caprino en cabañas (incluye la producción de semen)'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado equino en haras (incluye la producción de semen)'
              }, {
                  "value": 3,
                  "label": 'Cría en cabañas de ganado N. C. P,'
              }, {
                  "value": 3,
                  "label": 'Producción de leche de ganado bovino'
              }, {
                  "value": 3,
                  "label": 'Centro de acopio de leche de ganado bovino'
              }, {
                  "value": 3,
                  "label": 'Producción de leche de ganado N. C. P. (incluye la cría de búfalos, cabra, etc. Para la producción de leche)'
              }, {
                  "value": 3,
                  "label": 'Producción de lana'
              }, {
                  "value": 3,
                  "label": 'Producción de pelos (incluye la cría de caprinos, camélidos, etc. Para la obtención de pelos)'
              }, {
                  "value": 3,
                  "label": 'Cría de ganado N. C. P. (incluye cría de alpaca, asno, guacano, llama, vicuña, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cría de otros animales y elaboración de productos para animales N.C.P.'
              }, {
                  "value": 3,
                  "label": 'Cría de aves para la producción de carne'
              }, {
                  "value": 3,
                  "label": 'Cría de aves para la producción de huevos (incluye pollitos BB para postura)'
              }, {
                  "value": 3,
                  "label": 'Producción de huevo'
              }, {
                  "value": 3,
                  "label": 'Apicultura (incluye la producción de miel, jalea real, polen, propolen, etc.)'
              }, {
                  "value": 3,
                  "label": 'Cría de animales para la obtención de pieles y cueros'
              }, {
                  "value": 3,
                  "label": 'Cría de animales para la obtención de pelos'
              }, {
                  "value": 3,
                  "label": 'Cría de animales para la obtención de plumas'
              }, {
                  "value": 3,
                  "label": 'Sericultura (cría de gusanos de seda)'
              }, {
                  "value": 3,
                  "label": 'Cría de avestruces y emus'
              }, {
                  "value": 3,
                  "label": 'Cría de otras aves excepto las de corral'
              }, {
                  "value": 3,
                  "label": 'Cría de insectos'
              }, {
                  "value": 3,
                  "label": 'Cría de gatos y perros'
              }, {
                  "value": 3,
                  "label": 'Cría de aves como pericos, etc.'
              }, {
                  "value": 3,
                  "label": 'Cría de hámster, etc.'
              }, {
                  "value": 3,
                  "label": 'Cría de animales y obtención de productos de origen animal N. C. P. (incluye ciervo, conejo excepto para pelo, lombriz, etc.)'
              }, {
                  "value": 3,
                  "label": 'Actividades de servicios agrícolas y a la ganadería excepto los veterinarios'
              }, {
                  "value": 3,
                  "label": 'Servicios de labranza, siembra, trasplante y cuidados culturales'
              }, {
                  "value": 3,
                  "label": 'Servicios de pulverización, desinfección y fumigación de áreas y terrenos'
              }, {
                  "value": 3,
                  "label": 'Estabilización de cultivos'
              }, {
                  "value": 3,
                  "label": 'Tratamiento de cultivos'
              }, {
                  "value": 3,
                  "label": 'Trasplante de arroz'
              }, {
                  "value": 3,
                  "label": 'Servicios de maquinaria agrícola N. C. P. excepto los de cosecha mecánica (incluye enfardado, enrollado, envasado silo pack, etc.)'
              }, {
                  "value": 3,
                  "label": 'Servicios de cosecha mecánica (incluye la cosecha mecánica de granos, caña de azúcar, algodón, forrajes, el enfardado, enrollado, etc.)'
              }, {
                  "value": 3,
                  "label": 'Servicios de contratistas de mano de obra agrícola (incluye la poda de árboles, trasplante, fumigación y desinfección manual, etc.)'
              }, {
                  "value": 3,
                  "label": 'Control de plaga (incluidos los conejos) en relación a la agricultura'
              }, {
                  "value": 3,
                  "label": 'Servicios agrícolas N. C. P. (incluye planificación y diseño paisajista, plantación y mantenimiento de jardines, parques y cementerios)'
              }, {
                  "value": 3,
                  "label": 'Operación de equipos de riego agrícola'
              }, {
                  "value": 3,
                  "label": 'Mantenimiento de la tierra para uso agrícola'
              }, {
                  "value": 3,
                  "label": 'Inseminación artificial y servicios N. C. P. para mejorar la reproducción de los animales y el rendimiento de sus productos'
              }, {
                  "value": 3,
                  "label": 'Actividades para promover la propagación, el crecimiento y la producción de animales'
              }, {
                  "value": 3,
                  "label": 'Servicios de contratistas de mano de obra pecuaria (incluye el arreo, castración de ovejas, recolección de estiércol, etc.)'
              }, {
                  "value": 3,
                  "label": 'Servicios para el control de plagas, baños parasiticidas, etc.'
              }, {
                  "value": 3,
                  "label": 'Albergue y cuidado de animales de terceros'
              }, {
                  "value": 3,
                  "label": 'Embarcado y cuidado de animales de granja'
              }, {
                  "value": 3,
                  "label": 'Servicios pecuarios N. C. P. excepto los veterinarios'
              }, {
                  "value": 3,
                  "label": 'Caza y captura de animales vivos, repoblación de animales de caza y servicios conexos'
              }, {
                  "value": 3,
                  "label": 'Caza y captura de animales vivos (incluye la caza de animales para obtener carne, pieles, cueros, etc.)'
              }, {
                  "value": 3,
                  "label": 'Repoblación de animales'
              }, {
                  "value": 3,
                  "label": 'Caza y captura con fines comerciales'
              }, {
                  "value": 3,
                  "label": 'Caza y captura de animales vivos para la extracción de pieles, cueros o para el uso en investigaciones científicas'
              }, {
                  "value": 3,
                  "label": 'Captura de animales vivos para zoológicos o como animales de compañía'
              }, {
                  "value": 3,
                  "label": 'Captura en tierra de mamíferos marinos como la foca o la morsa'
              }, {
                  "value": 3,
                  "label": 'Servicios para la caza'
              }, {
                  "value": 3,
                  "label": 'Silvicultura y extracción de madera'
              }, {
                  "value": 3,
                  "label": 'Planeación de bosques'
              }, {
                  "value": 3,
                  "label": 'Cultivo de monte'
              }, {
                  "value": 3,
                  "label": 'Repoblación y conservación de bosques nativos y zonas forestadas'
              }, {
                  "value": 3,
                  "label": 'Replantación de árboles'
              }, {
                  "value": 3,
                  "label": 'Aclareo de bosques'
              }, {
                  "value": 3,
                  "label": 'Explotación de viveros forestales (incluye la propagación de especies forestales)'
              }, {
                  "value": 3,
                  "label": 'Extracción de productos forestales de bosques cultivados (incluye la tala de árboles, devaste de troncos y producción de madera en bruto)'
              }, {
                  "value": 3,
                  "label": 'Extracción de productos forestales de bosques nativos (incluye tala de árboles, devaste de troncos y producción de madera en bruto, leña)'
              }, {
                  "value": 3,
                  "label": 'Recopilación y producción de leña'
              }, {
                  "value": 3,
                  "label": 'Producción de carbón de leña en los bosques (utilizando los métodos tradicionales)'
              }, {
                  "value": 3,
                  "label": 'Recolección de productos silvestres'
              }, {
                  "value": 3,
                  "label": 'Recolección de bayas'
              }, {
                  "value": 3,
                  "label": 'Recolección de frutos secos'
              }, {
                  "value": 3,
                  "label": 'Recolección de lacas y resinas'
              }, {
                  "value": 3,
                  "label": 'Recolección de bálsamos'
              }, {
                  "value": 3,
                  "label": 'Recolección de bellotas'
              }, {
                  "value": 3,
                  "label": 'Recolección de musgos y líquenes'
              }, {
                  "value": 3,
                  "label": 'Servicios forestales de extracción de madera (incluye tala de árboles, acarreo y transporte en el interior del bosque, servicios realizados de terceros)'
              }, {
                  "value": 3,
                  "label": 'Producción de madera en rollo basado en las industrias manufactureras'
              }, {
                  "value": 3,
                  "label": 'Servicios forestales excepto los relacionados con la extracción de madera (incluye la protección contra incendios, evaluación de masas forestales)'
              }, {
                  "value": 3,
                  "label": 'Transporte de troncos dentro del bosque'
              }, {
                  "value": 3,
                  "label": 'Otras actividades relacionadas con la silvicultura N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Inventarios forestales'
              }, {
                  "value": 3,
                  "label": 'Servicios de consultaría sobre gestión de bosques'
              }, {
                  "value": 3,
                  "label": 'Evaluación de madera'
              }, {
                  "value": 3,
                  "label": 'Lucha contra los incendios forestales'
              }, {
                  "value": 3,
                  "label": 'Control de plagas forestales'
              }, {
                  "value": 3,
                  "label": 'PESCA'
              }, {
                  "value": 3,
                  "label": 'Pesca y servicios conexos'
              }, {
                  "value": 3,
                  "label": 'Pesca marítima (incluye peces, crustáceos, moluscos, mariscos excepto camarones)'
              }, {
                  "value": 3,
                  "label": 'Pesca de camarones'
              }, {
                  "value": 3,
                  "label": 'Pesca de altamar, costera y en estuarios'
              }, {
                  "value": 3,
                  "label": 'Caza de ballena, foca, lobo marino y otros mamíferos marinos'
              }, {
                  "value": 3,
                  "label": 'Ostricultura'
              }, {
                  "value": 3,
                  "label": 'Captura de tortugas, erizos de mar, etc.'
              }, {
                  "value": 3,
                  "label": 'Pesca continental, fluvial y lacustre'
              }, {
                  "value": 3,
                  "label": 'Recolección de crustáceos y moluscos de agua dulce'
              }, {
                  "value": 3,
                  "label": 'Captura de animales de agua dulce'
              }, {
                  "value": 3,
                  "label": 'Recolección de productos marinos (incluye la recolección de algas marinas y otras plantas acuáticas, corales, esponjas)'
              }, {
                  "value": 3,
                  "label": 'Explotación de criaderos de peces, granjas piscícolas y otros frutos acuáticos (acuicultura)'
              }, {
                  "value": 3,
                  "label": 'Cría de peces ornamentales'
              }, {
                  "value": 3,
                  "label": 'Cultivo de laver y otros tipos de algas comestibles'
              }, {
                  "value": 3,
                  "label": 'Producción de larvas de camarones y alevines'
              }, {
                  "value": 3,
                  "label": 'Actividades de acuicultura de agua salada en tanques o reservorios'
              }, {
                  "value": 3,
                  "label": 'Actividades de acuicultura en aguas salobre'
              }, {
                  "value": 3,
                  "label": 'Criaderos de peces marinos'
              }, {
                  "value": 3,
                  "label": 'Criaderos de gusanos marinos'
              }, {
                  "value": 3,
                  "label": 'Acuicultura en agua dulce'
              }, {
                  "value": 3,
                  "label": 'Cría de peces ornamentales de agua dulce'
              }, {
                  "value": 3,
                  "label": 'Cría de crustáceos, moluscos y bivalvos'
              }, {
                  "value": 3,
                  "label": 'Cría de otros animales acuáticos'
              }, {
                  "value": 3,
                  "label": 'Criaderos de peces de agua dulce'
              }, {
                  "value": 3,
                  "label": 'Criaderos de ranas'
              }, {
                  "value": 3,
                  "label": 'Servicios para la pesca'
              }, {
                  "value": 3,
                  "label": 'EXPLOTACIÓN DE MINAS Y CANTERAS'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de carbón de piedra'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de carbón (incluye la producción de hulla no aglomerada, antracita, carbón bituminoso no aglomerado, briqueta)'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de carbón mediante explotación minera a cielo abierto'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de carbón a través de métodos de licuefacción'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de lignito (carbón marrón)'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de lignito (incluye la producción de lignito aglomerado y no aglomerado)'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de lignito mediante explotación minera a cielo abierto'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de lignito a través de métodos de licuefacción'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de turba'
              }, {
                  "value": 3,
                  "label": 'Extracción y aglomeración de turba (incluye la producción de turba utilizada como corrector de suelo)'
              }, {
                  "value": 3,
                  "label": 'Aglomeración de turba'
              }, {
                  "value": 3,
                  "label": 'Preparación de la turba'
              }, {
                  "value": 3,
                  "label": 'Extracción de petróleo crudo y gas natural'
              }, {
                  "value": 3,
                  "label": 'Extracción de petróleo crudo y gas natural (incluye gas natural licuado y gaseoso, arenas alquitraniferas, esquistos bituminosos, etc.)'
              }, {
                  "value": 3,
                  "label": 'Extracción de aceites bituminosos o de esquistos bituminosos o de arena de alquitrán'
              }, {
                  "value": 3,
                  "label": 'Producción de petróleo extraído del esquito bituminoso y arena de alquitrán'
              }, {
                  "value": 3,
                  "label": 'Procesos para obtener aceites crudos: decantación, desalinización, deshidratación, estabilización, etc.'
              }, {
                  "value": 3,
                  "label": 'Producción de crudo de hidrocarburos gaseosos (gas natural)'
              }, {
                  "value": 3,
                  "label": 'Extracción de condensados'
              }, {
                  "value": 3,
                  "label": 'Drenaje y separación de hidrocarburos líquido'
              }, {
                  "value": 3,
                  "label": 'Desulfurización de gas'
              }, {
                  "value": 3,
                  "label": 'Actividades de servicios relacionados con la extracción de petróleo y gas, excepto las actividades de prospección'
              }, {
                  "value": 3,
                  "label": 'Actividades de servicios relacionados con la extracción de petróleo y gas, excepto las actividades de prospección'
              }, {
                  "value": 3,
                  "label": 'Servicios de perforación direccional y redireccionado, reparación y desmantelamiento, cementación de petróleo y gas, bombeo de los pozos, taponamiento y abandono de los pozos'
              }, {
                  "value": 3,
                  "label": 'Licuefacción y regasificación del gas natural con fines de transporte'
              }, {
                  "value": 3,
                  "label": 'Servicios de drenaje y bombeo por contrato o por comisión'
              }, {
                  "value": 3,
                  "label": 'Servicios de prueba de perforación en relación a la extracción de petróleo o gas'
              }, {
                  "value": 3,
                  "label": 'Extracción de minerales y concentrados de uranio y torio'
              }, {
                  "value": 3,
                  "label": 'Extracción de minerales y concentrados uranio y torio'
              }, {
                  "value": 3,
                  "label": 'Extracción de pichtblende'
              }, {
                  "value": 3,
                  "label": 'Concentración de esos minerales'
              }, {
                  "value": 3,
                  "label": 'Extracción de minerales de hierro'
              }, {
                  "value": 3,
                  "label": 'Extracción de minerales de hierro (incluye hematitas, limonitas, magnetitas, siderita, etc.)'
              }, {
                  "value": 3,
                  "label": 'Aglomeración de minerales de hierro'
              }, {
                  "value": 3,
                  "label": 'Extracción de minerales metalíferos no ferrosos, excepto minerales de uranio y torio'
              }, {
                  "value": 3,
                  "label": 'Extracción de minerales metalíferos no ferrosos, excepto minerales de uranio y torio (incluye aluminio, níquel)'
              }, {
                  "value": 3,
                  "label": 'Extracción de cobre'
              }, {
                  "value": 3,
                  "label": 'Extracción de plomo, zinc y estaño'
              }, {
                  "value": 3,
                  "label": 'Extracción de magnesio, cromo, cobalto, molibdeno, tantalio, etc.'
              }, {
                  "value": 3,
                  "label": 'Extracción de metales preciosos: oro, plata y platino'
              }, {
                  "value": 3,
                  "label": 'Extracción de piedra, arena y arcilla'
              }, {
                  "value": 3,
                  "label": 'Extracción de rocas ornamentales (incluye areniscas, cuarcita, dolomita, granito, mármol, piedra laja, pizarra, pórfido, serpentina, etc.)'
              }, {
                  "value": 3,
                  "label": 'Extracción de piedra caliza y yeso (incluye caliza, castina, conchilla, riolita, etc.)'
              }, {
                  "value": 3,
                  "label": 'Minería de yeso y anhidrita'
              }, {
                  "value": 3,
                  "label": 'Extracción de tiza y dolomita'
              }, {
                  "value": 3,
                  "label": 'Extracción de arena, canto rodado y triturado pétreos (incluye la arena para la construcción, arena silícea, otras arenas naturales, canto rodado, etc.)'
              }, {
                  "value": 3,
                  "label": 'Extracción y dragado de arena industrial'
              }, {
                  "value": 3,
                  "label": 'Extracción y dragado de arena para la construcción y grava'
              }, {
                  "value": 3,
                  "label": 'Trituración de piedra y grava'
              }, {
                  "value": 3,
                  "label": 'Extracción de arcilla y caolín (incluye andalucita, arcilla, bentonita, caolín, piriofilita, silimanita, mullita, tierra de chamota o de dinas, etc.)'
              }, {
                  "value": 3,
                  "label": 'Extracción de minerales para la fabricación de fertilizantes y productos químicos'
              }, {
                  "value": 3,
                  "label": 'Extracción de minerales para la fabricación de abonos excepto turba (incluye silvita, silvinita y otras sales de potasio naturales)'
              }, {
                  "value": 3,
                  "label": 'Extracción de minerales para la fabricación de productos químicos (incluye azufre, boracita e hidroboracita, calcita, celestina, celestina, etc.)'
              }, {
                  "value": 3,
                  "label": 'Extracción y preparación de piritas y pirrotina'
              }, {
                  "value": 3,
                  "label": 'Minería de sulfato de bario y carbonato (baritina y witherita)'
              }, {
                  "value": 3,
                  "label": 'Extracción de boratos naturales y sulfato de magnesio natural'
              }, {
                  "value": 3,
                  "label": 'Minería de tierras colorantes y fluorita'
              }, {
                  "value": 3,
                  "label": 'Extracción de guano'
              }, {
                  "value": 3,
                  "label": 'Extracción de sal'
              }, {
                  "value": 3,
                  "label": 'Extracción de sal de roca incluida la molienda'
              }, {
                  "value": 3,
                  "label": 'Extracción de sal en salinas y de roca'
              }, {
                  "value": 3,
                  "label": 'Trituración, perforación y refinado de sal'
              }, {
                  "value": 3,
                  "label": 'Explotación de otras minas y canteras N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Servicios directos al sistema minero'
              }, {
                  "value": 3,
                  "label": 'Servicios de exploración'
              }, {
                  "value": 3,
                  "label": 'Servicios de drenaje y bombeo por comisión o por contrato'
              }, {
                  "value": 3,
                  "label": 'Perforación y prueba de pozos taladrados'
              }, {
                  "value": 3,
                  "label": 'Explotación de minas y canteras N. C. P. (incluye aminato, baritina, cuarzo, diatomita, piedra pómez, ágata, agua marina)'
              }, {
                  "value": 3,
                  "label": 'Extracción de materiales abrasivos, asbesto, silicios fósiles, granito natural, esteatita, feldespato, etc.'
              }, {
                  "value": 3,
                  "label": 'Extracción de asfalto naturales, asfaltito y roca; betún natural sólido'
              }, {
                  "value": 3,
                  "label": 'Extracción de piedras preciosas, cuarzo, mica, etc.'
              }, {
                  "value": 3,
                  "label": 'INDUSTRIA MANUFACTURERA'
              }, {
                  "value": 3,
                  "label": 'Elaboración de productos alimenticios y bebidas'
              }, {
                  "value": 3,
                  "label": 'Producción, procesamiento y conservación de carnes y productos cárnicos'
              }, {
                  "value": 3,
                  "label": 'Matadero de ganado bovino'
              }, {
                  "value": 3,
                  "label": 'Procesamiento de carne de ganado bovino (incluye los mataderos y frigoríficos que sacrifican principalmente ganado bovino)'
              }, {
                  "value": 3,
                  "label": 'Saladero y peladero de cueros de ganado bovino'
              }, {
                  "value": 3,
                  "label": 'Procesamiento de carne fresca refrigerada o congelada en trozos'
              }, {
                  "value": 3,
                  "label": 'Procesamiento de carne fresca refrigeradas o congeladas en porciones individuales'
              }, {
                  "value": 3,
                  "label": 'Matadero y procesamiento de carne de aves'
              }, {
                  "value": 3,
                  "label": 'Elaboración de fiambres y embutidos'
              }, {
                  "value": 3,
                  "label": 'Matadero de ganado excepto el bovino y el procesamiento de su carne (incluye ganado ovino, porcino, equino, búfalo, etc.)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aceites y grasas de origen animal comestible'
              }, {
                  "value": 3,
                  "label": 'Matanza de animales N. C. P. y procesamiento de su carne; elaboración de subproductos cárnicos N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Procesamiento y conservación de pescado, crustáceos y moluscos'
              }, {
                  "value": 3,
                  "label": 'Elaboración de productos de pescados de mar, crustáceos y productos marinos N. C. P'
              }, {
                  "value": 3,
                  "label": 'Elaboración de productos de pescados de río y lagunas y otros productos fluviales y lacustres'
              }, {
                  "value": 3,
                  "label": 'Fabricación de harina, aceite y grasa a base de pescado N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Procesamiento de peces, crustáceos y moluscos: pescado cocido, filetes de pescado, huevas, caviar, sucedáneos de caviar, etc.)'
              }, {
                  "value": 3,
                  "label": 'Producción de comida a base de pescado y otros animales acuáticos no aptas para el consumo humano'
              }, {
                  "value": 3,
                  "label": 'Elaboración y conservación de frutas, legumbres y hortalizas'
              }, {
                  "value": 3,
                  "label": 'Preparación de conserva de frutas, hortalizas y legumbres'
              }, {
                  "value": 3,
                  "label": 'Conservación de frutas, frutos secos u hortalizas: congelación, secado, inmersión en aceite o en vinagre, enlatado, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de jugos naturales y sus concentrados de frutas y hortalizas'
              }, {
                  "value": 3,
                  "label": 'Elaboración y envasado de dulces, mermeladas y jaleas'
              }, {
                  "value": 3,
                  "label": 'Elaboración de frutas, hortalizas y legumbres congeladas'
              }, {
                  "value": 3,
                  "label": 'Preparación y congelación de papas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de puré de papas deshidratado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de snacks de papas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de harina de papa'
              }, {
                  "value": 3,
                  "label": 'Fabricación de alimentos de frutos y pastas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de concentrados de frutas y hortalizas frescas'
              }, {
                  "value": 3,
                  "label": 'Elaboración de frutas, hortalizas y legumbres deshidratadas o disecadas'
              }, {
                  "value": 3,
                  "label": 'Ensaladas'
              }, {
                  "value": 3,
                  "label": 'Pelado y cortado de verduras'
              }, {
                  "value": 3,
                  "label": 'Elaboración de tofu'
              }, {
                  "value": 2,
                  "label": 'Elaboración de aceites y grasas de origen vegetal y animal'
              }, {
                  "value": 2,
                  "label": 'Elaboración de aceites y grasas vegetales sin refinar y sus subproductos; elaboración de aceite virgen'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite de oliva'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite de frijol, soja'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite de palma'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite de semilla de girasol'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite de semilla de algodón'
              }, {
                  "value": 2,
                  "label": 'Elaboración de aceites y grasas vegetales refinadas (no incluye aceite de maíz)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite de oliva refinado'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite de soja refinado'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite de frijol refinado'
              }, {
                  "value": 2,
                  "label": 'Transformación de aceite vegetal mediante soplado'
              }, {
                  "value": 2,
                  "label": 'Transformación de aceite vegetal mediante ebullición'
              }, {
                  "value": 2,
                  "label": 'Transformación de aceite vegetal mediante deshidratación o hidrogenación'
              }, {
                  "value": 2,
                  "label": 'Elaboración de margarinas y grasas vegetales'
              }, {
                  "value": 2,
                  "label": 'Elaboración de aceite y grasa animal no comestible'
              }, {
                  "value": 2,
                  "label": 'Elaboración de productos lácteos'
              }, {
                  "value": 2,
                  "label": 'Elaboración de leches, productos lácteos deshidratados (incluye la estandarización, homogenización, pasterización y esterilizado)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de leche líquida fresca pasteurizada'
              }, {
                  "value": 2,
                  "label": 'Fabricación de leche líquida fresca esterilizada'
              }, {
                  "value": 2,
                  "label": 'Fabricación de leche líquida fresca homogeneizada'
              }, {
                  "value": 2,
                  "label": 'Fabricación de crema de leche fresca pasteurizada'
              }, {
                  "value": 2,
                  "label": 'Fabricación de crema de leche fresca esterilizada'
              }, {
                  "value": 2,
                  "label": 'Fabricación de crema de leche fresca homogeneizada'
              }, {
                  "value": 2,
                  "label": 'Elaboración de queso (incluye la producción de suero)'
              }, {
                  "value": 2,
                  "label": 'Fabricación industrial de helados (no incluye las heladerías artesanales)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de productos lácteos N. C. P. (incluye la producción de caseintos lácteos, cremas, manteca, etc.)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de leche o nata en estado sólido'
              }, {
                  "value": 2,
                  "label": 'Fabricación de mantequilla'
              }, {
                  "value": 2,
                  "label": 'Fabricación de yogurt'
              }, {
                  "value": 2,
                  "label": 'Elaboración de productos de molinería'
              }, {
                  "value": 2,
                  "label": 'Molienda de trigo'
              }, {
                  "value": 2,
                  "label": 'Producción de harina de centeno'
              }, {
                  "value": 2,
                  "label": 'Producción de avena'
              }, {
                  "value": 2,
                  "label": 'Preparación de arroz'
              }, {
                  "value": 2,
                  "label": 'Factoría de arroz'
              }, {
                  "value": 2,
                  "label": 'Producción de harina de arroz'
              }, {
                  "value": 2,
                  "label": 'Elaboración de alimentos a base de cereales'
              }, {
                  "value": 2,
                  "label": 'Fabricación de galletas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de panqueques'
              }, {
                  "value": 2,
                  "label": 'Producción de harina o sémola de frutos secos comestible'
              }, {
                  "value": 2,
                  "label": 'Preparación y molienda de legumbres y cereales N. C. P. (excepto trigo)'
              }, {
                  "value": 2,
                  "label": 'Elaboración de almidones y productos derivados del almidón'
              }, {
                  "value": 2,
                  "label": 'Elaboración de almidones y productos derivados del almidón (incluye la elaboración de glucosa, gluten, aceites de maíz)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de glucosa, jarabe de glucosa, maltosa, inulina, etc.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de gluten'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite de maíz'
              }, {
                  "value": 2,
                  "label": 'Elaboración de alimentos preparados para animales'
              }, {
                  "value": 2,
                  "label": 'Elaboración de alimentos preparados para animales'
              }, {
                  "value": 2,
                  "label": 'Fabricación de alimentos para perros y gatos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de alimentos para aves, peces, etc.'
              }, {
                  "value": 2,
                  "label": 'Preparación de mezcla (única) para la alimentación de animales de granja'
              }, {
                  "value": 2,
                  "label": 'Elaboración de productos de panadería'
              }, {
                  "value": 2,
                  "label": 'Fabricación de galletitas y biscochos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de tortas, pasteles, tartas, etc.'
              }, {
                  "value": 2,
                  "label": 'Elaboración industrial de productos de panadería, excluidas galletas y bizcochos'
              }, {
                  "value": 2,
                  "label": 'Elaboración de masas y productos de pastelería'
              }, {
                  "value": 2,
                  "label": 'Fabricación de pan y panecillos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de productos de aperitivos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de tortillas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de productos de panadería congelados: panqueques, wafles, rollos, etc.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de productos de panadería N. C. P. (incluye elaboración de churros, prepizas, masas fritas, hojaldre, etc.)'
              }, {
                  "value": 3,
                  "label": 'Elaboración de azúcar'
              }, {
                  "value": 3,
                  "label": 'Elaboración de azúcar'
              }, {
                  "value": 3,
                  "label": 'Elaboración de azúcar de la remolacha'
              }, {
                  "value": 3,
                  "label": 'Fabricación de jarabe de azúcar'
              }, {
                  "value": 3,
                  "label": 'Fabricación de melaza'
              }, {
                  "value": 3,
                  "label": 'Producción de jarabe de arce y azúcar'
              }, {
                  "value": 3,
                  "label": 'Elaboración de productos de confitería de cacao y chocolate'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cacao, chocolate y productos a base de cacao'
              }, {
                  "value": 2,
                  "label": 'Fabricación de chocolates y confitería de chocolate'
              }, {
                  "value": 2,
                  "label": 'Fabricación de goma de mascar'
              }, {
                  "value": 2,
                  "label": 'Preservación en azúcar de frutas, nueces, cáscaras de frutas'
              }, {
                  "value": 2,
                  "label": 'Elaboración de productos de confiterías N. C. P. (incluye caramelos, frutas confitadas, pastillas, etc.)'
              }, {
                  "value": 2,
                  "label": 'Elaboración de macarrones, fideos alcuzcuz y productos farináceos'
              }, {
                  "value": 2,
                  "label": 'Elaboración de pastas alimentarias frescas'
              }, {
                  "value": 2,
                  "label": 'Elaboración de pastas alimentarias secas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cuscús'
              }, {
                  "value": 2,
                  "label": 'Fabricación de conservas o congelados de pastas'
              }, {
                  "value": 2,
                  "label": 'Elaboración de otros productos alimenticios N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Tostado, torrado, y molienda de café'
              }, {
                  "value": 3,
                  "label": 'Elaboración y molienda de hierbas aromáticas y especias'
              }, {
                  "value": 3,
                  "label": 'Torrefacción de café'
              }, {
                  "value": 3,
                  "label": 'Café soluble'
              }, {
                  "value": 3,
                  "label": 'Extractos y concentrados de café'
              }, {
                  "value": 3,
                  "label": 'Fabricación de sucedáneos de café'
              }, {
                  "value": 3,
                  "label": 'Preparación de hojas de te'
              }, {
                  "value": 3,
                  "label": 'Elaboración de té y yerba mate'
              }, {
                  "value": 3,
                  "label": 'Mezcla de té y yerba mate'
              }, {
                  "value": 3,
                  "label": 'Elaboración de yerba mate'
              }, {
                  "value": 3,
                  "label": 'Elaboración de extractos, jarabes y concentrados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vinagre'
              }, {
                  "value": 3,
                  "label": 'Fabricación de levadura'
              }, {
                  "value": 3,
                  "label": 'Preparados para lactantes'
              }, {
                  "value": 3,
                  "label": 'Preparación de alimentos con ingredientes homogeneizados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de mostaza'
              }, {
                  "value": 3,
                  "label": 'Fabricación de salsa de tomate'
              }, {
                  "value": 3,
                  "label": 'Elaboración de productos alimenticios N. C. P. (incluye la elaboración de polvos para preparar postres y gelatinas)'
              }, {
                  "value": 3,
                  "label": 'Destilación, rectificación y mezcla de bebidas alcohólicas'
              }, {
                  "value": 3,
                  "label": 'Destilación de alcohol etílico'
              }, {
                  "value": 3,
                  "label": 'Destilación, rectificación y mezcla de bebidas alcohólicas'
              }, {
                  "value": 3,
                  "label": 'Destilado de whisky'
              }, {
                  "value": 3,
                  "label": 'Fabricación de brandy'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ginebra'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ron'
              }, {
                  "value": 3,
                  "label": 'Mezcla de bebidas alcohólicas'
              }, {
                  "value": 2,
                  "label": 'Elaboración de vinos'
              }, {
                  "value": 2,
                  "label": 'Elaboración de vinos (incluye el fraccionamiento)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de vino a base de concentrado de mosto de uva'
              }, {
                  "value": 2,
                  "label": 'Elaboración de sidra, y otras bebidas alcohólicas fermentadas a partir de frutas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de vinos espumosos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de sidra'
              }, {
                  "value": 2,
                  "label": 'Fabricación de sake'
              }, {
                  "value": 2,
                  "label": 'Fabricación de otros vinos de frutas y bebidas mezcladas con contenido alcohólico'
              }, {
                  "value": 2,
                  "label": 'Fabricación de vermut y similares'
              }, {
                  "value": 2,
                  "label": 'Elaboración de bebidas no alcohólicas y aguas minerales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cerveza, bebidas malteadas y de malta'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cerveza negra'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maltas'
              }, {
                  "value": 3,
                  "label": 'Embotellado de aguas naturales y minerales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de sodas y agua tónica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bebidas no alcohólicas excepto cervezas sin alcohol y vino'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aguas endulzadas y/o aromatizadas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bebidas gaseosas exceptuando la soda'
              }, {
                  "value": 3,
                  "label": 'Elaboración de hielo, jugos envasados para diluir y otras bebidas no alcohólicas'
              }, {
                  "value": 3,
                  "label": 'Elaboración de productos de tabaco'
              }, {
                  "value": 3,
                  "label": 'Preparación de hoja de tabaco'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tabaco para masticar'
              }, {
                  "value": 3,
                  "label": 'Elaboración de cigarrillos y cigarros'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cigarros'
              }, {
                  "value": 3,
                  "label": 'Elaboración de productos de tabaco N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Preparación e hilatura de fibras textiles, tejeduras de productos textiles'
              }, {
                  "value": 3,
                  "label": 'Desmontado de algodón y preparación de fibras de algodón'
              }, {
                  "value": 3,
                  "label": 'Preparación de fibras textiles vegetales excepto de algodón (incluye la preparación de fibras de yute, ramio, cáñamo y limo)'
              }, {
                  "value": 3,
                  "label": 'Devanado y lavado de seda'
              }, {
                  "value": 3,
                  "label": 'Carbonizado de la lana y el tenido de la lana vellón'
              }, {
                  "value": 3,
                  "label": 'Preparación de fibras animales de uso textil, incluso lavado de lana'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hilados de lana y sus mezclas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hilados de algodón y sus mezclas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hilados textiles excepto de lana y algodón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos planos de lana y sus mezclas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos planos de algodón y sus mezclas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos planos de fibras manufacturadas y de seda'
              }, {
                  "value": 3,
                  "label": 'Fabricación de toallas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos de fibra de vidrio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos de carbono y los hilos de aramida'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos planos de fibras textiles N. C. P. (incluye heladerías y tenedurías integradas)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de tejeduría N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Acabado de productos textiles'
              }, {
                  "value": 3,
                  "label": 'Acabado de productos textiles'
              }, {
                  "value": 3,
                  "label": 'Secado, vaporizado, reducción y reparación de productos textiles'
              }, {
                  "value": 3,
                  "label": 'Blanqueo de jeans'
              }, {
                  "value": 3,
                  "label": 'Impregnación o revestimiento de prendas de vestir para la impermeabilización'
              }, {
                  "value": 3,
                  "label": 'Serigrafiado de prendas de vestir'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos confeccionados de materiales textiles, tejeduras de productos textiles, excepto prendas de vestir'
              }, {
                  "value": 3,
                  "label": 'Fabricación de frazadas, mantas, ponchos, colchas, cobertores, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ropa de cama, para mesa y de tocador o cocina'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de lonas y sucedáneos de lona'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bolsas de materiales textiles para productos a granel'
              }, {
                  "value": 3,
                  "label": 'Fabricación de edredones, cojines, almohadas, sacos de dormir, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cortinas, doseles, colchas, cubre muebles, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bandera, pancartas, banderines, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de partes de mantas eléctricas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos confeccionados de materiales textiles excepto prendas de vestir N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tapices y alfombras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de alfombras, tapetes y baldosas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de agujas, revestimientos para el suelo'
              }, {
                  "value": 3,
                  "label": 'Fabricación cuerda, cordeles, bramante'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cuerdas, cordeles, bramantes y redes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de redes anudadas de cordeles, cuerdas o cordajes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de redes para pesca'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cojines de descarga'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cuerdas o cables equipados con aro de metal'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros textiles N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos estrecho, incluidos los tejidos que consisten en deformar sin trama reunidos por medio de un adhesivo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de etiquetas, insignias, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de trenzas, borlas, pompones, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tules y otros tejidos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de telas impregnadas, recubiertas, revestidas o estratificadas con plásticos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hilados metalizados o entorchado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos para cuerdas de neumáticos de alta tenacidad'
              }, {
                  "value": 3,
                  "label": 'Fabricación de lienzos preparados para el uso del pintor'
              }, {
                  "value": 3,
                  "label": 'Fabricación de becarán y telas rígidas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de telas recubiertas con goma de mascar o de materiales amiláceas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de mechas textiles y manguitos de incandescencia'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cintas transportadoras'
              }, {
                  "value": 3,
                  "label": 'Espigado de tela'
              }, {
                  "value": 3,
                  "label": 'Fabricación de adornos para automóviles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cintas de tela sensibles a la presión'
              }, {
                  "value": 3,
                  "label": 'Fabricación de calzado de encaje'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos de punto y artículos de punto y ganchillo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de medias'
              }, {
                  "value": 3,
                  "label": 'Fabricación de suéteres y artículos similares de punto'
              }, {
                  "value": 3,
                  "label": 'Fabricación de jerseys'
              }, {
                  "value": 3,
                  "label": 'Fabricación de chalecos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros artículos similares'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tejidos y artículos de punto N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de abrigos, trajes, conjuntos, chaquetas, pantalones, faldas, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de camisas, camisetas y blusas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de prendas de vestir'
              }, {
                  "value": 3,
                  "label": 'Fabricación de prendas de vestir, excepto prendas de piel'
              }, {
                  "value": 2,
                  "label": 'Confección de ropa interior, prendas de vestir para dormir y para la playa'
              }, {
                  "value": 2,
                  "label": 'Confección de indumentaria de trabajo, uniformes, guardapolvos y sus accesorios'
              }, {
                  "value": 2,
                  "label": 'Confección de indumentaria para bebés y niños'
              }, {
                  "value": 2,
                  "label": 'Confección de indumentaria deportiva'
              }, {
                  "value": 2,
                  "label": 'Confección de trajes de baño'
              }, {
                  "value": 2,
                  "label": 'Confección de pilotos e impermeables'
              }, {
                  "value": 3,
                  "label": 'Fabricación de accesorios de vestir excepto de cuero'
              }, {
                  "value": 1,
                  "label": 'Diseñadores de alta costura'
              }, {
                  "value": 3,
                  "label": 'Fabricación de guantes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cinturones'
              }, {
                  "value": 3,
                  "label": 'Fabricación de chales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de corbatas'
              }, {
                  "value": 2,
                  "label": 'Confección de prendas de vestir N. C. P. excepto las de piel, cuero y sucedáneos, pilotos e impermeables'
              }, {
                  "value": 3,
                  "label": 'Fabricación de accesorios de vestir de cuero'
              }, {
                  "value": 2,
                  "label": 'Confección de prendas de vestir de cuero'
              }, {
                  "value": 3,
                  "label": 'Adobo y teñido de pieles, fabricación de artículos de piel'
              }, {
                  "value": 3,
                  "label": 'Confección de prendas de vestir de piel y sucedáneos'
              }, {
                  "value": 3,
                  "label": 'Terminación y teñido de cueros'
              }, {
                  "value": 3,
                  "label": 'Ensamblado de pieles de peletería, placas, esteras, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de piel N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Preparación, curtido y acabado del cuero, fabricación de maletas, bolsos de mano, artículos de talabartería y guarnicionería, y calzado'
              }, {
                  "value": 3,
                  "label": 'Curtido y adobo de cueros'
              }, {
                  "value": 3,
                  "label": 'Curtido y terminación de cueros'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vestidos de gamuza, vestidos pergamino, patente o cueros metalizados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de componentes de cuero'
              }, {
                  "value": 3,
                  "label": 'Raspado, curtido, decoloración y teñido de pieles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maletas, bolsos de mano y artículos de talabartería y guarnicionaría'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maletas, bolsos de mano, y similares, artículos de talabartería y artículos de cuero N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de talabartería y artículos de cuero N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de guarnicionaría o talabartería'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bandas de relojes no metálicas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cinturones de conducción, envases etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cordón de cuero'
              }, {
                  "value": 3,
                  "label": 'Fabricación de calzado y sus partes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de calzado de cuero, excepto el ortopédico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de calzado de tela, plástico, goma, caucho y otros materiales, excepto los ortopédicos y de asbesto'
              }, {
                  "value": 3,
                  "label": 'Fabricación de las partes de calzados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de las partes internas y externas de los calzados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tacones, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de polainas, botines y artículos análogos'
              }, {
                  "value": 3,
                  "label": 'Producción de madera y fabricación de productos de madera y corcho, excepto muebles'
              }, {
                  "value": 3,
                  "label": 'Aserraderos y cepillado de madera'
              }, {
                  "value": 3,
                  "label": 'Aserrado y cepillado de madera'
              }, {
                  "value": 3,
                  "label": 'Corte en rodaja de la madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pisos de madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de lana de madera, harina de madera, astillas'
              }, {
                  "value": 3,
                  "label": 'Secado de madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hojas de madera para enchapado fabricación de tableros y contrachapados, tableros laminados y paneles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hojas de madera para enchapado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tableros contrachapados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tableros laminados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tableros y paneles N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tableros de partículas de fibras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de madera desinfectada'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pegamento para madera estratificada, laminado de chapas madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de partes y piezas de carpintería para edificios y construcciones'
              }, {
                  "value": 3,
                  "label": 'Fabricación de puertas y ventanas de madera para la construcción'
              }, {
                  "value": 3,
                  "label": 'Fabricación de viviendas prefabricadas de madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vigas y estructuras para techo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de escaleras y barandillas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de molduras, tejas y listones de madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bloques de suelo de parquet montados en paneles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de casas móviles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tabiques de madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de recipientes de madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de recipientes de madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cajas y otras plataformas de carga'
              }, {
                  "value": 3,
                  "label": 'Fabricación de barriles, cubos, tubas y otros productos de madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de carretes de madera para cables'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros productos de madera, fabricación de artículos de corcho, paja y materiales trenzables'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de cestería, caña y mimbre'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ataúdes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de madera en tornería'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de corcho'
              }, {
                  "value": 3,
                  "label": 'Fabricación de marcos de madera para espejos y fotos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de marcos de madera para obras de arte'
              }, {
                  "value": 3,
                  "label": 'Fabricación de utensilios de madera para el hogar y utensilios de cocina'
              }, {
                  "value": 3,
                  "label": 'Fabricación de estatuillas de madera y objetos de adornos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de madera N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de mangos de madera para paraguas, bastones y similares'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de la riqueza natural o aglomerado de corcho, incluida revestimiento para suelo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de alfombras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de esteras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pantallas, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel y de los productos de papel'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pulpa, papel y cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pulpa de madera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel y cartón excepto envases'
              }, {
                  "value": 3,
                  "label": 'Fabricación, blanqueo y semi-blanqueado de pasta de papel por medios mecánicos o químicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pulpa a partir de la borra de algodón'
              }, {
                  "value": 3,
                  "label": 'Revestimiento y la impregnación de papel y cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel crêped y papel plegado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de laminados con papel y cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel a mano'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel prensa'
              }, {
                  "value": 3,
                  "label": 'Fabricación de guata de celulosa y napa de fibras de celulosa'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel carbón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel y cartón ondulado y envases de papel y cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de envases de papel'
              }, {
                  "value": 3,
                  "label": 'Fabricación de envases de cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel y cartón corrugado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de contenedores de papel o cartón corrugado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de envases plegables de cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de envases a partir de residuos sólidos de cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de sacos y bolsas de papel'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cajas de archivos para oficinas y artículos similares'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros artículos de papel y cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de papel y cartón de uso domestico e higiénico sanitario'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tisúes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel higiénico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de toallas sanitarias, tampones, pañales y mantillas para bebes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tazas, platos y bandejas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel para la impresión'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel para equipos de impresión'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel engomado o papel adhesivo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de papel y cartón N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de sobres y tarjetas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de registros y libros, carpeta'
              }, {
                  "value": 3,
                  "label": 'Fabricación de álbumes y similares'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cajas, sobres y presentaciones similares con un surtido de artículos de papelería'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel pintado y revestimientos similares de paredes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vinilo recubierto de textil y papel tapiz'
              }, {
                  "value": 3,
                  "label": 'Fabricación de etiquetas (labels)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel y cartón filtro'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bandejas de pulpa moldeada'
              }, {
                  "value": 3,
                  "label": 'Actividades de impresión y reproducción de grabaciones'
              }, {
                  "value": 3,
                  "label": 'Actividades de impresión y servicios conexos'
              }, {
                  "value": 3,
                  "label": 'Edición de libros, folletos, partituras y otras publicaciones'
              }, {
                  "value": 3,
                  "label": 'Edición de libros, folletos, partituras y otras publicaciones'
              }, {
                  "value": 3,
                  "label": 'Impresión de libros y folletos'
              }, {
                  "value": 3,
                  "label": 'Impresión de manuscritos, mapas, atlas, carteles, catalogo de publicidad y otros impresos publicitarios'
              }, {
                  "value": 3,
                  "label": 'Fotograbado, flexográfica y otras prensas, maquinas de duplicación, impresoras de computadora'
              }, {
                  "value": 3,
                  "label": 'Impresión directa sobre materiales textiles, plásticos, vidrio, metal, madera y cerámica'
              }, {
                  "value": 3,
                  "label": 'Impresión en las etiquetas o tags'
              }, {
                  "value": 3,
                  "label": 'Edición de periódicos, revistas y publicaciones periódicas'
              }, {
                  "value": 3,
                  "label": 'Edición de grabaciones'
              }, {
                  "value": 3,
                  "label": 'Edición de grabaciones'
              }, {
                  "value": 3,
                  "label": 'Otras actividades de edición'
              }, {
                  "value": 3,
                  "label": 'Edición N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Actividades de impresión'
              }, {
                  "value": 3,
                  "label": 'Impresión de periódicos, revistas'
              }, {
                  "value": 3,
                  "label": 'Impresión excepto de periódicos, revistas'
              }, {
                  "value": 3,
                  "label": 'Actividades de servicios relacionados con la impresión'
              }, {
                  "value": 3,
                  "label": 'Servicios relacionados con la impresión'
              }, {
                  "value": 3,
                  "label": 'Preparación de planchas o troqueles para la impresión o estampación'
              }, {
                  "value": 3,
                  "label": 'Servicios de elaboración de placas'
              }, {
                  "value": 3,
                  "label": 'Servicios de gravado en cilindros y placas'
              }, {
                  "value": 3,
                  "label": 'Servicios de elaboración de trofeos'
              }, {
                  "value": 3,
                  "label": 'Obras artísticas incluidas la preparación de piedra litográfica'
              }, {
                  "value": 3,
                  "label": 'Diseño de productos de imprenta, bocetos y maniquíes'
              }, {
                  "value": 3,
                  "label": 'Reproducción de grabaciones'
              }, {
                  "value": 3,
                  "label": 'Reproducción de grabaciones'
              }, {
                  "value": 3,
                  "label": 'Reproducción de DVD, cintas con imágenes en movimiento y otras grabaciones de video'
              }, {
                  "value": 3,
                  "label": 'Reproducción de copias masters de software y datos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de coque y de productos de refinación del petróleo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de producto de horno de coque'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de hornos de coque'
              }, {
                  "value": 2,
                  "label": 'Producción de coque y semi coque'
              }, {
                  "value": 2,
                  "label": 'Producción de brea y coque de brea'
              }, {
                  "value": 2,
                  "label": 'Producción de coque en horno de gas'
              }, {
                  "value": 2,
                  "label": 'Producción de crudo de alquitrán, de carbón y de lignito alquitranes'
              }, {
                  "value": 2,
                  "label": 'Aglomeración de coque'
              }, {
                  "value": 3,
                  "label": 'Fabricación de los productos de la refinación del petróleo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de combustibles de la refinación del petróleo'
              }, {
                  "value": 3,
                  "label": 'Producción de fuel oil ligero, medio y pesado, refinería de gases como el etano, butano, propano, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aceite lubricante a base de aceites o grasas incluidos aceite usado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos para la industria petroquímica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos para el revestimiento de carretera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vaselina'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cera de parafina'
              }, {
                  "value": 3,
                  "label": 'Fabricación de carbón duro y lignito y briquetas combustibles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de briquetas de petróleo'
              }, {
                  "value": 3,
                  "label": 'Mezcla de biocarburantes, es decir mezcla de alcohol con petróleo'
              }, {
                  "value": 3,
                  "label": 'Elaboración de combustible nuclear'
              }, {
                  "value": 3,
                  "label": 'Fabricación de combustible nuclear'
              }, {
                  "value": 3,
                  "label": 'Fabricación de reactores nucleares'
              }, {
                  "value": 3,
                  "label": 'Fabricación de sustancias químicas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de sustancias químicas básicas, abonos, compuestos de nitrógeno, plásticos y caucho'
              }, {
                  "value": 3,
                  "label": 'Fabricación de sustancias químicas básicas, excepto abonos y compuestos de nitrógeno'
              }, {
                  "value": 3,
                  "label": 'Fabricación de gases comprimidos y licuados'
              }, {
                  "value": 3,
                  "label": 'Producción de aire comprimido'
              }, {
                  "value": 3,
                  "label": 'Producción de gases refrigerantes'
              }, {
                  "value": 3,
                  "label": 'Producción de gases mixtos industriales'
              }, {
                  "value": 3,
                  "label": 'Producción de gases inertes como el dióxido de carbono'
              }, {
                  "value": 3,
                  "label": 'Fabricación de curtientes naturales y sintéticos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materias colorantes básicas, excepto pigmentos preparados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de elementos químicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materia química inorgánica básica N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de álcalis, lejía y otros compuestos inorgánicos de bases, salvo el amoniaco'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros compuestos inorgánicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materias químicas orgánicas básicas N. C. P. (incluye fabricación de alcoholes excepto el etílico, sustancias químicas para laboratorios'
              }, {
                  "value": 3,
                  "label": 'Elaboración de hidrocarburos acíclicos saturados y no saturados'
              }, {
                  "value": 3,
                  "label": 'Elaboración de hidrocarburos cíclicos saturados y no saturados'
              }, {
                  "value": 3,
                  "label": 'Elaboración de alcoholes cíclicos y acíclicos'
              }, {
                  "value": 3,
                  "label": 'Elaboración de otros compuestos de función de oxigeno, incluyendo aldehídos, cetona y quinonas'
              }, {
                  "value": 3,
                  "label": 'Elaboración de glicerina sintética'
              }, {
                  "value": 3,
                  "label": 'Elaboración de compuestos orgánicos de nitrógeno, incluyendo la amina'
              }, {
                  "value": 3,
                  "label": 'Fermentación de la caña de azúcar, maíz y similares para producir alcohol y esteres'
              }, {
                  "value": 3,
                  "label": 'Fabricación de agua destilada'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos aromáticos sintéticos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de abonos y compuestos de nitrógeno'
              }, {
                  "value": 3,
                  "label": 'Urea, fosfatos naturales en bruto y crudo, potasio de sales naturales'
              }, {
                  "value": 3,
                  "label": 'Elaboración de amoniaco, cloruro de amonio, carbonato de amonio, nitritos y nitratos de potasio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maceta con suelo de turba como principal componente'
              }, {
                  "value": 3,
                  "label": 'Fabricación de macetas con mezcla de suelo natural'
              }, {
                  "value": 3,
                  "label": 'Fabricación de plástico y caucho sintético en forma primaria'
              }, {
                  "value": 3,
                  "label": 'Fabricación de resinas y caucho sintético'
              }, {
                  "value": 3,
                  "label": 'Fabricación de fibras artificiales, excepto el vidrio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de poliamidas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de fenólicas y resinas epoxi y poliuretanos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de alquídicas y resinas de poliéster'
              }, {
                  "value": 3,
                  "label": 'Fabricación de siliconas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de intercambiadores a base de polímeros'
              }, {
                  "value": 3,
                  "label": 'Fabricación de facticio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materias plásticas en formas primarias N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de mezcla de caucho sintético y caucho natural'
              }, {
                  "value": 3,
                  "label": 'Fabricación de celulosas y sus derivados químicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos químicos N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pesticidas y otros productos químicos de uso agropecuario'
              }, {
                  "value": 3,
                  "label": 'Fabricación de plaguicidas, y productos químicos de uso agropecuario'
              }, {
                  "value": 3,
                  "label": 'Fabricación de inhibidores de germinación y reguladores de crecimiento de plantas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de desinfectantes (para la agricultura y otros usos)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros productos agroquímicos N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pintura, barnices y productos similares de revestimiento'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pinturas, barnices y productos de revestimiento similares; tintas de imprenta y masillas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de colores preparados de pigmentos y colorantes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de esmaltes y barnices vitrificables, engobes y preparaciones similares'
              }, {
                  "value": 3,
                  "label": 'Fabricación de masillas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de compuesto de calafateo y similares'
              }, {
                  "value": 3,
                  "label": 'Fabricación de disolventes y diluyentes de compuestos orgánicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de preparados para quitar pintura o barniz'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos farmacéuticos, sustancias químicas medicinales y de productos botánicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de medicamentos de uso humano y productos farmacéuticos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de antibióticos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vitaminas básicas'
              }, {
                  "value": 3,
                  "label": 'Procesamiento de la sangre'
              }, {
                  "value": 3,
                  "label": 'Fabricación de antisueros y otras fracciones de sangre'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vacunas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos anticonceptivos para uso externo y medicamentos anticonceptivos hormonales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos médicos de diagnósticos, incluyendo las pruebas de embarazo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materiales a base de sustancias radioactiva para el diagnostico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de medicamentos para uso veterinario'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de laboratorio, sustancias químicas medicinales y productos botánicos N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de azucares químicamente puros'
              }, {
                  "value": 3,
                  "label": 'Fabricación de extractos de glándulas y tratamientos de glándulas'
              }, {
                  "value": 3,
                  "label": 'Preparación de productos botánicos para uso farmacéutico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de jabones y detergentes, preparados para limpiar y pulir, perfumes y preparados de tocador'
              }, {
                  "value": 3,
                  "label": 'Fabricación de preparado para limpieza, pulido y saneamiento'
              }, {
                  "value": 3,
                  "label": 'Fabricación de jabones y detergentes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel, guata, fieltro recubierto o revestido de jabón o detergente'
              }, {
                  "value": 3,
                  "label": 'Fabricación de glicerol crudo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de detergentes en polvo en estado sólido o líquido'
              }, {
                  "value": 3,
                  "label": 'Fabricación de lava platos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de suavizantes textiles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de preparados para perfumar o desodorizar habitaciones'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ceras artificiales y ceras preparadas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de abrillantadores y cremas para pieles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de abrillantadores y cremas para maderas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de abrillantadores y cremas para cristales y metales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pastas y polvos para el desengrasado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cosméticos, perfumes  y productos de higiene y tocador'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquillaje'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bloqueadores solares'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos para el manicure y el pedicura'
              }, {
                  "value": 3,
                  "label": 'Fabricación de champú, lacas para el cabello'
              }, {
                  "value": 3,
                  "label": 'Fabricación de dentífrico y artículos para la higiene bucal'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos para afeitar, incluidos los pre-afeitados y para después del afeitado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de desodorantes y sales de baño'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cremas depiladoras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros productos químicos N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tintas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de explosivos, municiones y productos de pirotecnia'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cola, adhesivos y cementos excepto los odontológicos obtenidos de sustancias minerales y vegetales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ceras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de polvos propulsores (pólvora)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de extractos de productos aromáticos naturales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de agua destilada aromática'
              }, {
                  "value": 3,
                  "label": 'Fabricación de mezclas odoríferas de productos para la fabricación de perfumes o alimentos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos químicos N. C. P. (incluye la producción de aceites esenciales, etc.)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de placas fotográficas, películas, papel sensibilizado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de preparados químicos para uso fotográfico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de peptonas, derivados de peptonas, otras sustancias proteicas y sus derivados N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Modificación química de aceites y grasas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materiales utilizados en el acabado de los productos textiles y de cuero'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pastas y polvos utilizados en soldadura'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aditivos preparados para cemento'
              }, {
                  "value": 3,
                  "label": 'Fabricación de carbón activado, aditivos para aceite lubricante, aceleradores y catalizadores'
              }, {
                  "value": 3,
                  "label": 'Fabricación de anticongelantes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de compuestos para diagnósticos de laboratorio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de fibras manufacturadas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de fibras manufacturadas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hilados de filamentos sintéticos o artificiales de remolque'
              }, {
                  "value": 3,
                  "label": 'Fabricación de filamentos sintéticos o artificiales de hilo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de monofilamentos o tiras sintéticas o artificiales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de caucho y plástico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubos y neumáticos de caucho, reconstrucción de neumáticos de caucho'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cubiertas y cámaras de caucho'
              }, {
                  "value": 3,
                  "label": 'Fabricación de neumáticos macizos o huecos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de neumáticos de rodaduras intercambiables, camelback tiras para el recauchutado de neumáticos'
              }, {
                  "value": 2,
                  "label": 'Reconstrucción y recauchutado de neumáticos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros productos de caucho natural o sintético'
              }, {
                  "value": 3,
                  "label": 'Fabricación de autopartes de caucho excepto cámaras y cubiertas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de placas, hojas, tiras, barras y perfiles de caucho'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubos, caños y mangueras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cintas transportadoras de goma o correas de transmisión o de bandas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos higiénicos de goma'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de vestir de caucho'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hilo y cuerda de goma'
              }, {
                  "value": 3,
                  "label": 'Fabricación de anillos de goma y sus accesorios'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de caucho N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de balones inflables'
              }, {
                  "value": 3,
                  "label": 'Fabricación de escobillas de goma'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubo de goma dura'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de plástico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de envases plásticos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de placas, laminas, bloques, películas, cintas y tiras de plástico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tanques'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de plástico en su forma básica y artículos de plástico N.C.P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubos de plástico, mangueras y accesorios para tuberías'
              }, {
                  "value": 3,
                  "label": 'Fabricación de puertas, ventanas, marcos, postigos, persianas y zócalos de plástico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de piso, paredes o techos en rollo o en forma de baldosa de plástico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de baños plásticos y artículos para el baño de plástico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vajillas de plástico, utensilios de cocina y artículos de tocador'
              }, {
                  "value": 3,
                  "label": 'Fabricación de revestimiento de suelo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de piedra artificial'
              }, {
                  "value": 3,
                  "label": 'Fabricación de señales no eléctricas de plástico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de diversos productos de plástico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros productos minerales no metálicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vidrio y productos de vidrio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de envases de vidrio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de botellas y otros recipientes de vidrio o cristal'
              }, {
                  "value": 3,
                  "label": 'Fabricación y elaboración de vidrio plano y templado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vidrio plano templado o laminado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vidrio en barra o tubos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bloques de vidrio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de espejos y cristales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vidrios y parabrisas para automóviles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de múltiples unidades aislantes de paredes de vidrio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de fibra de vidrio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vidrio para reloj, para gafas, vidrios ópticos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de figuras de cristal'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de vidrio N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de cerámicas no refractaria para uso no industrial'
              }, {
                  "value": 3,
                  "label": 'Fabricación de accesorios sanitarios de cerámica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos cerámicos para uso industrial y de laboratorio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de objetos cerámicos para uso domestico excepto artefactos sanitario'
              }, {
                  "value": 3,
                  "label": 'Fabricación de objetos cerámicos excepto revestimiento de pisos y paredes N.C.P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de objetos de barro, loza, porcelana, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cerámica no refractaria'
              }, {
                  "value": 3,
                  "label": 'Fabricación de estatuillas y otros artículos ornamentales de cerámica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aisladores eléctricos y piezas aislantes de cerámica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cerámica y los imanes de ferrita'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ollas de cerámica, frascos y artículos similares'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cerámica refractaria'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de cerámica refractaria'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos para el aislamiento térmico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de replicas, crisoles, mufles, boquillas, tubos, caños, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cerámica refractaria'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de arcilla y cerámica no refractaria para uso estructural'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ladrillos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de revestimiento cerámico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bloques de pisos en arcilla cocida'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos de arcilla y cerámica no refractaria para uso estructural N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cemento de la cal y del yeso'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cementos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de yeso'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cal'
              }, {
                  "value": 3,
                  "label": 'Fabricación de dolomita calcinada'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de hormigón, de cemento y de yeso'
              }, {
                  "value": 3,
                  "label": 'Fabricación de mosaicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de cemento y fibrocemento'
              }, {
                  "value": 3,
                  "label": 'Fabricación de premoldeados para la construcción'
              }, {
                  "value": 3,
                  "label": 'Fabricación de juntas, hojas y paneles de yeso'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materiales de construcción de sustancias vegetales aglomerados con cemento, yeso u otros minerales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hojas corrugadas y otras hojas, paneles y losetas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubos, caños, embalses, canales, cuencas y sumideros'
              }, {
                  "value": 3,
                  "label": 'Fabricación de muebles y marcos de ventana'
              }, {
                  "value": 3,
                  "label": 'Fabricación de estatuas, muebles, bajorrelieves, floreros y macetas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de morteros en polvo'
              }, {
                  "value": 3,
                  "label": 'Corte, tallado y acabado de la piedra'
              }, {
                  "value": 3,
                  "label": 'Corte, tallado y acabado de la piedra (incluye mármoles y granitos, etc.)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de muebles de piedra'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materiales para pavimento y techado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros productos minerales N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Elaboración primaria de minerales no metálicos N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materiales de fricción y artículos con una base de sustancias minerales o de celulosa'
              }, {
                  "value": 3,
                  "label": 'Fabricación de briquete de combustible'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos minerales no metálicos N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Lana de escoria, lana de roca y lanas minerales similares'
              }, {
                  "value": 2,
                  "label": 'Aplicación de arcilla, aislante de calor, aislante de sonido'
              }, {
                  "value": 2,
                  "label": 'Trabajo en mica y articulo de mica, de turba, grafito (que no sean artículos eléctricos)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de asfalto o material similar'
              }, {
                  "value": 3,
                  "label": 'Fabricación de fibras de carbono, de grafito y sus productos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de metales básicos'
              }, {
                  "value": 3,
                  "label": 'Industria básica de hierro y acero'
              }, {
                  "value": 3,
                  "label": 'Fundición de acero en hornos de alta temperatura, producción de lingotes o planchas de acero'
              }, {
                  "value": 3,
                  "label": 'Laminación y estirado'
              }, {
                  "value": 3,
                  "label": 'Producción de aleaciones de acero'
              }, {
                  "value": 3,
                  "label": 'Producción de hierro por reducción directa de productos de hierro y otros productos férreos esponjosos'
              }, {
                  "value": 3,
                  "label": 'Producción de hierro de pureza excepcional por electrolisis u otros procesos químicos'
              }, {
                  "value": 3,
                  "label": 'Producción de hierro granulado y polvo de hierro'
              }, {
                  "value": 3,
                  "label": 'Producción de acero en lingotes o demás formas primarias'
              }, {
                  "value": 3,
                  "label": 'Refundición de lingotes de chatarra de hierro o acero'
              }, {
                  "value": 3,
                  "label": 'Fabricación en industrias básicas de productos de hierro y acero N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de laminados de acero'
              }, {
                  "value": 3,
                  "label": 'Fabricación de laminados de acero en caliente, barras y varillas de acero'
              }, {
                  "value": 3,
                  "label": 'Fabricación de barras de acero'
              }, {
                  "value": 3,
                  "label": 'Fabricación de materiales de vía férrea de acero'
              }, {
                  "value": 3,
                  "label": 'Fabricación de alambre de acero'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubos sin soldadura, tubos y perfiles huecos de acero'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubos con soldaduras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de brindas planas y brindas forjadas con anillos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de roscado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos primarios de metales preciosos y metales no ferrosos'
              }, {
                  "value": 3,
                  "label": 'Elaboración de aluminio primario y semielaborados de aluminio'
              }, {
                  "value": 3,
                  "label": 'Producción de aluminio a base del refinado electrolítico de aluminio'
              }, {
                  "value": 3,
                  "label": 'Producción de aleación de aluminio'
              }, {
                  "value": 3,
                  "label": 'Producción y refinado de metales preciosos como el oro, plata, platino, etc.'
              }, {
                  "value": 3,
                  "label": 'Producción de aleaciones de metales preciosos'
              }, {
                  "value": 3,
                  "label": 'Producción de laminados de plata a base de metales'
              }, {
                  "value": 3,
                  "label": 'Producción de laminados de oro'
              }, {
                  "value": 3,
                  "label": 'Producción de platino y metales del grupo del platino'
              }, {
                  "value": 3,
                  "label": 'Producción de plomo, zinc y estaño'
              }, {
                  "value": 3,
                  "label": 'Producción de plomo, zinc y estaño a base de refinado electrolítico de aluminio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aleaciones de plomo, zinc y estaño'
              }, {
                  "value": 3,
                  "label": 'Producción de minerales de cobre'
              }, {
                  "value": 3,
                  "label": 'Producción de cobre a base de refinado electrolítico del cobre'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aleaciones de cobre'
              }, {
                  "value": 3,
                  "label": 'Fabricación de fusibles o tiras'
              }, {
                  "value": 3,
                  "label": 'Producción de metales no ferrosos N. C. P. y sus semielaborados'
              }, {
                  "value": 3,
                  "label": 'Producción de cromo, manganeso y níquel de los minerales u óxidos'
              }, {
                  "value": 3,
                  "label": 'Producción de cromo, manganeso y níquel a base de refinado electrolítico de cromo, manganeso y níquel'
              }, {
                  "value": 3,
                  "label": 'Producción de aleaciones de cromo, manganeso y níquel'
              }, {
                  "value": 3,
                  "label": 'Producción de uranio metálico de pitchblende u otros materiales'
              }, {
                  "value": 3,
                  "label": 'Fundición y refinación de uranio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hilos de estos metales antes mencionados'
              }, {
                  "value": 3,
                  "label": 'Producción de oxido de aluminio'
              }, {
                  "value": 3,
                  "label": 'Producción de láminas de aluminio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de laminados de metales preciosos'
              }, {
                  "value": 3,
                  "label": 'Fundición de metales'
              }, {
                  "value": 3,
                  "label": 'Fundición de hierro y acero'
              }, {
                  "value": 3,
                  "label": 'Fundición de hierro y acero'
              }, {
                  "value": 3,
                  "label": 'Fundición de hierro gris'
              }, {
                  "value": 3,
                  "label": 'Fundición de grafito esferoidal'
              }, {
                  "value": 3,
                  "label": 'Fundición de hierro maleable'
              }, {
                  "value": 3,
                  "label": 'Fundición de acero'
              }, {
                  "value": 3,
                  "label": 'Fundición despiezas de acero'
              }, {
                  "value": 3,
                  "label": 'Fundición de metales no ferrosos'
              }, {
                  "value": 3,
                  "label": 'Fundición de productos semiacabados de aluminio, magnesio, titanio, zinc, etc.'
              }, {
                  "value": 3,
                  "label": 'Fundición de metales ligeros'
              }, {
                  "value": 3,
                  "label": 'Fundición de piezas de metales pesados'
              }, {
                  "value": 3,
                  "label": 'Fundición de piezas de metales preciosos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos derivados del metal, excepto maquinaria y equipo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos metálicos para uso estructural, tanques, depósitos y los generadores de vapor'
              }, {
                  "value": 3,
                  "label": 'Fabricación de productos metálicos para uso estructural'
              }, {
                  "value": 3,
                  "label": 'Fabricación de carpintería metálica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de estructuras metálicas para la construcción'
              }, {
                  "value": 3,
                  "label": 'Fabricación industrial de marcos de metal (marcos para los altos hornos, aparatos elevadores y de manejo de equipos, etc.)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cabañas, elementos modulares para exposición, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de puertas, ventanas, persianas y sus marcos en metal'
              }, {
                  "value": 3,
                  "label": 'Fabricación de habitaciones de metal'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tanques, depósitos y recipientes de metal'
              }, {
                  "value": 3,
                  "label": 'Fabricación de contenedores metálicos para gases comprimidos o licuados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de calderas de calefacción central y radiadores'
              }, {
                  "value": 3,
                  "label": 'Fabricación de generadores de vapor'
              }, {
                  "value": 3,
                  "label": 'Fabricación de condensadores, economizadores y colectores de vapor'
              }, {
                  "value": 3,
                  "label": 'Forjado, prensado, estampado y laminados de metales; pulvimetalurgia'
              }, {
                  "value": 3,
                  "label": 'Tratamiento y revestimiento de metales; obras de ingeniería mecánica realizadas a cambio de una retribución o por contrato'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería'
              }, {
                  "value": 3,
                  "label": 'Fabricación de herramientas de mano y sus accesorios'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artículos de cuchillería y utensilios de mesa y de cocina'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cuchillas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas de afeitar y hojas de afeitar'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tijeras'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cuchillos y hojas cortantes para maquinas o aparatos mecánicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de herramientas agrícolas de mano sin motor'
              }, {
                  "value": 2,
                  "label": 'Fabricación de sierras'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cerraduras, herraje y artículos de ferretería N. C. P. (no incluye clavos, productos de buhonería, vajilla de mesa)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de brocas, fresas, punzones, etc.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de herramientas de prensado'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cajas y moldes, excepto lingoteras'
              }, {
                  "value": 2,
                  "label": 'Fabricación de abrazaderas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de fraguas y yunques'
              }, {
                  "value": 2,
                  "label": 'Fabricación de candados, cerraduras, llaves, bisagras y similares, de hardware para los edificios, mobiliarios, vehículos, etc.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de sables, espadas, bayonetas, etc.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de otros productos de metal N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de envases metálicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de latas para productos alimenticios, tubos plegables'
              }, {
                  "value": 2,
                  "label": 'Fabricación de tejidos de alambre'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cajas de seguridad'
              }, {
                  "value": 2,
                  "label": 'Fabricación de productos metálicos de tornería y/o matricera'
              }, {
                  "value": 2,
                  "label": 'Fabricación de productos de herrería y soldadura'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cable de metal, trenzas y artículos similares'
              }, {
                  "value": 2,
                  "label": 'Fabricación de alambre de púa'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cercas de alambre'
              }, {
                  "value": 2,
                  "label": 'Fabricación de parrilla'
              }, {
                  "value": 2,
                  "label": 'Fabricación de productos metálicos N. C. P. (incluye clavos, productos de bulonería, vajilla de mesa y de cocina, etc.)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria y equipo N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de la maquinaria para uso en general'
              }, {
                  "value": 3,
                  "label": 'Fabricación de motores y turbinas, excepto motores para aeronaves, vehículos automotores y motocicletas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de motores de ferrocarril'
              }, {
                  "value": 3,
                  "label": 'Fabricación de turbinas de vapor'
              }, {
                  "value": 3,
                  "label": 'Fabricación de turbinas hidráulicas, ruedas hidráulicas y los reguladores del mismo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de turbinas eólicas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de turbinas de gas, excepto turborreactores o turbo hélices para aviones de propulsión'
              }, {
                  "value": 3,
                  "label": 'Fabricación de caldera-turbina'
              }, {
                  "value": 3,
                  "label": 'Fabricación de la turbina-generador'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bombas; compresores; grifos y válvulas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bombas hidráulicas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de motores hidráulicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cilindros hidráulicos y neumáticos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de válvulas hidráulicas y neumáticas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de mangueras y accesorios hidráulicos y neumáticos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipos de preparación de aire para su uso en sistema neumático'
              }, {
                  "value": 2,
                  "label": 'Fabricación de sistemas de energía de fluidos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipos de transmisión hidráulica'
              }, {
                  "value": 2,
                  "label": 'Fabricación de bombas para líquidos que estén o no provistos de un dispositivo de medición'
              }, {
                  "value": 2,
                  "label": 'Fabricación de bombas para aceite y de agua, bombas de combustibles de los vehículos de motor'
              }, {
                  "value": 2,
                  "label": 'Fabricación industrial de grifos y válvulas incluyendo la regulación de grifos y válvulas de admisión'
              }, {
                  "value": 2,
                  "label": 'Fabricación de grifos y válvulas sanitarios'
              }, {
                  "value": 2,
                  "label": 'Fabricación de grifos y válvulas para los sistemas de calefacción'
              }, {
                  "value": 2,
                  "label": 'Fabricación de bombas manuales'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cojinetes; engranajes; trenes de engranaje y piezas de transmisión'
              }, {
                  "value": 2,
                  "label": 'Fabricación de carburadores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de conductos de admisión y válvulas de escapes de los motores de combustión interna'
              }, {
                  "value": 2,
                  "label": 'Fabricación de bolas y cojinetes de rodillos y sus partes'
              }, {
                  "value": 2,
                  "label": 'Fabricación de ejes de transmisión y manivelas, árboles de levas, cigüeñales, bielas, etc.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de soportes de rodamiento y cojinetes'
              }, {
                  "value": 2,
                  "label": 'Fabricación de engranajes, cajas de engranajes y otros variadores de velocidad'
              }, {
                  "value": 2,
                  "label": 'Fabricación de embragues y órganos de acoplamientos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de volantes y poleas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cadenas de eslabones articulados'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cadenas de transmisión de potencia'
              }, {
                  "value": 2,
                  "label": 'Fabricación de hornos y quemadores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aparatos eléctricos y otros industriales de laboratorios'
              }, {
                  "value": 2,
                  "label": 'Fabricación y el montaje permanente de calentadores eléctricos, calentadores eléctricos de piscina'
              }, {
                  "value": 2,
                  "label": 'Fabricación y montaje de calefacción solar'
              }, {
                  "value": 2,
                  "label": 'Fabricación y montaje de calefacción de vapor'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aceite térmico'
              }, {
                  "value": 2,
                  "label": 'Fabricación y montaje de hornos y equipos de calefacción'
              }, {
                  "value": 2,
                  "label": 'Fabricación de electrodomésticos del tipo de hornos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipo de elevación y manipulación (incluye la fabricación de ascensores, escaleras mecánicas, montacargas, etc.)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de grúas, bastidores y elevadores móviles'
              }, {
                  "value": 1,
                  "label": 'Fabricación de camiones de obras sean o no equipados con equipos de elevación o manipulación'
              }, {
                  "value": 1,
                  "label": 'Fabricación de manipuladores mecánicos y robots industriales diseñados específicamente para elevación, carga y descarga'
              }, {
                  "value": 1,
                  "label": 'Fabricación de transportadores, teleféricos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de ascensores, escaleras mecánicas y pasillos móviles'
              }, {
                  "value": 2,
                  "label": 'Fabricación de piezas especializadas para el levantamiento y manejo de equipo'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinaria de uso general N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de calandrias u otras maquinas laminadoras (a excepción de metal y vidrio)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de centrifugadoras (excepto separadores de crema y secadoras de ropa)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de juntas y uniones similares hechas de una combinación de materiales o capas del mismo material'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinas de ventas automáticas de productos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de piezas para maquinaria de uso general'
              }, {
                  "value": 1,
                  "label": 'Fabricación de ventiladores (ventiladores de techo, etc.)'
              }, {
                  "value": 1,
                  "label": 'Fabricación de aparatos no eléctricos de soldadura y equipos de soldadura'
              }, {
                  "value": 2,
                  "label": 'Ensamblaje de maquinarias y equipos (incluye las eléctricas)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinaria agropecuaria y forestal'
              }, {
                  "value": 1,
                  "label": 'Fabricación de tractores'
              }, {
                  "value": 1,
                  "label": 'Fabricación de tractores (controlado peatonalmente)'
              }, {
                  "value": 1,
                  "label": 'Fabricación de segadoras, incluidas las cortadoras de césped'
              }, {
                  "value": 1,
                  "label": 'Fabricación de cosechadoras, trilladoras y clasificadoras'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinaria de pulverización para uso agrícola'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinaria agropecuaria y forestal, excepto tractores'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinas herramienta, piezas para maquina de trabajar los metales y la madera (no eléctrica)'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinaria para la avicultura, apicultura, equipos para la preparación de forraje'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinaria para limpiar, seleccionar o clasificar huevos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria metalúrgica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de herramientas, piezas y accesorios para maquinas de trabajar los metales y otros materiales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas y herramientas para el torneado, taladrado y fresado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas y herramientas para el estampado y pulsado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de prensas de troquelado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de prensas hidráulicas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de frenos hidráulicos, martillos de caída'
              }, {
                  "value": 3,
                  "label": 'Fabricación de prensas para la fabricación de tableros de partículas y similares'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas estacionaria para clavar, grapar madera, corcho,  hueso, plástico'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria para la explotación de minas y canteras y para obras de construcción'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria para la perforación, corte, hundimiento de túneles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria para el tratamiento de materiales mediante cribado, selección, clasificación, lavado, trituración, etc.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tractores usados en la construcción y minería'
              }, {
                  "value": 3,
                  "label": 'Fabricación de excavadoras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinaria para hacer confitería de cacao o chocolate'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinaria para procesar carne de aves de corral'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria para preparar frutas y vegetales'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinaria para preparar pescado y mariscos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de mecanismos de filtrado y purificación'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinarias para la extracción o preparación de aceites o grasas animales o vegetales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria para la preparación de tabaco'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinaria para la preparación de alimentos en hoteles y restaurantes'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas para la preparación de fibras textiles'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas de hilado'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas para la preparación de hilados textiles'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas de telares, incluidos los telares de mano'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas para hacer nudo'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas auxiliares o equipos para maquinaria textil'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria de impresión textil'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria para el lavado, blanqueo, teñido, acabado y recubrimiento de tejidos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas para el devanado de tejido'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas de planchar incluyendo las prensas de fusión'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas comerciales de lavado y secado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas de lavado en seco'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas para la producción o acabado de fieltro o tela sin tejer'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas para la preparación, curtido o trabajo de cuero y pieles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas para fabricar o reparar calzado u otro artículos de cuero, pieles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de armas y municiones'
              }, {
                  "value": 3,
                  "label": 'Fabricación de artillería'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cañones móviles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de lanzadores de cohetes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubos de torpedos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ametralladoras pesadas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de explosivos como bombas, minas y torpedos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de revólveres y pistolas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de escopetas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ametralladoras ligeras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cañones y pistolas de aire o gas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de municiones para guerra'
              }, {
                  "value": 3,
                  "label": 'Fabricación y reparación de maquinaria de la industria petrolera'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria de uso especial'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria para la industria del papel y las artes graficas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de papel y cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de secadores para madera, pasta de papel o cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de máquina para la producción de artículos de papel o cartón'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria de uso especial N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aparatos para uso domésticos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cocinas, estufas y calefactores de uso domestico no eléctricos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de heladeras, frezar, lavaplatos y secarropas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de lavavajillas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquina de lavado y secado de ropa'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aspiradoras'
              }, {
                  "value": 2,
                  "label": 'Fabricación de eliminadores de residuos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de molinos, mezcladores, exprimidores de zumo'
              }, {
                  "value": 2,
                  "label": 'Fabricación de abridores de lata'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas de afeitar eléctricas, cepillos de dientes eléctricos y otros aparatos eléctricos del cuidado personal'
              }, {
                  "value": 2,
                  "label": 'Fabricación de calentadores de agua eléctricos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de mantas eléctricas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de secadores de pelo, peines y cepillos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de plancha eléctrica'
              }, {
                  "value": 2,
                  "label": 'Fabricación de placas de cocción'
              }, {
                  "value": 2,
                  "label": 'Fabricación de tostadoras y cafeteras eléctricas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de sartenes, asadores y parrillas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de calefactores eléctricos de resistencia'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas de coser y tejer'
              }, {
                  "value": 2,
                  "label": 'Fabricación de ventiladores, extractores y acondicionadores de aire, aspiradores y similares'
              }, {
                  "value": 2,
                  "label": 'Fabricación de encendedoras, pulidoras, batidoras, licuadoras y similares'
              }, {
                  "value": 2,
                  "label": 'Fabricación de planchas, calefactores, hornos eléctricos, tostadoras y otros aparatos generadores de calor'
              }, {
                  "value": 2,
                  "label": 'Fabricación de artefactos para la iluminación excepto los eléctricos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aparatos y accesorios eléctricos N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipos periféricos)'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinaria de oficina, contabilidad e informática'
              }, {
                  "value": 1,
                  "label": 'Fabricación de calculadoras, eléctricas o no'
              }, {
                  "value": 1,
                  "label": 'Fabricación de cajas registradoras operadas mecánicamente'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinas de escribir'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas de taquigrafía'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas de protección de cheques'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinaria para embasado de moneda y conteo de moneda'
              }, {
                  "value": 1,
                  "label": 'Fabricación de sacapuntas'
              }, {
                  "value": 1,
                  "label": 'Fabricación de grapadoras y saca grapas'
              }, {
                  "value": 1,
                  "label": 'Fabricación de dispensadores de cinta'
              }, {
                  "value": 1,
                  "label": 'Fabricación de perforadoras de papel'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinas fotocopiadoras'
              }, {
                  "value": 1,
                  "label": 'Fabricación de cartuchos de tóner'
              }, {
                  "value": 2,
                  "label": 'Fabricación de generadores y motores eléctricos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de motores, generadores y transformadores eléctricos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de generadores de energía (excepto alternadores de carga de baterías)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de generadores de motor (excepto el generador de turbina)'
              }, {
                  "value": 1,
                  "label": 'Fabricación de disyuntores de potencia (fusibles-breakers)'
              }, {
                  "value": 1,
                  "label": 'Fabricación de paneles de control para la distribución de la energía'
              }, {
                  "value": 1,
                  "label": 'Fabricación de rieles eléctrica'
              }, {
                  "value": 1,
                  "label": 'Fabricación de conductos de los aparatos eléctricos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de fusibles eléctricos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de equipos de conmutación de potencia'
              }, {
                  "value": 1,
                  "label": 'Fabricación de interruptores de energía eléctrica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aparatos de distribución y control de la energía eléctrica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aparatos de distribución y control de la energía eléctrica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de interruptores de cables eléctricos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tomas de electricidad'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cajas de cables eléctricos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de conductos eléctricos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de plástico actual no portadora de dispositivos de cableado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otros cables eléctricos y electrónicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de hilos y cables aislados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de acumuladores y de pilas y baterías primarias e inversores'
              }, {
                  "value": 3,
                  "label": 'Fabricación de acumuladores y pilas y baterías primarias'
              }, {
                  "value": 3,
                  "label": 'Fabricación de células que contienen dióxido de magnesio, dióxido de mercurio, oxido de plata'
              }, {
                  "value": 3,
                  "label": 'Fabricación de acumuladores eléctricos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de separadores y contenedores'
              }, {
                  "value": 3,
                  "label": 'Fabricación de baterías de acido de plomo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de baterías de NiCad'
              }, {
                  "value": 3,
                  "label": 'Fabricación de baterías de NiMH'
              }, {
                  "value": 3,
                  "label": 'Fabricación de baterías de litio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pilas secas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de baterías de celdas húmedas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de inversores de estado sólido, pilas de combustibles'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cargadores de batería de estado sólido'
              }, {
                  "value": 3,
                  "label": 'Fabricación de puertas de apertura y cierre con dispositivos eléctricos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de campanas eléctricas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de maquinas de limpieza por ultrasonidos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cama de bronceado'
              }, {
                  "value": 3,
                  "label": 'Fabricación de sistemas de alimentación interrumpida UPS'
              }, {
                  "value": 3,
                  "label": 'Fabricación de lámparas eléctrica y equipos de iluminación'
              }, {
                  "value": 3,
                  "label": 'Fabricación de lámparas eléctrica y equipos de iluminación'
              }, {
                  "value": 3,
                  "label": 'Fabricación de techo de luminaria'
              }, {
                  "value": 3,
                  "label": 'Fabricación de lámparas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de lámparas de mesa'
              }, {
                  "value": 1,
                  "label": 'Fabricación de aparatos de iluminación para árboles de navidad'
              }, {
                  "value": 1,
                  "label": 'Fabricación de lámparas eléctricas contra insectos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de lámparas de carburo, eléctricas, de gas, queroseno'
              }, {
                  "value": 1,
                  "label": 'Fabricación de proyectores'
              }, {
                  "value": 1,
                  "label": 'Fabricación de accesorios para la iluminación de calle'
              }, {
                  "value": 1,
                  "label": 'Fabricación de equipos de iluminación para equipos de transporte'
              }, {
                  "value": 3,
                  "label": 'Fabricación de equipo electrónico N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de equipo electrónico N. C. P.'
              }, {
                  "value": 1,
                  "label": 'Fabricación de diodos emisores de luz (LED)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cristales electrónicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de computadoras de escritorio'
              }, {
                  "value": 2,
                  "label": 'Fabricación de computadoras portátiles'
              }, {
                  "value": 2,
                  "label": 'Fabricación de ordenadores de bolsillos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de discos magnéticos, unidades flash y otros dispositivos de almacenamiento'
              }, {
                  "value": 2,
                  "label": 'Fabricación de unidades ópticas como CD-RW, CD ROM, DVD ROM'
              }, {
                  "value": 3,
                  "label": 'Fabricación de impresoras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de monitores'
              }, {
                  "value": 1,
                  "label": 'Fabricación de teclados'
              }, {
                  "value": 1,
                  "label": 'Fabricación de ratones, joystick, TrackBall'
              }, {
                  "value": 3,
                  "label": 'Fabricación de terminales de ordenadores especializados'
              }, {
                  "value": 2,
                  "label": 'Fabricación de servidores informáticos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de escáneres'
              }, {
                  "value": 2,
                  "label": 'Fabricación de lectores de tarjetas inteligentes'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cascos de realidad virtual'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipos de conmutación para oficina'
              }, {
                  "value": 2,
                  "label": 'Fabricación de teléfonos inalámbricos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de central telefónica privada, equipo (PBX)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de teléfonos y equipos de fax, incluyendo los contestadores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de pagers (buscadores)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de teléfonos celulares'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipo de comunicación móvil'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipos de comunicaciones de datos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de antenas de transmisión y recepción'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubos, válvulas y otros componentes electrónicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tubos, válvulas y otros componentes electrónicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de circuitos integrados (análogos, digitales o híbridos)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de diodos, transistores y dispositivos discretos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de selonoides, interruptores para aplicaciones electrónicas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de semiconductores acabados o semiacabados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de condensadores eléctricos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de resistencias electrónicas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de microprocesadores'
              }, {
                  "value": 3,
                  "label": 'Fabricación de circuitos impresos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de inductores'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tarjetas de interfaz'
              }, {
                  "value": 2,
                  "label": 'Fabricación de componentes de pantallas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de transmisores de radio y televisión y de aparatos para la telefonía y telegrafía con hilos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de transmisores de radio y televisión y de aparatos para la telefonía y telegrafía con hilos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de grabadoras de video'
              }, {
                  "value": 2,
                  "label": 'Fabricación de monitores de televisión'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipos de sistemas de grabación de sonido'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipos estereos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de reproductores de CD y DVD'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipos de karaoke'
              }, {
                  "value": 2,
                  "label": 'Fabricación de receptores de radio y televisión, aparatos de grabación y reproducción de sonido y video y productos conexos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de receptores de radio y televisión, aparatos de grabación y reproducción de sonido y video y productos conexos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de sistemas de altavoces'
              }, {
                  "value": 2,
                  "label": 'Fabricación de micrófonos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de amplificadores de instrumentos musicales'
              }, {
                  "value": 2,
                  "label": 'Fabricación de audífonos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de consolas de videojuegos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de piezas y accesorios para radio y televisión'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipo médico y quirúrgico y aparatos ortopédicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipo médico y quirúrgico y de aparatos ortopédicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cortinas estéril, cadenas y tejidos quirúrgicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de empastes dentales, cementos, cera dental y otras preparaciones dentales en yeso'
              }, {
                  "value": 2,
                  "label": 'Fabricación de hornos de laboratorio dental'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinaria para la limpieza de laboratorios de ultrasonido'
              }, {
                  "value": 3,
                  "label": 'Fabricación de esterilizadores de laboratorios'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aparatos de destilación, centrifugadoras de laboratorio'
              }, {
                  "value": 2,
                  "label": 'Fabricación de mesas de operación'
              }, {
                  "value": 2,
                  "label": 'Fabricación de Tabla de examinación'
              }, {
                  "value": 2,
                  "label": 'Fabricación de camas de hospitales con ajustes mecánicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de sillas odontológicas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de tornillos, jeringas, agujas, catéteres y cánulas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de instrumentos dentales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de dientes artificiales, puentes realizados en laboratorios dentales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ojos de vidrio'
              }, {
                  "value": 3,
                  "label": 'Fabricación de termómetros médicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de productos oftálmicos, anteojos, lentes de sol, lentes de terreno para la prescripción, lentes de contacto'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos y aparatos para medir, verificar, ensayar, navegar y otros fines, excepto el equipo de control de procesos industriales'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos y aparatos para medir, verificar, ensayar, navegar y otros fines, excepto el equipo de control de procesos industriales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de equipos de radar'
              }, {
                  "value": 3,
                  "label": 'Fabricación de dispositivos GPS'
              }, {
                  "value": 3,
                  "label": 'Fabricación de instrumentos para motores de aeronaves'
              }, {
                  "value": 3,
                  "label": 'Fabricación de equipos para verificación de emisiones automotriz'
              }, {
                  "value": 3,
                  "label": 'Fabricación de instrumentos meteorológicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de equipos de inspección y pruebas de las propiedades físicas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de maquinas de polígrafos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipos de medición y pruebas de electricidad y señales eléctricas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos de detección y seguimiento de radiación'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos de topografía'
              }, {
                  "value": 3,
                  "label": 'Fabricación de termómetros líquidos en vidrio y tipos bimétalicos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de microscopios de electrones y protones'
              }, {
                  "value": 3,
                  "label": 'Fabricación de equipos de controles de procesos industriales'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aparatos de control de limite hidrónicos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aparatos para control de llamas y quemadores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de aparatos para controles ambientales y controles automáticos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de espectrómetros'
              }, {
                  "value": 2,
                  "label": 'Fabricación de indicadores de nivel neumático'
              }, {
                  "value": 2,
                  "label": 'Fabricación de medidores de consumo'
              }, {
                  "value": 2,
                  "label": 'Fabricación de medidores de flujos y aparatos para contar'
              }, {
                  "value": 3,
                  "label": 'Fabricación de detectores de mina, detectores de metales'
              }, {
                  "value": 2,
                  "label": 'Fabricación de detectores de movimientos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de instrumentos analíticos de laboratorios'
              }, {
                  "value": 1,
                  "label": 'Fabricación de instrumentos ópticos y equipo fotografito'
              }, {
                  "value": 1,
                  "label": 'Fabricación de aparatos y accesorios para fotografía excepto películas, placas y papeles sensibles'
              }, {
                  "value": 1,
                  "label": 'Fabricación de lentes y otros artículos oftálmicos'
              }, {
                  "value": 1,
                  "label": 'Fabricaron de instrumento de óptica'
              }, {
                  "value": 1,
                  "label": 'Fabricación de microscopios ópticos, binoculares y telescopios'
              }, {
                  "value": 1,
                  "label": 'Fabricación de espejos ópticos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de instrumentos ópticos de aumento'
              }, {
                  "value": 1,
                  "label": 'Fabricación de maquinas ópticas de herramientas de precisión'
              }, {
                  "value": 1,
                  "label": 'Fabricación de comparadores ópticos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de equipos ópticos de observación para armas'
              }, {
                  "value": 1,
                  "label": 'Fabricación de equipo óptico de posicionamiento'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipos de control de incendio'
              }, {
                  "value": 2,
                  "label": 'Fabricación de medidores de luz fotográfica'
              }, {
                  "value": 2,
                  "label": 'Fabricación de buscadores de rango'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cámaras de películas y cámaras digitales'
              }, {
                  "value": 2,
                  "label": 'Fabricación de películas y proyectores de diapositivas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cintas magnéticas de video y de audio'
              }, {
                  "value": 2,
                  "label": 'Fabricación de casetes magnéticos de video y de audio'
              }, {
                  "value": 2,
                  "label": 'Fabricación de diskettes'
              }, {
                  "value": 2,
                  "label": 'Fabricación de discos ópticos en blanco'
              }, {
                  "value": 2,
                  "label": 'Fabricación de discos duros'
              }, {
                  "value": 3,
                  "label": 'Fabricación de todo tipo de relojes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de relojes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de relojes de metales preciosos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de parquímetros'
              }, {
                  "value": 3,
                  "label": 'Fabricación de relojes de tiempo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de marcadoras de fecha y hora'
              }, {
                  "value": 3,
                  "label": 'Fabricación de procesadoras de temporizadores'
              }, {
                  "value": 3,
                  "label": 'Fabricación de cerraduras de tiempo'
              }, {
                  "value": 3,
                  "label": 'Fabricación de piezas móviles de todo tipo de relojes'
              }, {
                  "value": 2,
                  "label": 'Fabricación de resortes y otras piezas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de vehículos automotores, remolques y semirremolques'
              }, {
                  "value": 2,
                  "label": 'Fabricación de vehículos automotores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de vehículos automotores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de furgonetas, camiones, tractores de carretera para semirremolque'
              }, {
                  "value": 2,
                  "label": 'Fabricación de autobuses'
              }, {
                  "value": 2,
                  "label": 'Fabricación de motores de los vehículos automotores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de carrocería para vehículos automotores, fabricación de remolques y semirremolque'
              }, {
                  "value": 2,
                  "label": 'Fabricación de motos de nieve, carritos de golf, vehículos anfibios'
              }, {
                  "value": 2,
                  "label": 'Fabricación de camiones bomberos, barredores de calle y vehículos blindados'
              }, {
                  "value": 2,
                  "label": 'Fabricación de camines mezcladores de hormigón'
              }, {
                  "value": 2,
                  "label": 'Fabricación de go-karts y similares incluyendo carros de carrera'
              }, {
                  "value": 2,
                  "label": 'Fabricación de carrocería de vehículos automotores; fabricación de remolques y semirremolques'
              }, {
                  "value": 2,
                  "label": 'Fabricación de carrocerías de vehículos automotores; fabricación de remolques y semirremolques'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipamientos de todos los tipos de vehículos de motor, remolques y semirremolques'
              }, {
                  "value": 2,
                  "label": 'Fabricación de remolques para el transporte de mercancías'
              }, {
                  "value": 2,
                  "label": 'Fabricación de remolques para el transporte de pasajeros'
              }, {
                  "value": 2,
                  "label": 'Fabricación de contenedores para el transporte'
              }, {
                  "value": 2,
                  "label": 'Ensamblaje de vehículos automotores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de partes y accesorios para motores de vehículos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de partes; piezas y accesorios para vehículos automotores y sus motores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de ejes, ruedas, suspensiones y amortiguadores'
              }, {
                  "value": 2,
                  "label": 'Fabricación de radiadores, silenciadores y tubos de escapes'
              }, {
                  "value": 2,
                  "label": 'Fabricación de volantes, columnas de dirección y cajas de dirección'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cinturones de seguridad, airbags, puertas y parachoques'
              }, {
                  "value": 2,
                  "label": 'Fabricación de asientos de automóviles'
              }, {
                  "value": 2,
                  "label": 'Fabricación de generadores, alternadores y bujías'
              }, {
                  "value": 2,
                  "label": 'Fabricación de arnés de cableado de encendido'
              }, {
                  "value": 2,
                  "label": 'Fabricación de paneles de instrumentos, reguladores de voltaje'
              }, {
                  "value": 2,
                  "label": 'Fabricación y reparación de baterías'
              }, {
                  "value": 2,
                  "label": 'Construcción de buques y otras embarcaciones'
              }, {
                  "value": 2,
                  "label": 'Contracción y reparación de buques y embarcaciones N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Fabricación y reparación de buques (incluye la construcción de motores y piezas para navíos, etc.)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de buques de guerra'
              }, {
                  "value": 2,
                  "label": 'Fabricación de barcos de pesca y barcos factorías para el procesamiento del pescado'
              }, {
                  "value": 3,
                  "label": 'Construcción de aerodeslizadores'
              }, {
                  "value": 3,
                  "label": 'Construcción de plataformas de perforación, flotantes o sumergidas'
              }, {
                  "value": 2,
                  "label": 'Construcción de diques flotantes, boyas, tanques flotantes, barcazas, grúas flotantes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de botes inflables y balsas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de veleros con o sin motor auxiliar'
              }, {
                  "value": 3,
                  "label": 'Construcción de barcos a motor'
              }, {
                  "value": 3,
                  "label": 'Fabricación de otras embarcaciones de recreo y deporte'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aerodeslizadores para la recreación'
              }, {
                  "value": 3,
                  "label": 'Fabricación de locomotoras y de material rodante'
              }, {
                  "value": 3,
                  "label": 'Fabricación de locomotoras y de material rodante para ferrocarriles y tranvía con sistemas eléctricos, diesel o vapor'
              }, {
                  "value": 3,
                  "label": 'Fabricación de vehículos de mantenimiento'
              }, {
                  "value": 3,
                  "label": 'Fabricación de coches de viajeros, vagones de mercancía y vagones cisternas'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ejes, ruedas, frenos, partes de freno, amortiguadores y partes de amortiguación'
              }, {
                  "value": 3,
                  "label": 'Fabricación de ganchos y dispositivos de acoplamiento'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aeronaves, naves espaciales y de maquinaria conexa'
              }, {
                  "value": 3,
                  "label": 'Fabricación y reparación de aviones'
              }, {
                  "value": 3,
                  "label": 'Fabricación de aviones para el uso de las fuerzas de defensa'
              }, {
                  "value": 3,
                  "label": 'Fabricación de helicópteros'
              }, {
                  "value": 3,
                  "label": 'Fabricación de planeadores'
              }, {
                  "value": 3,
                  "label": 'Fabricación de dirigibles y globos aerostáticos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de partes tales como fuselajes, alas, puertas, superficies de control, tren de aterrizaje y tanques de combustible'
              }, {
                  "value": 3,
                  "label": 'Fabricación de rotores de helicópteros y palas del rotor'
              }, {
                  "value": 3,
                  "label": 'Fabricación de partes de turborreactores para las aeronaves'
              }, {
                  "value": 2,
                  "label": 'Fabricación de asientos de avión'
              }, {
                  "value": 2,
                  "label": 'Fabricación de motocicleta'
              }, {
                  "value": 2,
                  "label": 'Fabricación de motocicleta'
              }, {
                  "value": 2,
                  "label": 'Fabricación de motores para motocicleta'
              }, {
                  "value": 2,
                  "label": 'Fabricación de sidecar'
              }, {
                  "value": 2,
                  "label": 'Fabricación de piezas de repuestos y accesorios para motocicleta'
              }, {
                  "value": 1,
                  "label": 'Fabricación de bicicletas y sillas de ruedas para inválidos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de partes y accesorios para bicicletas'
              }, {
                  "value": 1,
                  "label": 'Fabricación de sillas de ruedas, partes y accesorios'
              }, {
                  "value": 1,
                  "label": 'Fabricación de sillas para bebes'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipo de transporte N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de dispositivos de propulsión de vehículos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de vehículos tirados por animales'
              }, {
                  "value": 1,
                  "label": 'Fabricación de muebles y colchones'
              }, {
                  "value": 1,
                  "label": 'Fabricación de muebles y partes de muebles, principalmente de madera'
              }, {
                  "value": 1,
                  "label": 'Fabricación de sillas y asientos para oficinas, talleres, hoteles y restaurantes'
              }, {
                  "value": 1,
                  "label": 'Fabricación de sillas y asientos para teatros y cines'
              }, {
                  "value": 1,
                  "label": 'Fabricación de sofás, sofás camas'
              }, {
                  "value": 1,
                  "label": 'Fabricación de sillas y asientos de jardín'
              }, {
                  "value": 1,
                  "label": 'Fabricación de muebles para iglesias y escuelas'
              }, {
                  "value": 1,
                  "label": 'Fabricación de muebles especiales para tiendas, mostradores, vitrinas y estanterías'
              }, {
                  "value": 1,
                  "label": 'Fabricación de mobiliario para oficina'
              }, {
                  "value": 1,
                  "label": 'Fabricación de mueblas para cocina'
              }, {
                  "value": 1,
                  "label": 'Fabricación de muebles y partes de muebles, principalmente de otros materiales (metal, plástico)'
              }, {
                  "value": 1,
                  "label": 'Fabricación de somieres y colchones'
              }, {
                  "value": 1,
                  "label": 'Fabricación de  bancos, sillas y otros asientos para laboratorios y mobiliarios de laboratorios'
              }, {
                  "value": 1,
                  "label": 'Acabados, tales como tapicería de sillas y asientos'
              }, {
                  "value": 1,
                  "label": 'Acabado de muebles, tales como la fumigación, pintura y pulido'
              }, {
                  "value": 1,
                  "label": 'Otras industrias manufactureras N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Fabricación de joyas y artículos conexos'
              }, {
                  "value": 3,
                  "label": 'Fabricación de objetos de platería y artículos enchapados'
              }, {
                  "value": 3,
                  "label": 'Fabricación de bisutería y accesorios'
              }, {
                  "value": 3,
                  "label": 'Combinaciones de metales preciosos y piedras o de otros materiales preciosos o semipreciosos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de vajillas, cubiertos, artículos de tocador, artículos de oficina o de escritorio y artículos para uso religioso'
              }, {
                  "value": 2,
                  "label": 'Fabricación de crisoles, espátulas, ánodos de galvanoplasta'
              }, {
                  "value": 2,
                  "label": 'Fabricación de relojes de metales preciosos, muñequeras y correas de relojes'
              }, {
                  "value": 3,
                  "label": 'Fabricación de joyas que contienen piedras de imitación tales como imitación de diamantes'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos musicales'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos de cuerda con teclado, incluidos los pianos automáticos'
              }, {
                  "value": 2,
                  "label": 'Fabricación de órganos de tubo y teclado'
              }, {
                  "value": 2,
                  "label": 'Fabricación de acordeones e instrumentos similares incluyendo los órganos de boca'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos de viento'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos musicales de percusión'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos musicales que el sonido se produce por vía electrónica'
              }, {
                  "value": 2,
                  "label": 'Fabricación de cajas de música'
              }, {
                  "value": 2,
                  "label": 'Fabricación de instrumentos, piezas y accesorios'
              }, {
                  "value": 2,
                  "label": 'Fabricación de artículos de deporte'
              }, {
                  "value": 2,
                  "label": 'Fabricación de artículos de deporte (incluye equipos de deporte, para gimnasios y campos de juego, equipos de pesca y camping)'
              }, {
                  "value": 2,
                  "label": 'Fabricación de pelotas inflables duras y suaves'
              }, {
                  "value": 2,
                  "label": 'Fabricación de esquís, bastones y botas de esquí'
              }, {
                  "value": 2,
                  "label": 'Fabricación de velas y Tablas de surf'
              }, {
                  "value": 2,
                  "label": 'Fabricación de artículos para la pesca deportiva'
              }, {
                  "value": 2,
                  "label": 'Fabricación de artículos para la caza, el montañismo'
              }, {
                  "value": 2,
                  "label": 'Fabricación de patines de hielo, patines'
              }, {
                  "value": 2,
                  "label": 'Fabricación de arcos y ballestas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de equipo deportivo para gimnasio'
              }, {
                  "value": 1,
                  "label": 'Fabricación de juegos y juguetes'
              }, {
                  "value": 1,
                  "label": 'Fabricación de juegos y juguetes'
              }, {
                  "value": 1,
                  "label": 'Fabricación de muñecas, partes y accesorios'
              }, {
                  "value": 1,
                  "label": 'Fabricación de figuras de acción'
              }, {
                  "value": 1,
                  "label": 'Fabricación de animales de juguete'
              }, {
                  "value": 1,
                  "label": 'Fabricación de instrumentos musicales de juguete'
              }, {
                  "value": 1,
                  "label": 'Fabricación de naipes'
              }, {
                  "value": 1,
                  "label": 'Fabricación de juegos de mesa y similares'
              }, {
                  "value": 1,
                  "label": 'Fabricación de juegos electrónicos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de juegos que funcionan con monedas, billares, mesas especiales para juego de casino'
              }, {
                  "value": 1,
                  "label": 'Otras industrias manufactureras'
              }, {
                  "value": 1,
                  "label": 'Fabricación de lápices, lapiceras, bolígrafos, sellos y artículos similares para oficinas'
              }, {
                  "value": 1,
                  "label": 'Fabricación de lápices conduce'
              }, {
                  "value": 1,
                  "label": 'Fabricación de brochas, pinceles, escobas y cepillos que constituyan entre ellos partes de maquinas'
              }, {
                  "value": 1,
                  "label": 'Fabricación de lámparas'
              }, {
                  "value": 2,
                  "label": 'Fabricación de velas, cirios y similares'
              }, {
                  "value": 1,
                  "label": 'Fabricación de ramos, coronas y cestas de flores, flores, frutas y follaje artificiales'
              }, {
                  "value": 1,
                  "label": 'Fabricación de pelucas'
              }, {
                  "value": 1,
                  "label": 'Fabricación de botones, sujetadores de equipos y cremalleras'
              }, {
                  "value": 3,
                  "label": 'Fabricación de fósforos'
              }, {
                  "value": 1,
                  "label": 'Fabricación de paraguas, sombrillas de sol, bastones'
              }, {
                  "value": 3,
                  "label": 'Fabricación de encendedores de cigarrillos'
              }, {
                  "value": 1,
                  "label": 'Otras industrias manufactureras N. C. P. (incluye la fabricación de cochecitos de bebe, termos, etc.)'
              }, {
                  "value": 3,
                  "label": 'Recogida de desperdicios y desechos metálicos'
              }, {
                  "value": 3,
                  "label": 'Recogida de desperdicios y desechos metálicos'
              }, {
                  "value": 3,
                  "label": 'Reciclamiento de desperdicios y desechos no metálicos'
              }, {
                  "value": 3,
                  "label": 'Reciclamiento de desperdicios y desechos no metálicos'
              }, {
                  "value": 2,
                  "label": 'SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO'
              }, {
                  "value": 2,
                  "label": 'Suministro de electricidad, gas, vapor y aire acondicionado'
              }, {
                  "value": 2,
                  "label": 'Producción, transmisión y distribución de energía eléctrica'
              }, {
                  "value": 2,
                  "label": 'Generación de energía térmica convencional (incluye la producción de energía eléctrica mediante maquinas turbogas, turbo vapor)'
              }, {
                  "value": 3,
                  "label": 'Generación de energía térmica nuclear (incluye la producción de energía mediante combustible nuclear)'
              }, {
                  "value": 1,
                  "label": 'Generación de energía hidráulica (incluye la producción de energía eléctrica mediante centrales de bombeo)'
              }, {
                  "value": 1,
                  "label": 'Generación de energía N. C. P. (incluye la producción de energía eléctrica mediante fuentes de energía solar, biomosa, eólica, etc.)'
              }, {
                  "value": 2,
                  "label": 'Transporte de energía eléctrica'
              }, {
                  "value": 2,
                  "label": 'Distribución de energía eléctrica'
              }, {
                  "value": 1,
                  "label": 'Venta de electricidad para el usuario'
              }, {
                  "value": 1,
                  "label": 'Actividades de agentes que organizan la venta de energía'
              }, {
                  "value": 2,
                  "label": 'Operación de electricidad y capacidad de transmisión para los intercambios de energía eléctrica'
              }, {
                  "value": 3,
                  "label": 'Fabricación de gas, distribución de combustible gaseoso por tubería'
              }, {
                  "value": 3,
                  "label": 'Fabricación y distribución de gas (no incluye el transporte por gaseoductos)'
              }, {
                  "value": 3,
                  "label": 'Fabricación de combustibles gaseosos con un valor calorífico'
              }, {
                  "value": 2,
                  "label": 'Venta de gas a los usuarios'
              }, {
                  "value": 3,
                  "label": 'Actividades ligadas al gas o agentes intermediarios'
              }, {
                  "value": 3,
                  "label": 'Productos básicos y la capacidad de transporte de los combustibles gaseosos'
              }, {
                  "value": 3,
                  "label": 'Fabricación y distribución de combustibles gaseosos N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Suministro de vapor y agua caliente'
              }, {
                  "value": 3,
                  "label": 'Suministro de vapor y agua caliente'
              }, {
                  "value": 3,
                  "label": 'Producción y distribución de aire frío'
              }, {
                  "value": 3,
                  "label": 'Producción y distribución de agua fría para refrigeración'
              }, {
                  "value": 2,
                  "label": 'Producción de hielo'
              }, {
                  "value": 2,
                  "label": 'Captación, tratamiento y suministro de agua'
              }, {
                  "value": 2,
                  "label": 'Captación, depuración y distribución de agua de fuentes subterráneas'
              }, {
                  "value": 2,
                  "label": 'Purificación de agua para fines de abastecimiento de agua'
              }, {
                  "value": 2,
                  "label": 'Tratamiento de agua industrial y para otros fines'
              }, {
                  "value": 2,
                  "label": 'Distribución de agua a través de la red, por camiones u otros medios'
              }, {
                  "value": 2,
                  "label": 'Captación, depuración y distribución de agua de fuentes superficiales'
              }, {
                  "value": 2,
                  "label": 'Recogida del agua de lluvia'
              }, {
                  "value": 2,
                  "label": 'Desalinización de agua de mar o subterránea para producir agua como principal producto de interés'
              }, {
                  "value": 2,
                  "label": 'Operación de los canales de riego'
              }, {
                  "value": 3,
                  "label": 'CONSTRUCCIÓN'
              }, {
                  "value": 3,
                  "label": 'Preparación de terrenos para obras'
              }, {
                  "value": 3,
                  "label": 'Demolición y voladura de edificios y sus partes (incluye los trabajos de limpieza de escombros asociados a la demolición)'
              }, {
                  "value": 3,
                  "label": 'Perforación y sondeo excepto la perforación de pozos de petróleo, de gas, de minas'
              }, {
                  "value": 3,
                  "label": 'Otras actividades de desarrollo y preparación de los minerales y propiedades de los sitios'
              }, {
                  "value": 2,
                  "label": 'Obras de drenaje'
              }, {
                  "value": 3,
                  "label": 'Drenaje de los productos agrícolas y forestales'
              }, {
                  "value": 2,
                  "label": 'Movimiento de suelos y preparación de terrenos para obras N. C. P. (incluye el drenaje, remoción de rocas, excavación de zanjas)'
              }, {
                  "value": 2,
                  "label": 'Limpieza de obras de construcción'
              }, {
                  "value": 3,
                  "label": 'Excavación de zanjas, eliminación de rocas y voladuras'
              }, {
                  "value": 3,
                  "label": 'Construcción de edificios'
              }, {
                  "value": 3,
                  "label": 'Construcción, reforma y reparación de edificios residenciales (incluye construcción, reforma y reparación de viviendas unifamiliares)'
              }, {
                  "value": 3,
                  "label": 'Construcción de viviendas unifamiliares'
              }, {
                  "value": 3,
                  "label": 'Remodelación o renovación de estructuras residenciales existentes'
              }, {
                  "value": 3,
                  "label": 'Construcción, reforma y reparación de edificios no residenciales (incluye la construcción, reforma y reparación de restaurantes, bares, etc.)'
              }, {
                  "value": 3,
                  "label": 'Construcción de hospitales, escuelas y edificios de oficinas'
              }, {
                  "value": 3,
                  "label": 'Construcción de edificios de aeropuertos'
              }, {
                  "value": 3,
                  "label": 'Construcción de instalaciones deportivas'
              }, {
                  "value": 3,
                  "label": 'Construcción de garajes incluyendo los garajes subterráneos'
              }, {
                  "value": 3,
                  "label": 'Construcción de almacenes'
              }, {
                  "value": 3,
                  "label": 'Construcción de edificios religiosos'
              }, {
                  "value": 3,
                  "label": 'Construcción, reforma y reparación de edificios turísticos'
              }, {
                  "value": 3,
                  "label": 'Construcción, reforma y reparación de obras hidráulicas (incluye obras fluviales y canales, acueductos, diques, etc.)'
              }, {
                  "value": 3,
                  "label": 'Construcción de embalses'
              }, {
                  "value": 3,
                  "label": 'Construcción de sistemas de alcantarillado, incluyendo la reparación'
              }, {
                  "value": 3,
                  "label": 'Construcción de plantas de eliminación de aguas residuales'
              }, {
                  "value": 3,
                  "label": 'Construcción de estaciones de bombeo'
              }, {
                  "value": 3,
                  "label": 'Construcción de plantas de energía'
              }, {
                  "value": 3,
                  "label": 'Construcción, reforma y reparación de las calles, caminos, puentes, túneles'
              }, {
                  "value": 1,
                  "label": 'Pintura sobre el pavimento y otro tipo de marcado'
              }, {
                  "value": 1,
                  "label": 'Instalación de barreras, señales de tráfico'
              }, {
                  "value": 3,
                  "label": 'Obras de vías férreas'
              }, {
                  "value": 3,
                  "label": 'Construcción, reforma y reparación de obras de infraestructuras de transporte'
              }, {
                  "value": 3,
                  "label": 'Construcción de túneles'
              }, {
                  "value": 3,
                  "label": 'Construcción, reforma y reparación de redes (incluye la construcción, reforma y reparación de redes de electricidad, gas)'
              }, {
                  "value": 3,
                  "label": 'Construcción de tuberías en zonas urbanas de comunicación y líneas eléctricas'
              }, {
                  "value": 3,
                  "label": 'Construcción de línea de agua principal'
              }, {
                  "value": 3,
                  "label": 'Fabricación de pozos de agua'
              }, {
                  "value": 3,
                  "label": 'Actividades de hincado de pilotes, cimentación y otros trabajos de hormigón armado'
              }, {
                  "value": 2,
                  "label": 'Prueba de humedad y agua'
              }, {
                  "value": 2,
                  "label": 'Humidificación de los edificios'
              }, {
                  "value": 2,
                  "label": 'Hundimiento de ejes'
              }, {
                  "value": 2,
                  "label": 'Plegado de acero'
              }, {
                  "value": 2,
                  "label": 'Colocación de ladrillos y piedras'
              }, {
                  "value": 2,
                  "label": 'Cubrimiento con tejado de los edificios residenciales'
              }, {
                  "value": 2,
                  "label": 'Montaje y desmontaje de andamios y plataformas de trabajo excluyendo el alquiler de andamios y plataformas de trabajo'
              }, {
                  "value": 2,
                  "label": 'Construcción de chimeneas y hornos industriales'
              }, {
                  "value": 2,
                  "label": 'Trabajos sobre estructuras altas'
              }, {
                  "value": 2,
                  "label": 'Actividades especializadas de construcción N. C. P. (incluye el alquiler e instalación de andamios)'
              }, {
                  "value": 3,
                  "label": 'Construcción de refinerías'
              }, {
                  "value": 3,
                  "label": 'Construcción de plantas químicas'
              }, {
                  "value": 3,
                  "label": 'Construcción de obras marítimas'
              }, {
                  "value": 3,
                  "label": 'Obras de ingeniería civil N. C.P. (incluye los trabajos generales de construcción para la minería y la industria y centrales eléctricas)'
              }, {
                  "value": 3,
                  "label": 'Acondicionamiento de edificios'
              }, {
                  "value": 1,
                  "label": 'Instalación de ascensores, montacargas y escaleras mecánicas'
              }, {
                  "value": 1,
                  "label": 'Instalación de puertas giratorias automatizadas'
              }, {
                  "value": 1,
                  "label": 'Instalación de pararrayos'
              }, {
                  "value": 1,
                  "label": 'Instalación de sistemas de limpieza'
              }, {
                  "value": 1,
                  "label": 'Instalación de sistemas de iluminación, control y señalización eléctrica para el transporte'
              }, {
                  "value": 1,
                  "label": 'Instalación de sistemas de iluminación de pistas de aterrizajes en aeropuertos'
              }, {
                  "value": 1,
                  "label": 'Ejecución y mantenimiento de instalaciones eléctricas y electrónicas N. C.P. (incluye la instalación de antenas, pararrayos)'
              }, {
                  "value": 2,
                  "label": 'Cableado eléctrico y accesorios'
              }, {
                  "value": 2,
                  "label": 'Cableado de telecomunicación'
              }, {
                  "value": 2,
                  "label": 'Instalación de equipos de red, cableado de televisión por cable'
              }, {
                  "value": 2,
                  "label": 'Instalación de antenas parabólicas'
              }, {
                  "value": 2,
                  "label": 'Instalación de alarmas contra incendios'
              }, {
                  "value": 2,
                  "label": 'Instalación de sistemas de alarmas de seguridad'
              }, {
                  "value": 2,
                  "label": 'Aislamiento térmico, acústico, hídrico y anti vibratorio'
              }, {
                  "value": 2,
                  "label": 'Instalaciones de gas, agua y sanitarios con sus artefactos conexos (incluyendo las instalaciones de compactadores, calderas)'
              }, {
                  "value": 2,
                  "label": 'Instalación de tuberías de vapor'
              }, {
                  "value": 2,
                  "label": 'Instalación de sistemas de rociadores contra incendio'
              }, {
                  "value": 2,
                  "label": 'Instalación de sistema de riego de césped'
              }, {
                  "value": 2,
                  "label": 'Construcción e instalación de equipos de climatización'
              }, {
                  "value": 2,
                  "label": 'Instalación de hornos, torres de enfriamiento'
              }, {
                  "value": 2,
                  "label": 'Instalación de colectores de energía solar no eléctricos'
              }, {
                  "value": 2,
                  "label": 'Instalación de ventilador, refrigeración o aire acondicionado o conductos'
              }, {
                  "value": 2,
                  "label": 'Instalaciones para edificios y obras de ingeniería N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Instalación de cocinas equipadas'
              }, {
                  "value": 2,
                  "label": 'Instalación de mobiliarios'
              }, {
                  "value": 2,
                  "label": 'Terminación de edificios'
              }, {
                  "value": 2,
                  "label": 'Instalaciones de carpintería, herrería de obra y artística (incluye la instalación de puertas y ventanas, carpintería metálica y no metálica)'
              }, {
                  "value": 2,
                  "label": 'Instalación de carpintería, herrería de obra y artística'
              }, {
                  "value": 2,
                  "label": 'Aplicación de yeso y estuco en edificios y otras obras de construcción de interior y exterior'
              }, {
                  "value": 2,
                  "label": 'Terminación y revestimiento de paredes y pisos (incluye la instalación de yesería, salpicré, pulido de pisos y colocación de revestimiento de cerámica)'
              }, {
                  "value": 2,
                  "label": 'Instalación de cerámica, hormigón, paredes talladas en piedras o baldosas'
              }, {
                  "value": 2,
                  "label": 'Instalación de alfombras y linóleo para suelos, incluidos los de caucho o de plástico'
              }, {
                  "value": 3,
                  "label": 'Instalación de terrazo, mármol, granito o revestimiento de paredes'
              }, {
                  "value": 1,
                  "label": 'Instalación de papel tapiz'
              }, {
                  "value": 1,
                  "label": 'Colocación de cristales en obra (incluye la instalación y revestimiento de vidrio, espejos y otros artículos de vidrio)'
              }, {
                  "value": 1,
                  "label": 'Pintura y trabajos de decoración'
              }, {
                  "value": 2,
                  "label": 'Pintura de estructuras de ingeniería civil'
              }, {
                  "value": 2,
                  "label": 'Terminación de obras de ingeniería civil N. C. P. (incluye trabajos de ornamentación, limpieza de exterior de edificios)'
              }, {
                  "value": 2,
                  "label": 'Limpieza de los edificios nuevos después de la construcción'
              }, {
                  "value": 2,
                  "label": 'Alquiler de equipos de construcción o demolición dotados de operarios'
              }, {
                  "value": 2,
                  "label": 'Alquiler de equipo de construcción o demolición dotado de operario'
              }, {
                  "value": 2,
                  "label": 'Alquiler de grúas con operador'
              }, {
                  "value": 2,
                  "label": 'COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACIÓN DE LOS VEHÍCULOS DE MOTOR Y DE LAS MOTOCICLETAS'
              }, {
                  "value": 2,
                  "label": 'Comercio al por mayor y al por menor, reparación de vehículos automotores y motocicletas'
              }, {
                  "value": 2,
                  "label": 'Ventas de vehículos automotores, excepto motocicleta'
              }, {
                  "value": 2,
                  "label": 'Venta de autos, camionetas y utilitarios, nuevos (incluye taxis, jeeps, 4 X 4 y vehículos similares)'
              }, {
                  "value": 2,
                  "label": 'Venta de vehículos automotores, nuevos N. C. P. (incluye casas rodantes, tráiler, remolques, ambulancias, ómnibus)'
              }, {
                  "value": 2,
                  "label": 'Venta de autos, camionetas y utilitarios, usados (incluye taxis, jeeps, 4 X 4 y vehículos similares)'
              }, {
                  "value": 2,
                  "label": 'Venta de vehículos automotores, usados N. C. P. (incluye casas rodantes, tráiler, camiones, remolques, ambulancias, ómnibus)'
              }, {
                  "value": 2,
                  "label": 'Venta de equipos y aparatos de transporte aéreo y marítimo'
              }, {
                  "value": 2,
                  "label": 'Venta de vehículos de camping, como caravanas y autocaravanas'
              }, {
                  "value": 2,
                  "label": 'Subastas de coches'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor o al por menor por comisión de agentes'
              }, {
                  "value": 2,
                  "label": 'Venta de vehículos automotores de pasajeros y vehículos de motor especializados como ambulancias y minibuses'
              }, {
                  "value": 2,
                  "label": 'Mantenimiento y reparación de vehículos de motor'
              }, {
                  "value": 1,
                  "label": 'Lavado automático y manual'
              }, {
                  "value": 1,
                  "label": 'Reparación de cámaras y cubiertas (incluye reparación de llantas)'
              }, {
                  "value": 1,
                  "label": 'Reparación de amortiguadores, alineación de dirección y balanceo de ruedas'
              }, {
                  "value": 1,
                  "label": 'Alineación de dirección y balanceo de ruedas'
              }, {
                  "value": 1,
                  "label": 'Instalación y reparación de lunetas y ventanillas, alarmas, cerraduras,  radios, sistemas de climatización automotor'
              }, {
                  "value": 1,
                  "label": 'Tapizado y retapizado'
              }, {
                  "value": 1,
                  "label": 'Reparaciones eléctricas, del tablero e instrumental, reparación y recarga de baterías'
              }, {
                  "value": 1,
                  "label": 'Reparación y pintura de carrocería; colocación de guardabarros y protecciones de exteriores'
              }, {
                  "value": 2,
                  "label": 'Tratamiento antióxido'
              }, {
                  "value": 2,
                  "label": 'Instalación y reparación de caños de escape'
              }, {
                  "value": 2,
                  "label": 'mantenimiento y reparación de frenos'
              }, {
                  "value": 2,
                  "label": 'Rectificación de motores'
              }, {
                  "value": 2,
                  "label": 'Mantenimiento y reparación del motor N. C. P. mecánica integral (incluye auxilio y servicio de grúa)'
              }, {
                  "value": 2,
                  "label": 'Reparaciones de sistemas de inyección electrónica'
              }, {
                  "value": 2,
                  "label": 'Prestación de servicios ordinarios'
              }, {
                  "value": 2,
                  "label": 'Reparación de piezas de vehículos de motor'
              }, {
                  "value": 2,
                  "label": 'Venta de partes, piezas y accesorios de vehículos automotores'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de partes y piezas y accesorios de vehículos automotores'
              }, {
                  "value": 2,
                  "label": 'Venta de llantas de goma y tubos'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor d tubo para neumáticos y cubiertas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de baterías'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de partes, piezas y accesorios excepto neumáticos, cubiertas, parabrisas'
              }, {
                  "value": 2,
                  "label": 'Venta, mantenimiento y reparación de motocicletas y de sus partes y piezas'
              }, {
                  "value": 2,
                  "label": 'Venta de motocicleta y de sus partes, piezas y accesorios'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor y al por menor de partes y piezas de motocicletas'
              }, {
                  "value": 2,
                  "label": 'Mantenimiento y reparación de motocicletas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de combustibles para vehículos en almacenes especializados'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de combustible para vehículos automotores y motocicletas (incluye estaciones de servicios)'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de productos lubricantes y productos de refrigeración para vehículos de motor'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor a cambio de una retribución o contrata'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor en comisión o consignación de cereales (incluye arroz), oleaginosas y forrajeras'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor en comisión o consignación de semillas'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor en comisión o consignación de productos agrícolas N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Operación de intermediación de ganado en pie (incluye consignatarios de hacienda y ferieros)'
              }, {
                  "value": 3,
                  "label": 'Operaciones de intermediación de lanas, cueros y productos afines de terceros'
              }, {
                  "value": 3,
                  "label": 'Operación de intermediación de carne (consignatario directo)'
              }, {
                  "value": 3,
                  "label": 'Operación de intermediación de carne excepto consignatario directo'
              }, {
                  "value": 3,
                  "label": 'Corretaje de vino'
              }, {
                  "value": 3,
                  "label": 'Venta de materias primas agrícolas, animales vivos, materias primas textiles y productos semielaborados'
              }, {
                  "value": 3,
                  "label": 'Venta de combustible, minerales, metales y productos químicos industriales incluyendo los fertilizantes'
              }, {
                  "value": 2,
                  "label": 'Venta de maquinaria incluyendo de oficina y computadoras, equipos industriales, barcos y aviones'
              }, {
                  "value": 2,
                  "label": 'Venta de muebles y enseres domésticos'
              }, {
                  "value": 3,
                  "label": 'Actividades de venta al por mayor en casas de subasta'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor en comisión o consignación de alimentos, bebidas y tabaco N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor en comisión o consignación de productos textiles, prendas de vestir, calzados, paraguas'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor en comisión o consignación de madera y materiales de construcción'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor en comisión o consignación de energía eléctrica, gas y combustibles'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor en comisión o consignación de minerales, metales y productos químicos industriales'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor en comisión o consignación de maquinaria, equipo profesional industrial y comercial, embarcaciones y aeronaves'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor en comisión o consignación de papel, cartón, libros, revistas, diarios y materiales de embalaje'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor en comisión o consignación de materiales N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de materias primas agropecuarias y animales vivos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de cereales (arroz), oleaginosas y forrajeras excepto semillas'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de semillas'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de tabaco sin manufacturar'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de animales vivos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de materia prima agrícola y de silvicultura N. C. P. (incluye el acopio y venta al por mayor de frutas, flores, etc.)'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de lanas, cueros en bruto y productos afines'
              }, {
                  "value": 3,
                  "label": 'Ventas al por mayor de artículos de cuero'
              }, {
                  "value": 3,
                  "label": 'Ventas al por mayor de alimentos, bebidas y tabaco'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de productos lácteos'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de fiambres y quesos'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de leche'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de carnes y productos derivados excepto las de aves'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de aves, huevos y productos de granjas y de la caza N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de pescado'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de mariscos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor y empaque de frutas, legumbres y hortalizas frescas (incluye la conservación en cámaras frigoríficas)'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de pan, productos de confitería y pastas frescas'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de chocolates, golosinas y productos de confitería para quioscos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de azúcar'
              }, {
                  "value": 3,
                  "label": 'Ventas al por mayor de aceites y grasas'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de café, té, yerba mate y otras infusiones'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de condimentos y especias'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de productos y subproductos de molinería'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de frutas, legumbres y cereales secos en conserva'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de alimentos para animales'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de alimentos N. C. P. (incluye la venta de miel y derivados, productos congelados, etc.)'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de vino'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de bebidas espiritosas'
              }, {
                  "value": 2,
                  "label": 'Compra de vino a granel y embotellado sin transformación'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de bebidas alcohólicas N. C. P. (incluye la venta de aperitivos con alcohol, cerveza, sidra, etc.)'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de bebidas no alcohólicas (incluye la venta de aguas, sodas, bebidas refrescantes, jarabes, extractos, etc.)'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de cigarrillos y productos de tabaco'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de textiles, prendas de vestir y calzado'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de productos textiles, excepto telas, tejidos, prendas y accesorios de vestir'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de tejidos (telas)'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos de mercería (incluye la venta de puntillas, galones, hombreras, agujas, botones, etc.)'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de mantelería y artículos textiles para el hogar'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de tapices y alfombras de materiales textiles'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de ropa'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de prendas de vestir y ropa de cama'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de ropa para deportes'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de prendas de vestir de cuero'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de medias y prendas de puntos'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de corbatas, tirantes y guantes'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de prendas de vestir N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de calzados de todo tipo excepto los ortopédicos (incluye venta de calzado de cuero, tela, plástico, etc.)'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de pieles, cueros curtidos y salados'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de suelas y afines (incluye talabartería, artículos regionales de cuero, almacenes de suela, etc.)'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos de cuero y accesorios de viaje'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos de marroquería, paraguas y productos similares N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de libros y publicaciones'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de diarios y revistas'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de papel y productos de papel y cartón excepto envases'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de envases de papel y cartón'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos de librería y papelería'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de productos farmacéuticos (incluye venta de medicamentos y kits de diagnósticos)'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de productos veterinarios'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de productos cosméticos, de artículos de tocador y de perfumería'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de instrumental médico, odontológico y artículos ortopédicos (incluye venta de vaporizadores, nebulizadores, etc.)'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de aparatos fotográficos e instrumentos de óptica (incluye la venta de lentes de contacto, líquidos oftalmológicos, armazones, etc.)'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de relojes y joyas'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de muebles metálicos excepto de oficina'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de artículos de mimbre y corcho; colchones y Simones'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de muebles N. C. P. excepto de oficinas, artículos de mimbre y corcho'
              }, {
                  "value": 1,
                  "label": 'Venta al por mayor de aparatos de iluminación'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos de vidrio'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos de bazar, menaje excepto de vidrio'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de artefactos eléctricos, gas, kerosene u otros combustibles para el hogar'
              }, {
                  "value": 1,
                  "label": 'Venta al por mayor de instrumentos musicales, discos y casetes de audio y video'
              }, {
                  "value": 1,
                  "label": 'Venta al por mayor de equipos de sonido, radio y televisión, comunicaciones y sus componentes'
              }, {
                  "value": 1,
                  "label": 'Venta de reproductores y grabadoras de CD y DVD'
              }, {
                  "value": 1,
                  "label": 'Venta de equipos estéreos'
              }, {
                  "value": 1,
                  "label": 'Venta de consolas de videojuego'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de otros enseres domésticos'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de materiales y productos para la limpieza'
              }, {
                  "value": 1,
                  "label": 'Venta al por mayor de juegos y juguetes'
              }, {
                  "value": 1,
                  "label": 'Venta al por mayor de bicicletas, sus partes y accesorios'
              }, {
                  "value": 1,
                  "label": 'Venta al por mayor de artículos deportivos (incluye embarcaciones deportivas, armas y municiones, equipo de pesca)'
              }, {
                  "value": 1,
                  "label": 'Venta al por mayor de papeles para pared, revestimiento de suelo'
              }, {
                  "value": 1,
                  "label": 'Ventas al por mayor de flores y plantas naturales y artificiales'
              }, {
                  "value": 1,
                  "label": 'Venta al por mayor de productos en almacenes y supermercados mayoristas con predominio de alimentos y bebidas'
              }, {
                  "value": 1,
                  "label": 'Suplidores de equipos y materiales para hoteles y restaurantes'
              }, {
                  "value": 1,
                  "label": 'Venta al por mayor de artículos de uso domestico y/o personal N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de combustibles sólidos, líquidos, gaseosos y de productos conexos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de combustibles y lubricantes para automotores'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de carbón de leña, carbón de coque, combustible nafta'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de productos del petróleo refinado'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de gases licuados de petróleo, butano y gas propano'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de aceites lubricantes y grasas, excepto para automotores, leña y carbón'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de metales y minerales metalíferos'
              }, {
                  "value": 3,
                  "label": 'Venta y distribución de fósforos y materiales para su fabricación'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de metales ferrosos y no ferrosos en formas primaria'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de metales ferrosos y no ferrosos semi-acabado de productos metálicos N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de oro y otros metales preciosos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de materiales de construcción, artículos de ferretería y equipos y materiales de fontanería'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de aberturas (ventanas, puertas, cortinas enrollables)'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de productos de primera transformación de la madera'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de madera en bruto'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos de ferretería'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de pinturas y productos conexos'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de vidrio plano y templado'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos de plomería, electricidad, calefacción, obras sanitarias, etc.'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos de loza, porcelana y cerámica de uso en la construcción'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de baños, lavado, inodoros y otros sanitarios de porcelana'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de cerraduras'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de herrajes y accesorios'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de calentadores de agua'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de equipos y materiales eléctricos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de ladrillos, cemento, cal, arena, piedra, mármol y materiales para la construcción N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de otros productos intermedios desperdicios y desechos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de productos intermedios N. C. P. desperdicios y desechos textiles'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de fibras textiles'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de desperdicios y desechos textiles'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de productos intermedios de papel N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de papel a granel'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de desperdicios y desechos de papel y cartón'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de fertilizantes y productos agroquímicos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de caucho y productos de caucho excepto de calzado y autopartes'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de artículos de plásticos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de productos intermedios N. C. P. desperdicios y desechos metálicos (incluye chatarra, viruta de metales diversos)'
              }, {
                  "value": 3,
                  "label": 'Embalaje, envasado y almacenamiento de residuos y desechos'
              }, {
                  "value": 3,
                  "label": 'Compra y venta de residuos que tienen un valor restante'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de anilina, parafina'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de tinta de imprenta y colorante'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de gases industriales, metanol'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de aceites esenciales'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de colas químicas, resinas sintéticas'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de aromas y perfumes'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de soda, sal industrial'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de ácidos y azufres'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de almidón, fécula'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de productos intermedios, desperdicios y desechos N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de maquinaria, equipo y materiales conexos'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de maquinas, equipos e implementos de uso en los sectores agropecuario, jardinería, silvicultura, pesca y caza'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de arados, esparcidor de estiércol y sembradoras'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de trilladoras'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de maquinas de ordeño'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de tractores utilizados en la agricultura y silvicultura'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de maquinas avícolas y apicultoras'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de maquinas, equipos e implementos de uso en la elaboración de alimentos, bebidas y tabacos'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de equipos controlados por maquinaria para la industria textil'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de equipos e instrumentos de medida y control'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de maquinas, equipos e implementos de uso en imprentas, artes graficas y actividades conexas'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de maquinas, equipos e implementos de uso médico y paramédico'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de maquinas, equipos e implementos de uso en la industria de caucho y plástico'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de maquinas, equipos e implementos de uso en la construcción'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de maquinas, equipos e implementos de uso especial N.C.P.'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de maquinas-herramientas de uso general'
              }, {
                  "value": 2,
                  "label": 'Venta, reparación o alquilar de plantas eléctricas'
              }, {
                  "value": 2,
                  "label": 'Venta de productos para la fabricación de equipos para la generación de energía alternativa'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de vehículos, equipos y maquinas para el transporte ferroviario, aéreo y de navegación'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor e instalación de muebles de oficinas'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de maquinas y equipos de oficina'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor e instalación de muebles para industrias, comercios'
              }, {
                  "value": 3,
                  "label": 'Venta al por mayor de equipo profesional y científico'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de equipos informáticos y maquinas electrónicas de escribir y calcular'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de maquinas y equipos de comunicación, control y seguridad (incluye la venta de teléfonos y equipos de comunicación)'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de software'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de maquinas, equipos y materiales conexos'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de otros productos'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de mercancías N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Venta al por mayor de artículos regionales'
              }, {
                  "value": 2,
                  "label": 'Venta y distribución de tarjetas de llamadas telefónicas'
              }, {
                  "value": 3,
                  "label": 'Venta y distribución de fuegos artificiales'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de artículos relacionados con la salud'
              }, {
                  "value": 1,
                  "label": 'Comercio al por menor, excepto el comercio de vehículos automotores y motocicletas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor en almacenes no especializados'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor en almacenes no especializados, con surtido compuesto principalmente de alimentos, bebidas y tabaco'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor en hipermercados con predominio en productos alimentarios y bebidas, grandes tiendas, plazas'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de alimentos, bebidas y productos de tabaco en puestos de ventas ambulantes'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor en supermercados con predominio de productos alimenticios y bebidas'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de bebidas y productos del tabaco'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor en almacenes con predominio de productos alimenticios y bebidas'
              }, {
                  "value": 2,
                  "label": 'Colmados'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor en kioscos, poli rubros y comercios no especializados N.C.P.'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artefactos y juguetes'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos deportivos'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de artículos de ferretería'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor excepto la especializada, sin predominio de productos alimenticios y bebidas'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de alimenticios, bebidas o tabaco en almacenes especializados'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de productos lácteos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de embutidos y fiambre'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de bebidas'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de tabaco'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de productos de almacén y dietética'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de carne y productos cárnicos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de huevo, carne de aves y productos de granja y de la caza N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de frutas y hortalizas frescas o en conserva'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de pan y de productos de panadería'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de bombones, golosinas y demás artículos de confitería'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de bebidas no alcohólicas'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de bebidas alcohólicas (licor store)'
              }, {
                  "value": 2,
                  "label": 'Colmadones'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de café y te'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de pescados, mariscos y otros productos'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de cigarros y cigarrillos'
              }, {
                  "value": 3,
                  "label": 'Venta de animales y aves vivas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de alimentos para animales de compañía'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de tabaco'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de productos alimenticios N. C. P. en comercios especializados'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de productos farmacéuticos, medicinales, cosméticos y artículos de tocador en almacenes especializados'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de productos farmacéuticos'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de productos de herbostería'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de perfumes y cosméticos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de productos médicos y ortopédicos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de audífonos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de cosméticos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de prendas de vestir'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de muebles'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de electrodomésticos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de productos textiles, prendas de vestir, calzados y artículos de cuero en almacenes especializados'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de hilados de tejidos de punto'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de lana'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de agujas, hilo de cocer, etc. (mercerías)'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de tejidos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de confecciones para el hogar (incluye la venta al por menor de cortinas, sabanas y toallas)'
              }, {
                  "value": 2,
                  "label": 'Venta de tapices, cortinas y alfombras'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de telas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de materiales básicos para hacer alfombras, tapices o bordados'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de productos textiles'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos textiles N. C. P. excepto prendas de vestir'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de ropa interior, medias, prendas para dormir y prendas para la playa'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de indumentaria para trabajo, uniformes y guardapolvos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de indumentaria para bebes y niños'
              }, {
                  "value": 2,
                  "label": 'Sastrerías y atelier'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos de cuero'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de carteras'
              }, {
                  "value": 2,
                  "label": 'Venta de sombreros, guantes, pañuelo y corbata'
              }, {
                  "value": 2,
                  "label": 'Boutiques'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de prendas y accesorios de vestir N. C. P. excepto calzados, artículos de marroquería, paraguas, etc.'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos regionales y de talabartería'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de calzados excepto los ortopédicos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de marroquerías, paraguas y similares'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de aparatos para el hogar y equipo N. C. P.'
              }, {
                  "value": 1,
                  "label": 'Venta al por menor de mobiliario domestico'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de colchones y Simones'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos de iluminación'
              }, {
                  "value": 2,
                  "label": 'Ventas al por menor de artículos de bazar y menaje'
              }, {
                  "value": 3,
                  "label": 'Actividades de casas de subastas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artefactos para el hogar electrónicos, gas, a kerosene u otros combustibles'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de instrumentos musicales, equipos de sonido, casetes de audio y video'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de pinturas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de puertas, ventanas, cortinas enrollables y puertas corredizas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de madera, artículos de madera y corcho excepto muebles'
              }, {
                  "value": 1,
                  "label": 'Venta al por menor de artículos para el hogar y equipo de uso domestico N.C.P.'
              }, {
                  "value": 2,
                  "label": 'Compra y venta de artículos nuevos y usados'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos de ferretería, pinturas y productos de vidrio'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de artículos de ferretería'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de equipos y materiales del tipo hágalo usted mismo'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de cortadoras de césped'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de saunas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de equipos y materiales eléctricos'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de pintura, barnices y lacas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos de plomería e instalación de gas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de cristales, espejos, mamparas y cerramientos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de papel pintado y revestimiento de piso y artículos similares para la decoración'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de equipos sanitarios'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de grava, cascajo en estado natural'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de baldosas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de ladrillos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de madera'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de materiales de construcción N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de material fotográfico, óptico y de precisión'
              }, {
                  "value": 2,
                  "label": 'Actividades de ópticas'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de relojes'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de joyas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos de fantasía'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de todo tipo de libros'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de periódicos y artículos de papelería'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de lápices, bolígrafos, papel, etc.'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de papel, cartón y materiales de embalaje y artículos de librería'
              }, {
                  "value": 1,
                  "label": 'Confección y venta de mapas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de alfombras y tapetes'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de libros'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de juegos y juguetes'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de electrodomésticos y electrónica de consumo'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de música y grabaciones de video'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de productos nuevos en almacenes especializados'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de flores y plantas'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de semillas y fertilizantes'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos de limpieza'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de juegos y juguetes hechos de cualquier material'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos de deporte'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de armas y artículos de cuchillería, artículos para la caza y pesca'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos para la pesca deportiva'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos para acampar'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de botes'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de armas y municiones'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de maquinas y equipos para oficinas y sus componentes y repuestos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de computadoras'
              }, {
                  "value": 2,
                  "label": 'Reparación de teléfonos inalámbricos y celulares'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de consolas de videojuegos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de software no personalizados y juegos de video'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de equipos de telecomunicaciones'
              }, {
                  "value": 2,
                  "label": 'Reparación de portadores de equipos MODEM'
              }, {
                  "value": 2,
                  "label": 'Reparación de máquinas fax'
              }, {
                  "value": 3,
                  "label": 'Reparación de equipo de transmisión de comunicaciones'
              }, {
                  "value": 1,
                  "label": 'Venta al por menor de combustible para uso domestico, gas embasado, carbón y leña'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de productos veterinario y animales domésticos'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de bicicletas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de rodados excepto bicicletas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de artículos de caucho excepto tubos para neumáticos y cubiertas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de maquinas y motores y sus repuestos'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de equipos profesionales y científicos e instrumentos de medidas de control'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de artículos de colección y objetos de arte'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de artículos religiosos'
              }, {
                  "value": 2,
                  "label": 'Enmarcados'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de metales y similares'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de sellos y monedas'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de artículos nuevos N. C. P. (incluye casas de regalos, de artesanías y artículos regionales)'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de mercancías de segunda mano'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de muebles usados'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de libros usados'
              }, {
                  "value": 3,
                  "label": 'Venta al por menor de antigüedades'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor de otros bienes de segunda mano N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Comercio al por menor no realizado en almacenes, puestos de venta o mercados'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor a través de pedio por correo o vía Internet'
              }, {
                  "value": 1,
                  "label": 'Venta al por menor por correo, televisión, Internet y otros medios de comunicación'
              }, {
                  "value": 1,
                  "label": 'Venta al por menor de cualquier tipo de producto por correo'
              }, {
                  "value": 1,
                  "label": 'Venta al por menor de cualquier tipo de producto a través de Internet'
              }, {
                  "value": 1,
                  "label": 'Venta al por menor en subastas por Internet'
              }, {
                  "value": 1,
                  "label": 'Venta directa a través de la televisión, radio y teléfono'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor en puestos móviles'
              }, {
                  "value": 3,
                  "label": 'Venta de combustibles entregado directamente a los clientes'
              }, {
                  "value": 3,
                  "label": 'Actividades de subastas no realizadas en almacenes'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor por comisionistas'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor no realizada en establecimientos N. C. P. (incluye venta mediante maquinas expendedoras, vendedores ambulantes)'
              }, {
                  "value": 2,
                  "label": 'Venta al por menor a través de maquinas expendedoras'
              }, {
                  "value": 2,
                  "label": 'Reparación de otros bienes personales y domésticos N. C. P.'
              }, {
                  "value": 1,
                  "label": 'Reparación de zapatos, botas, artículos de marroquería, etc.'
              }, {
                  "value": 1,
                  "label": 'Instalación de tacones'
              }, {
                  "value": 1,
                  "label": 'Reparación de equipaje y similares'
              }, {
                  "value": 1,
                  "label": 'Retapizado, reacabado, reparación y restauración de los muebles y mobiliarios caseros incluyendo los muebles de oficinas'
              }, {
                  "value": 1,
                  "label": 'Reparación de artículos eléctricos de uso domestico'
              }, {
                  "value": 2,
                  "label": 'Reparación de cortadoras de césped, sopladores de hojas'
              }, {
                  "value": 1,
                  "label": 'Reparación de artículos electrodomésticos'
              }, {
                  "value": 1,
                  "label": 'Reparación de televisión, receptores de radio'
              }, {
                  "value": 1,
                  "label": 'Reparación de aparatos de video (VCR), lectores de CD'
              }, {
                  "value": 1,
                  "label": 'Reparación de cámaras de video tipo domésticas'
              }, {
                  "value": 1,
                  "label": 'Reparación y venta de artículos de refrigeración'
              }, {
                  "value": 1,
                  "label": 'Reparación y modificación de joyas, relojes y sus partes'
              }, {
                  "value": 1,
                  "label": 'Reparación de bicicletas'
              }, {
                  "value": 1,
                  "label": 'Reparación y modificación de la ropa'
              }, {
                  "value": 1,
                  "label": 'Reparación de artículos deportivos'
              }, {
                  "value": 1,
                  "label": 'Reparación de libros'
              }, {
                  "value": 1,
                  "label": 'Reparación de instrumentos musicales y de artículos de pasatiempo, afinación de piano'
              }, {
                  "value": 1,
                  "label": 'Reparación de otros enseres personales y domésticos'
              }, {
                  "value": 1,
                  "label": 'ALOJAMIENTO Y SERVICIOS DE COMIDA'
              }, {
                  "value": 1,
                  "label": 'Alojamiento'
              }, {
                  "value": 1,
                  "label": 'Servicios de alojamiento en hoteles, campamentos y otros tipos de hospedaje temporal'
              }, {
                  "value": 1,
                  "label": 'Servicios de alojamiento en camping (incluye refugios de montaña)'
              }, {
                  "value": 1,
                  "label": 'Cabañas y departamentos para visitantes, vivienda para vacacionar'
              }, {
                  "value": 3,
                  "label": 'Servicios de alojamiento por hora, moteles y cabañas'
              }, {
                  "value": 1,
                  "label": 'Operadores de hoteles'
              }, {
                  "value": 3,
                  "label": 'Servicios de alojamiento en pensiones'
              }, {
                  "value": 3,
                  "label": 'Servicios de alojamiento en hoteles, hosterías y residencias similares, excepto por hora que incluyen servicios de restaurantes'
              }, {
                  "value": 3,
                  "label": 'Servicios de alojamiento en hoteles, hosterías y residencias similares, excepto por hora que no incluyen servicios de restaurantes'
              }, {
                  "value": 3,
                  "label": 'Hoteles Resorts'
              }, {
                  "value": 3,
                  "label": 'Suite y apartahoteles'
              }, {
                  "value": 3,
                  "label": 'Albergues juveniles'
              }, {
                  "value": 3,
                  "label": 'Unidades (establecimientos) con cama y desayuno'
              }, {
                  "value": 3,
                  "label": 'Chalets, casas hogar y cabinas'
              }, {
                  "value": 3,
                  "label": 'Servicios de hospedajes temporales N. C. P. (incluye hospedaje en estancias, residencias para estudiantes y albergues juveniles)'
              }, {
                  "value": 2,
                  "label": 'Servicio de alimento y bebida'
              }, {
                  "value": 2,
                  "label": 'Restaurantes y cantinas sin espectáculos'
              }, {
                  "value": 2,
                  "label": 'Servicios de restaurantes y cantinas sin espectáculos'
              }, {
                  "value": 3,
                  "label": 'Servicios de restaurantes y cantinas con espectáculos'
              }, {
                  "value": 2,
                  "label": 'Servicios de pizzería, Fast food y locales de venta de comidas y bebidas al paso'
              }, {
                  "value": 3,
                  "label": 'Servicios de bares y confiterías (incluye locales de expendio de bebida con servicio de mesa y/o mostrador)'
              }, {
                  "value": 2,
                  "label": 'Operadoras de restaurantes'
              }, {
                  "value": 2,
                  "label": 'Servicios de camarería'
              }, {
                  "value": 2,
                  "label": 'Cafeterías'
              }, {
                  "value": 2,
                  "label": 'Restaurantes de comida rápida'
              }, {
                  "value": 2,
                  "label": 'Servicios de expendio de comida y bebida en establecimientos con mesa y/o mostrador'
              }, {
                  "value": 2,
                  "label": 'Expendio de helados'
              }, {
                  "value": 2,
                  "label": 'Carros móviles de suministro de comida'
              }, {
                  "value": 2,
                  "label": 'Preparación de alimentos en puestos de mercado'
              }, {
                  "value": 2,
                  "label": 'Provisión de comidas preparadas para empresas (incluye el servicio de suministro de comidas para banquetes, etc.)'
              }, {
                  "value": 2,
                  "label": 'Suministro de comidas basado en acuerdos contractuales'
              }, {
                  "value": 2,
                  "label": 'Suministro de comidas para banquetes, bodas, fiestas y otras celebraciones'
              }, {
                  "value": 2,
                  "label": 'Manejo de concesiones de alimentos en instalaciones de deportes y similares'
              }, {
                  "value": 2,
                  "label": 'Servicios de suministro de comidas para contratistas'
              }, {
                  "value": 2,
                  "label": 'Funcionamiento de cantinas y cafeterías sobre una base de concesión'
              }, {
                  "value": 2,
                  "label": 'Preparación y venta de comidas para llevar N. C. P. (incluye casas de comidas, rosticerías, etc.)'
              }, {
                  "value": 2,
                  "label": 'Venta de hielo'
              }, {
                  "value": 3,
                  "label": 'TRANSPORTE Y ALMACENAMIENTO'
              }, {
                  "value": 3,
                  "label": 'Transporte por vía terrestre y transporte por tuberías'
              }, {
                  "value": 3,
                  "label": 'Transporte por vía férrea'
              }, {
                  "value": 3,
                  "label": 'Transporte de carga por líneas férreas principales'
              }, {
                  "value": 3,
                  "label": 'Transporte de carga por líneas férreas de corto recorrido'
              }, {
                  "value": 3,
                  "label": 'Servicios de transporte ferroviario urbano y suburbano de pasajeros (incluye el servicio de subterráneo)'
              }, {
                  "value": 2,
                  "label": 'Transporte terrestre de pasajeros por tranvía'
              }, {
                  "value": 2,
                  "label": 'Transporte terrestre de pasajeros por metro y ferrocarriles elevados'
              }, {
                  "value": 2,
                  "label": 'Servicio de transporte de pasajeros por líneas férreas interurbanas'
              }, {
                  "value": 2,
                  "label": 'Operación de coches camas o coches comedores como parte integrada de operaciones de compañías ferroviarias'
              }, {
                  "value": 3,
                  "label": 'Otros tipos de transporte por vía terrestre'
              }, {
                  "value": 3,
                  "label": 'Servicios de transporte automotor'
              }, {
                  "value": 3,
                  "label": 'Mudanzas y acarreo'
              }, {
                  "value": 3,
                  "label": 'Transporte a granel, incluido los transportes en camiones cisterna'
              }, {
                  "value": 3,
                  "label": 'Transporte de animales vivos'
              }, {
                  "value": 3,
                  "label": 'Transporte urbano de carga N. C. P. (incluye el transporte realizado por fleteros y distribuidores dentro del Egido urbano)'
              }, {
                  "value": 3,
                  "label": 'Transporte de carga pesada N. C. P. (incluye servicio de carga de refrigerada, automotores, transporte)'
              }, {
                  "value": 3,
                  "label": 'Transporte de troncos'
              }, {
                  "value": 3,
                  "label": 'Transporte de valores'
              }, {
                  "value": 3,
                  "label": 'Transporte refrigerado de productos'
              }, {
                  "value": 2,
                  "label": 'Carga de automóviles'
              }, {
                  "value": 3,
                  "label": 'Transporte de residuos y materiales de desechos, sin recogida o eliminación'
              }, {
                  "value": 3,
                  "label": 'Transporte de mercadería y materiales pesados'
              }, {
                  "value": 3,
                  "label": 'Transporte de agregados para la construcción (arena, grava, entre otros)'
              }, {
                  "value": 3,
                  "label": 'Transporte y venta al por menor de combustible'
              }, {
                  "value": 2,
                  "label": 'Transporte automotor urbano regular de pasajeros'
              }, {
                  "value": 2,
                  "label": 'Transporte terrestre de pasajeros por autobús'
              }, {
                  "value": 2,
                  "label": 'Servicios de transporte automotor de pasajeros mediante taxis y remises; alquiler de autos con chofer'
              }, {
                  "value": 3,
                  "label": 'Alquiler de automóviles privados con conductor'
              }, {
                  "value": 2,
                  "label": 'Servicio de transporte escolar (incluye el servicio de transporte para colonias de vacaciones y clubes)'
              }, {
                  "value": 2,
                  "label": 'Operación de autobuses para el transporte de los empleados'
              }, {
                  "value": 2,
                  "label": 'Servicios de transporte automotor de oferta libre de pasajero excepto mediante taxis, alquiler de autos con chofer y transporte escolar'
              }, {
                  "value": 2,
                  "label": 'Servicios de transporte automotor interurbano de pasajeros (incluye los servicios de transporte regular de más de 50km)'
              }, {
                  "value": 2,
                  "label": 'Servicios de transporte automotor de pasajeros para el turismo'
              }, {
                  "value": 2,
                  "label": 'Servicios de buses de larga distancias regulares'
              }, {
                  "value": 2,
                  "label": 'Operación de teleféricos, funiculares, telesillas'
              }, {
                  "value": 2,
                  "label": 'Transporte automotor de pasajeros N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Líneas ciudad – aeropuerto y viceversa'
              }, {
                  "value": 2,
                  "label": 'Operación de funiculares, teleféricos si son parte del sistema de transito urbano o suburbano'
              }, {
                  "value": 3,
                  "label": 'Transporte por tuberías'
              }, {
                  "value": 3,
                  "label": 'Transporte por oleoductos y poliductos'
              }, {
                  "value": 2,
                  "label": 'Transporte de líquidos'
              }, {
                  "value": 2,
                  "label": 'Transporte de agua'
              }, {
                  "value": 3,
                  "label": 'Transporte de gases a través de gaseoductos'
              }, {
                  "value": 3,
                  "label": 'Transporte de otros productos básicos a través de gaseoductos'
              }, {
                  "value": 3,
                  "label": 'Transporte por vía acuática'
              }, {
                  "value": 3,
                  "label": 'Transporte marítimo y cabotaje'
              }, {
                  "value": 3,
                  "label": 'Transporte marítimo y de cabotaje de carga'
              }, {
                  "value": 3,
                  "label": 'Transporte por remolque o tracción de barcazas, plataformas petroleras, etc.'
              }, {
                  "value": 3,
                  "label": 'Actividades de navegación, pilotaje y de amarraderos'
              }, {
                  "value": 2,
                  "label": 'Transporte marítimo de pasajeros'
              }, {
                  "value": 2,
                  "label": 'Operación de excursión, cruceros o embarcaciones turísticas'
              }, {
                  "value": 2,
                  "label": 'Operaciones de ferries, taxis acuáticos, etc.'
              }, {
                  "value": 2,
                  "label": 'Alquiler de embarcaciones de recreo con tripulación para la navegación marítima y las aguas costeras de transporte'
              }, {
                  "value": 3,
                  "label": 'Trasporte de carga por vías de navegación interiores'
              }, {
                  "value": 3,
                  "label": 'Transporte fluvial de carga'
              }, {
                  "value": 1,
                  "label": 'Transporte fluvial de pasajeros'
              }, {
                  "value": 2,
                  "label": 'Alquiler de embarcaciones de recreo con tripulación'
              }, {
                  "value": 3,
                  "label": 'Transporte de carga por vía aérea'
              }, {
                  "value": 3,
                  "label": 'Transporte de mercancías por vía aérea en rutas regulares y en horarios regulares'
              }, {
                  "value": 3,
                  "label": 'Actividades de aviación en general'
              }, {
                  "value": 3,
                  "label": 'Lanzamiento de satélites y vehículos espaciales'
              }, {
                  "value": 3,
                  "label": 'Transporte espacial'
              }, {
                  "value": 3,
                  "label": 'Transporte no regulares de carga por vía aérea'
              }, {
                  "value": 2,
                  "label": 'Transporte de pasajeros por vía aérea'
              }, {
                  "value": 2,
                  "label": 'Transporte de pasajeros por vía aérea'
              }, {
                  "value": 2,
                  "label": 'Vuelos charter de pasajeros'
              }, {
                  "value": 2,
                  "label": 'Vuelos de exhibición y de turismo'
              }, {
                  "value": 3,
                  "label": 'Alquiler de equipos de transporte aéreo con un operador con el fin de transportar pasajeros'
              }, {
                  "value": 3,
                  "label": 'Almacenamiento y actividades complementarias al transporte'
              }, {
                  "value": 3,
                  "label": 'Servicios de manipulación de carga'
              }, {
                  "value": 3,
                  "label": 'Servicios de manipulación de carga (incluye los servicios de carga y descarga de mercancías o equipajes de pasajeros)'
              }, {
                  "value": 2,
                  "label": 'Actividades de estibas'
              }, {
                  "value": 2,
                  "label": 'Carga y descarga de vagones ferroviarios'
              }, {
                  "value": 3,
                  "label": 'Servicios de almacenamiento y depósito'
              }, {
                  "value": 3,
                  "label": 'Servicios de almacenamiento y depósito'
              }, {
                  "value": 3,
                  "label": 'Operación de silos de granos'
              }, {
                  "value": 3,
                  "label": 'Operación de almacenes generales de mercancías'
              }, {
                  "value": 3,
                  "label": 'Operación de almacenes refrigerados'
              }, {
                  "value": 3,
                  "label": 'Operación de tanque de almacenamiento'
              }, {
                  "value": 3,
                  "label": 'Almacenamientos de productos en zonas francas'
              }, {
                  "value": 2,
                  "label": 'Servicios complementarios para el transporte'
              }, {
                  "value": 2,
                  "label": 'Servicios de explotación de infraestructuras para el transporte terrestre, peaje y otros derechos'
              }, {
                  "value": 2,
                  "label": 'Organización de envíos grupales o individuales'
              }, {
                  "value": 2,
                  "label": 'Servicios prestados por parqueos de estacionamiento y garajes'
              }, {
                  "value": 2,
                  "label": 'Servicios complementarios para el transporte terrestre N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Operación de instalaciones de la terminal tales como la estación de tren'
              }, {
                  "value": 2,
                  "label": 'Operación de instalaciones de la terminal, estación de autobuses'
              }, {
                  "value": 2,
                  "label": 'Operación de instalaciones de la terminal, estaciones para la manipulación de mercancías'
              }, {
                  "value": 3,
                  "label": 'Operación de infraestructura ferroviaria'
              }, {
                  "value": 3,
                  "label": 'Servicios de explotación de infraestructura para el transporte por agua'
              }, {
                  "value": 3,
                  "label": 'Servicios de guardería náuticas'
              }, {
                  "value": 3,
                  "label": 'Servicios para la navegación (incluye servicio de practicaje y pilotaje, atraque)'
              }, {
                  "value": 1,
                  "label": 'Actividades se salvamento'
              }, {
                  "value": 1,
                  "label": 'Actividades de faro'
              }, {
                  "value": 1,
                  "label": 'Servicios complementarios para el transporte por agua N. C. P. (incluye explotación de servicios de terminales como puertos y muelles)'
              }, {
                  "value": 1,
                  "label": 'Operaciones de esclusas'
              }, {
                  "value": 2,
                  "label": 'Servicios de hangares, estacionamiento y remolque de aeronaves'
              }, {
                  "value": 2,
                  "label": 'Servicios para la aeronavegación'
              }, {
                  "value": 2,
                  "label": 'Actividades de aeropuertos y de control de tráfico aéreo'
              }, {
                  "value": 2,
                  "label": 'Actividades de servicios en aeropuertos'
              }, {
                  "value": 2,
                  "label": 'Servicios de manejadoras y/o representantes de las aerolíneas'
              }, {
                  "value": 2,
                  "label": 'Servicios complementarios para el transporte aéreo N. C. P. (incluye servicio de prevención y extinción de incendios)'
              }, {
                  "value": 2,
                  "label": 'Servicios de agencias de viaje y otras actividades complementarias de apoyo turístico'
              }, {
                  "value": 2,
                  "label": 'Servicios mayoristas de agencias de viaje'
              }, {
                  "value": 2,
                  "label": 'Servicios minoristas de agencias de viaje'
              }, {
                  "value": 2,
                  "label": 'Servicios complementarios de apoyo turístico'
              }, {
                  "value": 1,
                  "label": 'Organización y montaje de tours con transporte, alojamiento y comida'
              }, {
                  "value": 2,
                  "label": 'Visitas a museos históricos o culturales, teatrales, musicales o eventos deportivos'
              }, {
                  "value": 2,
                  "label": 'Servicios de gestión y logística para el transporte de mercancías'
              }, {
                  "value": 2,
                  "label": 'Actividades de logística, planificación, diseño y apoyo a las operaciones de transporte, almacenaje y distribución'
              }, {
                  "value": 2,
                  "label": 'Expedición y obtención de documentos de transporte y conocimiento de embarque'
              }, {
                  "value": 3,
                  "label": 'Actividades de los agentes de aduanas'
              }, {
                  "value": 3,
                  "label": 'Actividades de expedidores de carga marítima y agentes de carga aérea'
              }, {
                  "value": 3,
                  "label": 'Corretaje de buques y aeronaves espaciales'
              }, {
                  "value": 3,
                  "label": 'Operaciones de embalaje temporal con el único proteger las mercancías durante el transito'
              }, {
                  "value": 3,
                  "label": 'Operaciones de desembalaje'
              }, {
                  "value": 2,
                  "label": 'Prueba y pesaje de productos'
              }, {
                  "value": 2,
                  "label": 'Empresas de empaque'
              }, {
                  "value": 2,
                  "label": 'Actividades de otras agencias'
              }, {
                  "value": 2,
                  "label": 'Correos y servicios de mensajería'
              }, {
                  "value": 2,
                  "label": 'Actividades postales nacionales'
              }, {
                  "value": 1,
                  "label": 'Servicios de correo'
              }, {
                  "value": 1,
                  "label": 'Recolección de cartas y paquetes de correos públicos, de buzones o de las oficinas de correo'
              }, {
                  "value": 1,
                  "label": 'Distribución y entrega de correspondencias y paquetes'
              }, {
                  "value": 1,
                  "label": 'Actividades por correo distintas a las actividades postales nacionales'
              }, {
                  "value": 1,
                  "label": 'Servicios de mensajería (courrier)'
              }, {
                  "value": 1,
                  "label": 'Servicios de entregas a domicilio'
              }, {
                  "value": 1,
                  "label": 'Servicios de telecomunicaciones'
              }, {
                  "value": 1,
                  "label": 'Servicios de transmisión de radio y televisión'
              }, {
                  "value": 1,
                  "label": 'Servicios de comunicación por medio de teléfono, beeper, telégrafo y telex'
              }, {
                  "value": 1,
                  "label": 'Centros de telecomunicaciones'
              }, {
                  "value": 1,
                  "label": 'Suministro de acceso a Internet por el operador de la infraestructura del cableado'
              }, {
                  "value": 1,
                  "label": 'Servicios de telemarketing y/o profesional mercadeo'
              }, {
                  "value": 1,
                  "label": 'Consultaría de marketing'
              }, {
                  "value": 1,
                  "label": 'Emisión de programas de televisión'
              }, {
                  "value": 1,
                  "label": 'Servicios de transmisión N. C. P. de sonido, imágenes, datos u otra información'
              }, {
                  "value": 2,
                  "label": 'ACTIVIDADES FINANCIERAS Y DE SEGURO'
              }, {
                  "value": 2,
                  "label": 'Intermediación monetaria y financiera de la banca central'
              }, {
                  "value": 2,
                  "label": 'Servicios de la banca central 9incluye las actividades del banco central)'
              }, {
                  "value": 3,
                  "label": 'Emisión y administración de dinero'
              }, {
                  "value": 3,
                  "label": 'Toma de depósitos que son utilizados para compensaciones entre instituciones financieras'
              }, {
                  "value": 3,
                  "label": 'Supervisión de las operaciones bancarias'
              }, {
                  "value": 3,
                  "label": 'Tenedoras de las reservas internacionales de un país'
              }, {
                  "value": 3,
                  "label": 'Interpretar el rol de banquero del gobierno'
              }, {
                  "value": 3,
                  "label": 'Intermediación monetaria y financiera de las entidades financieras y bancarias y no bancarias'
              }, {
                  "value": 3,
                  "label": 'Servicios de la banca mayorista'
              }, {
                  "value": 3,
                  "label": 'Uniones y/o corporaciones de crédito'
              }, {
                  "value": 3,
                  "label": 'Giros postales y actividades postales de bancos'
              }, {
                  "value": 3,
                  "label": 'Entidades públicas de intermediación financiera'
              }, {
                  "value": 3,
                  "label": 'Servicios de la banca de inversión'
              }, {
                  "value": 3,
                  "label": 'Servicios de la banca minorista o comerciales'
              }, {
                  "value": 2,
                  "label": 'Bancos de ahorros y crédito'
              }, {
                  "value": 2,
                  "label": 'Bancos múltiples'
              }, {
                  "value": 2,
                  "label": 'Servicios de intermediación financiera realizada por las compañías financieras'
              }, {
                  "value": 2,
                  "label": 'Asociaciones de ahorros y préstamos'
              }, {
                  "value": 2,
                  "label": 'Servicios de intermediación financiera realizada por caja de crédito'
              }, {
                  "value": 2,
                  "label": 'financieros excepto los de la banca central y las entidades financieras'
              }, {
                  "value": 2,
                  "label": 'Actividades de crédito para financiar otras actividades económicas (incluye las empresas de factoring y otras formas de adelanto)'
              }, {
                  "value": 2,
                  "label": 'Financiación del comercio internacional'
              }, {
                  "value": 3,
                  "label": 'Prestación de dinero fuera del sistema financiero'
              }, {
                  "value": 3,
                  "label": 'Otorgamiento de crédito para viviendas por entidades especializadas no depositarias'
              }, {
                  "value": 3,
                  "label": 'Casas de empeño'
              }, {
                  "value": 3,
                  "label": 'Servicios de crédito N. C. P. (incluye el otorgamiento de préstamos por entidades que no reciben depósitos)'
              }, {
                  "value": 3,
                  "label": 'Servicios de agentes de mercado abierto puros (incluye las transacciones extrabursátiles por cuenta propia)'
              }, {
                  "value": 2,
                  "label": 'Corretaje de valores'
              }, {
                  "value": 2,
                  "label": 'Corretaje de contratos de comoditis'
              }, {
                  "value": 2,
                  "label": 'Actividades de oficinas de cambio'
              }, {
                  "value": 2,
                  "label": 'Servicios de entidades de tarjetas de compra y/o crédito'
              }, {
                  "value": 2,
                  "label": 'Participación en empresas'
              }, {
                  "value": 2,
                  "label": 'Actividades de las sociedades de holding'
              }, {
                  "value": 2,
                  "label": 'Fondos de inversión abierto'
              }, {
                  "value": 2,
                  "label": 'Fondos de inversión cerrado'
              }, {
                  "value": 2,
                  "label": 'Fideicomisos'
              }, {
                  "value": 2,
                  "label": 'Fondos de inversiones de portafolio'
              }, {
                  "value": 3,
                  "label": 'Rentista de valores mobiliarios'
              }, {
                  "value": 3,
                  "label": 'Sociedades de inversión'
              }, {
                  "value": 2,
                  "label": 'Servicios de financiación y actividades financieras N. C. P. incluye actividades de inversión en acciones, títulos, fondos comunes)'
              }, {
                  "value": 2,
                  "label": 'Seguros, reaseguros, fondos de pensiones, excepto los planes de seguridad social de afiliación obligatoria'
              }, {
                  "value": 2,
                  "label": 'Servicios de seguro'
              }, {
                  "value": 2,
                  "label": 'Seguros de salud (incluye medicina prepagada)'
              }, {
                  "value": 2,
                  "label": 'Servicios de seguros de vida (incluye los seguros de vida, retiro y sepelio)'
              }, {
                  "value": 2,
                  "label": 'Servicios de seguros a las personas excepto los de salud y de vida (incluye los seguros de accidente)'
              }, {
                  "value": 2,
                  "label": 'Seguros de viaje'
              }, {
                  "value": 2,
                  "label": 'Servicios de seguros patrimoniales'
              }, {
                  "value": 2,
                  "label": 'Seguro para transporte con motor, marina, la aviación'
              }, {
                  "value": 2,
                  "label": 'Seguro de responsabilidad civil y pérdida pecuniaria'
              }, {
                  "value": 2,
                  "label": 'Reaseguros'
              }, {
                  "value": 2,
                  "label": 'Servicios de administración de fondos de pensiones'
              }, {
                  "value": 2,
                  "label": 'Administradoras de fondos de pensiones'
              }, {
                  "value": 2,
                  "label": 'Administración de riesgo de salud'
              }, {
                  "value": 2,
                  "label": 'Otras actividades financieras, excepto seguros y fondos de pensiones'
              }, {
                  "value": 2,
                  "label": 'Servicios de mercados y cajas de valores'
              }, {
                  "value": 2,
                  "label": 'Servicios de mercados a término'
              }, {
                  "value": 2,
                  "label": 'Servicios de bolsas de comercio'
              }, {
                  "value": 2,
                  "label": 'Servicios bursátiles de mediación o por cuenta de terceros (incluye la actividad de agentes y sociedades de bolsas)'
              }, {
                  "value": 2,
                  "label": 'Contrato de intercambio de futuros y comoditis'
              }, {
                  "value": 2,
                  "label": 'Intercambio de valores'
              }, {
                  "value": 2,
                  "label": 'Intercambio de opciones de comoditis o acciones'
              }, {
                  "value": 3,
                  "label": 'Agentes de cambio'
              }, {
                  "value": 2,
                  "label": 'Servicios de sociedades calificadoras de riesgo'
              }, {
                  "value": 2,
                  "label": 'Ajustes de las reclamaciones'
              }, {
                  "value": 2,
                  "label": 'Evaluación de riesgo'
              }, {
                  "value": 2,
                  "label": 'Evaluación de daños'
              }, {
                  "value": 2,
                  "label": 'Promedio y ajuste por pérdida'
              }, {
                  "value": 2,
                  "label": 'Servicios auxiliares a la intermediación financiera N. C. P. excepto a los servicios de seguros y de administración de fondos de jubilaciones'
              }, {
                  "value": 3,
                  "label": 'Actividades de factoring'
              }, {
                  "value": 2,
                  "label": 'Swaps, opciones y otros acuerdos de coberturas'
              }, {
                  "value": 2,
                  "label": 'Actividades de compañías de convenios de liquidación por adelantado'
              }, {
                  "value": 2,
                  "label": 'Servicios auxiliares a los servicios de seguros y administradoras de fondos de jubilaciones y pensiones'
              }, {
                  "value": 2,
                  "label": 'Servicios de productores y asesores de seguros'
              }, {
                  "value": 2,
                  "label": 'Servicios auxiliares a los servicios de seguros N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Servicios auxiliares a la administración de fondos jubilados y pensiones'
              }, {
                  "value": 2,
                  "label": 'Administración de salvamento'
              }, {
                  "value": 2,
                  "label": 'Servicios actuariales'
              }, {
                  "value": 2,
                  "label": 'Corredores de seguro'
              }, {
                  "value": 2,
                  "label": 'ACTIVIDADES INMOBILIARIAS, ALQUILER Y ACTIVIDADES EMPRESARIALES'
              }, {
                  "value": 2,
                  "label": 'Servicios inmobiliarios realizados a cuenta propia, con bienes propios o arrendados'
              }, {
                  "value": 2,
                  "label": 'Servicios de alquiler y explotación de inmuebles para fiestas, convenciones y otros eventos similares'
              }, {
                  "value": 3,
                  "label": 'Condominios'
              }, {
                  "value": 2,
                  "label": 'Tierras'
              }, {
                  "value": 3,
                  "label": 'Servicios inmobiliarios realizados por cuenta propia, con bienes propios o arrendados N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Operadoras de zonas francas'
              }, {
                  "value": 2,
                  "label": 'Operadoras de franquicias'
              }, {
                  "value": 2,
                  "label": 'Actividades inmobiliarias realizadas a cambio de una retribución o por cuenta'
              }, {
                  "value": 3,
                  "label": 'Servicios inmobiliarios realizados a cambio de una retribución o por contrata (incluye compra, venta, alquiler, reate, tasación)'
              }, {
                  "value": 2,
                  "label": 'Administración de bienes raíces por comisión o por contrato'
              }, {
                  "value": 2,
                  "label": 'Servicios de tasación de propiedades inmobiliaria'
              }, {
                  "value": 2,
                  "label": 'Agentes inmobiliarios fideicomisos'
              }, {
                  "value": 3,
                  "label": 'Operaciones inmobiliarias de inversionistas extranjeros'
              }, {
                  "value": 3,
                  "label": 'Alquiler de maquinaria y equipos sin operador'
              }, {
                  "value": 3,
                  "label": 'Alquiler de equipos de transporte'
              }, {
                  "value": 3,
                  "label": 'Alquiler de equipo de transporte para vía terrestre, sin operarios ni tripulación'
              }, {
                  "value": 2,
                  "label": 'Camiones, remolques de utilidad y vehículos de recreo'
              }, {
                  "value": 2,
                  "label": 'Leasing automotor'
              }, {
                  "value": 2,
                  "label": 'Alquiler de equipo de transporte para vía acuática, sin operario ni tripulación'
              }, {
                  "value": 2,
                  "label": 'Alquiler de equipo de transporte para vía aérea, sin operario ni tripulación'
              }, {
                  "value": 2,
                  "label": 'Alquiler de globos de aire caliente'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinaria N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinaria y equipo agropecuario, sin operario'
              }, {
                  "value": 2,
                  "label": 'Alquiler de segadoras'
              }, {
                  "value": 2,
                  "label": 'Alquiler de remolques y semirremolques de carga y descarga automática'
              }, {
                  "value": 3,
                  "label": 'Alquiler de maquinas utilizadas para preparar el suelo, plantar y abonar'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinas para la recolección y trilla'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinas para ordeñar'
              }, {
                  "value": 2,
                  "label": 'Alquiler de aspersores de uso agrícola'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinaria agrícola diversa'
              }, {
                  "value": 3,
                  "label": 'Alquiler de maquinaria y equipo de construcción e ingeniería civil, sin operario (incluye el alquiler de andamios sin montaje)'
              }, {
                  "value": 2,
                  "label": 'Alquiler de camiones grúa'
              }, {
                  "value": 2,
                  "label": 'Alquiler de andamios y plataformas de trabajo, sin montaje y desmontaje'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinaria y equipo de oficina, incluso computadora'
              }, {
                  "value": 2,
                  "label": 'Alquiler de computadoras y equipos periféricos'
              }, {
                  "value": 2,
                  "label": 'Alquiler de copiadoras, máquina de escribir y maquinas de tratamiento de texto'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinaria y equipos contables, cajas registradoras y calculadoras electrónicas'
              }, {
                  "value": 2,
                  "label": 'Alquiler de mobiliario de oficina'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinarias y utensilios para fiestas'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinaria y equipo para la industria manufacturera, sin personal'
              }, {
                  "value": 3,
                  "label": 'Alquiler de maquinaria y equipo minero y petrolero, sin personal'
              }, {
                  "value": 2,
                  "label": 'Alquiler de equipos de comunicación profesional de radio y televisión'
              }, {
                  "value": 2,
                  "label": 'Alquiler de equipos de producción cinematográficas'
              }, {
                  "value": 2,
                  "label": 'Alquiler de equipos de medición y control'
              }, {
                  "value": 2,
                  "label": 'Alquiler de otras maquinarias científicas, comerciales e industriales'
              }, {
                  "value": 2,
                  "label": 'Alquiler de motocicleta, caravanas y campers'
              }, {
                  "value": 3,
                  "label": 'Alquiler de ferrocarriles'
              }, {
                  "value": 3,
                  "label": 'Alquiler de maquinaria y equipo N. C. P. sin personal'
              }, {
                  "value": 1,
                  "label": 'Alquiler de efectos personales y enseres domésticos N. C. P.'
              }, {
                  "value": 1,
                  "label": 'Alquiler de ropa'
              }, {
                  "value": 1,
                  "label": 'Alquiler de instrumentos musicales, estenografías y vestuario'
              }, {
                  "value": 1,
                  "label": 'Alquiler de libros, revistas y periódicos'
              }, {
                  "value": 2,
                  "label": 'Alquiler de maquinaria y equipo utilizado por aficionados o como un hobby por ejemplo herramientas para reparación del hogar'
              }, {
                  "value": 3,
                  "label": 'Alquiler de flores y plantas'
              }, {
                  "value": 2,
                  "label": 'Alquiler de equipos electrónicos de uso domésticos'
              }, {
                  "value": 2,
                  "label": 'Alquiler de efectos personales y enseres domésticos N. C. P. (incluye el alquiler de artículos deportivos y de video)'
              }, {
                  "value": 3,
                  "label": 'Programación informática, consultarías y actividades relacionadas'
              }, {
                  "value": 3,
                  "label": 'Servicios de consultores en equipo de informática'
              }, {
                  "value": 3,
                  "label": 'Servicios de consultores en equipos de informática'
              }, {
                  "value": 3,
                  "label": 'Servicios de consultaría en informática y suministros de programas de informática'
              }, {
                  "value": 3,
                  "label": 'Servicios de consultores en informática y suministro de programas de informática'
              }, {
                  "value": 3,
                  "label": 'Instalaciones de procesamiento de datos y servicios de soporte relacionado'
              }, {
                  "value": 3,
                  "label": 'Planificación y diseño de los sistemas informáticos'
              }, {
                  "value": 3,
                  "label": 'Procesamiento de datos'
              }, {
                  "value": 3,
                  "label": 'Procesamiento de datos'
              }, {
                  "value": 3,
                  "label": 'Generación de informes especializados a partir de los datos suministrados por los clientes'
              }, {
                  "value": 3,
                  "label": 'Servicios relacionados con bases de datos'
              }, {
                  "value": 3,
                  "label": 'Prestación de servicios de entrada de datos'
              }, {
                  "value": 2,
                  "label": 'Mantenimiento y reparación de maquinaria de oficina, contabilidad e informática'
              }, {
                  "value": 2,
                  "label": 'mantenimiento y reparación de maquinaria de oficina, contabilidad e informática'
              }, {
                  "value": 2,
                  "label": 'Reparación de disco magnético, flash drives y otros dispositivos de almacenaje'
              }, {
                  "value": 2,
                  "label": 'Reparación de discos ópticos (CD-RW, CD-ROM, DVD-ROM, DVD-RW)'
              }, {
                  "value": 2,
                  "label": 'Reparación de impresoras, monitores, teclados, ratones, palancas de mando, y accesorios de TrackBall'
              }, {
                  "value": 2,
                  "label": 'Reparación de módems internos y externos de la computadora'
              }, {
                  "value": 2,
                  "label": 'Reparación de servidores de computadora'
              }, {
                  "value": 2,
                  "label": 'Reparación de escáneres, incluyendo los escáneres de código de barras, lectores de tarjetas'
              }, {
                  "value": 2,
                  "label": 'Reparación de terminales como cajeros automáticas y de puntos de venta, que funcionan en forma no mecánica'
              }, {
                  "value": 2,
                  "label": 'Reparación de computadoras hand-held (PDA)'
              }, {
                  "value": 3,
                  "label": 'Actividades de informática N. C .P.'
              }, {
                  "value": 3,
                  "label": 'Actividades de informática N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Recuperación de datos ante desastres computacionales'
              }, {
                  "value": 3,
                  "label": 'Instalación (configuración) de computadoras personales'
              }, {
                  "value": 3,
                  "label": 'Instalación de redes informáticas'
              }, {
                  "value": 3,
                  "label": 'Diseño, desarrollo e instalación de software'
              }, {
                  "value": 3,
                  "label": 'Investigación y desarrollo científico'
              }, {
                  "value": 3,
                  "label": 'Investigación y desarrollo experimental en el campo de la ingeniería y de las ciencias exactas y naturales'
              }, {
                  "value": 3,
                  "label": 'Investigación y desarrollo experimental en el campo de la ingeniería y la tecnología'
              }, {
                  "value": 2,
                  "label": 'Investigación y desarrollo experimental en el campo de las ciencias médicas'
              }, {
                  "value": 2,
                  "label": 'Investigación y desarrollo experimental en el campo de las ciencias agropecuarias'
              }, {
                  "value": 2,
                  "label": 'Investigación de ciencias biológicas'
              }, {
                  "value": 2,
                  "label": 'Institutos meteorológicos'
              }, {
                  "value": 2,
                  "label": 'Investigación y desarrollo experimental en el campo de las ciencias exactas y naturales N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Investigación y desarrollo experimental en el campo de las ciencias sociales y las humanas'
              }, {
                  "value": 2,
                  "label": 'Investigación y desarrollo experimental en el campo de las ciencias sociales'
              }, {
                  "value": 2,
                  "label": 'Investigación y desarrollo experimental en el campo de las ciencias humanas'
              }, {
                  "value": 2,
                  "label": 'Investigación interdisciplinaria predominantemente en ciencias sociales y humanas'
              }, {
                  "value": 2,
                  "label": 'Otras actividades empresariales'
              }, {
                  "value": 3,
                  "label": 'Servicios jurídicos y de contabilidad, teneduría de libro y auditoria; asesoramiento en materia de impuestos'
              }, {
                  "value": 3,
                  "label": 'Actividades jurídicas'
              }, {
                  "value": 3,
                  "label": 'Servicios jurídicos'
              }, {
                  "value": 2,
                  "label": 'Servicios notariales'
              }, {
                  "value": 3,
                  "label": 'procuradores y alguaciles'
              }, {
                  "value": 2,
                  "label": 'Gestión de cobro'
              }, {
                  "value": 3,
                  "label": 'Asesoramiento y representación en casos civiles'
              }, {
                  "value": 3,
                  "label": 'Asesoramiento y representación en casos penales'
              }, {
                  "value": 3,
                  "label": 'Asesoramiento y representación en los conflictos laborales'
              }, {
                  "value": 3,
                  "label": 'Escritura de constituciones, contratos de sociedades colectivas y documentos similares para la formación de sociedades'
              }, {
                  "value": 3,
                  "label": 'Patentes y derechos de autor'
              }, {
                  "value": 3,
                  "label": 'Servicios de contabilidad y teneduría de libros, auditoría y asesoría fiscal'
              }, {
                  "value": 3,
                  "label": 'Preparación o auditoría de las cuentas financieras'
              }, {
                  "value": 3,
                  "label": 'Verificación de las cuentas y la certificación de su exactitud'
              }, {
                  "value": 3,
                  "label": 'Preparación de declaraciones de ingresos para personas y empresas'
              }, {
                  "value": 3,
                  "label": 'Actividades de otras asesorías y representación además de la legal'
              }, {
                  "value": 2,
                  "label": 'Estudio de mercado, realización de encuestas de opinión publica'
              }, {
                  "value": 2,
                  "label": 'Investigación de aceptación y la familiaridad de los productos'
              }, {
                  "value": 2,
                  "label": 'Investigación de los hábitos de compra de los consumidores con el propósito de la promoción y desarrollo de nuevos productos'
              }, {
                  "value": 2,
                  "label": 'Actividades de asesoramiento'
              }, {
                  "value": 2,
                  "label": 'Servicios de asesoramiento, dirección y gestión empresarial realizados por integrantes de los órganos de administración y/o fiscalización en sociedades anónimas'
              }, {
                  "value": 2,
                  "label": 'Servicios de asesoramiento, dirección y gestión empresarial realizados por integrantes de órganos de dirección en sociedades excepto las sociedades anónimas'
              }, {
                  "value": 2,
                  "label": 'Consultaría en seguridad'
              }, {
                  "value": 2,
                  "label": 'Consultaría en agronomía'
              }, {
                  "value": 2,
                  "label": 'Consultoría ambiental'
              }, {
                  "value": 2,
                  "label": 'Actividades de consultores que no sean las de arquitectura, ingeniería y gestión de consultores'
              }, {
                  "value": 3,
                  "label": 'Otras consultarías técnicas'
              }, {
                  "value": 2,
                  "label": 'Servicios de asesoramiento, dirección y gestión empresarial N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Actividades de arquitectura e ingeniería y actividades conexas de asesoramiento técnico'
              }, {
                  "value": 2,
                  "label": 'Arquitectos'
              }, {
                  "value": 3,
                  "label": 'Servicios geológicos y de prospección'
              }, {
                  "value": 3,
                  "label": 'Servicios relacionados con la electrónica y las comunicaciones'
              }, {
                  "value": 3,
                  "label": 'Servicios de ingeniería civil'
              }, {
                  "value": 3,
                  "label": 'Dibujantes'
              }, {
                  "value": 2,
                  "label": 'Técnicos de suelo'
              }, {
                  "value": 2,
                  "label": 'Ingeniero calculista'
              }, {
                  "value": 2,
                  "label": 'Planeamiento de pueblos y ciudades (urbano) y arquitectura de paisajes'
              }, {
                  "value": 2,
                  "label": 'Servicios de arquitectura e ingeniería y servicios conexos de asesoramiento técnico N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Servicios de plomería y afines'
              }, {
                  "value": 2,
                  "label": 'Ensayos y análisis técnico'
              }, {
                  "value": 3,
                  "label": 'Pruebas de composición y de pureza de los minerales'
              }, {
                  "value": 2,
                  "label": 'Pruebas en el ámbito de la higiene de los alimentos, incluidos los veterinarios'
              }, {
                  "value": 2,
                  "label": 'Análisis y control en relación con la producción de alimentos'
              }, {
                  "value": 2,
                  "label": 'Pruebas de las características físicas y el rendimiento de los materiales tales como fuerza, espesor, durabilidad y radioactividad'
              }, {
                  "value": 2,
                  "label": 'Pruebas de calificación y fiabilidad'
              }, {
                  "value": 2,
                  "label": 'Pruebas de rendimiento de maquinaria completa: motores, automóviles y equipos electrónicos'
              }, {
                  "value": 2,
                  "label": 'Pruebas radiográficas de las soldaduras y las articulaciones, análisis de falla'
              }, {
                  "value": 2,
                  "label": 'Análisis y la medición de los indicadores ambientales'
              }, {
                  "value": 2,
                  "label": 'Ingeniero comercial'
              }, {
                  "value": 2,
                  "label": 'Ingeniero mecánico'
              }, {
                  "value": 2,
                  "label": 'Ingeniero electrónico'
              }, {
                  "value": 2,
                  "label": 'Ingeniero agrónomo'
              }, {
                  "value": 2,
                  "label": 'Ingeniería de sistemas y de seguridad'
              }, {
                  "value": 2,
                  "label": 'Ingeniería acústica'
              }, {
                  "value": 2,
                  "label": 'Proyecto de gestión de las actividades relacionadas con la construcción'
              }, {
                  "value": 2,
                  "label": 'Ingeniería química'
              }, {
                  "value": 2,
                  "label": 'Otros servicios de ingeniería'
              }, {
                  "value": 2,
                  "label": 'Servicios de electromecánica'
              }, {
                  "value": 1,
                  "label": 'Servicios de publicidad'
              }, {
                  "value": 3,
                  "label": 'Servicios de publicidad'
              }, {
                  "value": 3,
                  "label": 'Representación de medios y venta de tiempo y espacio para varios medios solicitantes de publicidad'
              }, {
                  "value": 3,
                  "label": 'Publicidad aérea'
              }, {
                  "value": 3,
                  "label": 'Distribución o entrega de material publicitario'
              }, {
                  "value": 3,
                  "label": 'Distribución de espacios publicitarios en las vallas'
              }, {
                  "value": 3,
                  "label": 'Creación de stand y otras estructuras de visualización'
              }, {
                  "value": 3,
                  "label": 'Promoción de los productos'
              }, {
                  "value": 1,
                  "label": 'Marketing de los puntos de venta'
              }, {
                  "value": 1,
                  "label": 'Publicidad directa por correo'
              }, {
                  "value": 3,
                  "label": 'Actividades de diseñadores gráficos'
              }, {
                  "value": 1,
                  "label": 'Diseñadores de prendas de vestir'
              }, {
                  "value": 1,
                  "label": 'Diseñadores de calzados'
              }, {
                  "value": 1,
                  "label": 'Diseñadores de joyería'
              }, {
                  "value": 1,
                  "label": 'Diseñadores de muebles y demás decoraciones de interiores'
              }, {
                  "value": 1,
                  "label": 'Diseñadores de otros bienes de moda así como otros efectos personales o enseres domésticos'
              }, {
                  "value": 1,
                  "label": 'Creación y desarrollo de diseños y especificaciones que optimizan el uso, el valor y la apariencia de los productos'
              }, {
                  "value": 2,
                  "label": 'Construcción, mecanismo, forma, color y acabados superficiales del producto'
              }, {
                  "value": 2,
                  "label": 'Diseño e instalación de letreros y vallas'
              }, {
                  "value": 2,
                  "label": 'Servicios empresariales N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Obtención y dotación de personal'
              }, {
                  "value": 2,
                  "label": 'Periodistas'
              }, {
                  "value": 3,
                  "label": 'Actividades de investigación y seguridad'
              }, {
                  "value": 3,
                  "label": 'Servicios de transporte de caudales y objetos de valor'
              }, {
                  "value": 3,
                  "label": 'Servicios de recibos de artículos valiosos con el personal y equipo para proteger tales propiedades'
              }, {
                  "value": 3,
                  "label": 'Servicios de autos blindados'
              }, {
                  "value": 3,
                  "label": 'Servicios de seguridad N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Servicios de investigación N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Servicios de guardaespaldas'
              }, {
                  "value": 3,
                  "label": 'Servicios de polígrafos'
              }, {
                  "value": 3,
                  "label": 'Servicios de detección de huellas dactilares'
              }, {
                  "value": 3,
                  "label": 'Servicios de guardias de seguridad'
              }, {
                  "value": 3,
                  "label": 'Monitorio de sistemas de seguridad de alarmas electrónicas'
              }, {
                  "value": 3,
                  "label": 'Instalación, reparación, reconstrucción de dispositivos de bloqueo mecánico o electrónico'
              }, {
                  "value": 3,
                  "label": 'Instalación, reparación, reconstrucción de cajas fuertes y bóvedas de seguridad'
              }, {
                  "value": 2,
                  "label": 'Servicios de limpieza, conservación, mantenimiento y pintura de edificios'
              }, {
                  "value": 2,
                  "label": 'Limpieza general de interior, mantenimiento y eliminación de basura'
              }, {
                  "value": 3,
                  "label": 'Vigilancia y seguridad'
              }, {
                  "value": 2,
                  "label": 'Enrutamiento de correo'
              }, {
                  "value": 2,
                  "label": 'Lavandería y servicio relacionado a las actividades de apoyo'
              }, {
                  "value": 2,
                  "label": 'Limpieza de oficinas e instituciones'
              }, {
                  "value": 2,
                  "label": 'Limpieza de casas o apartamentos'
              }, {
                  "value": 2,
                  "label": 'Limpieza de fábricas'
              }, {
                  "value": 2,
                  "label": 'Limpieza de tiendas'
              }, {
                  "value": 2,
                  "label": 'Servicios de fotografía'
              }, {
                  "value": 2,
                  "label": 'Fotografía de publicidad, editoriales, moda, inmobiliarias o turísticas'
              }, {
                  "value": 2,
                  "label": 'Fotografía aérea'
              }, {
                  "value": 2,
                  "label": 'Video grabación de eventos como bodas, reuniones, etc.'
              }, {
                  "value": 1,
                  "label": 'Revelado de rollos de películas'
              }, {
                  "value": 1,
                  "label": 'Impresión y ampliado de los negativos de rollo de cine'
              }, {
                  "value": 3,
                  "label": 'Laboratorios de revelado e impresión de fotos'
              }, {
                  "value": 1,
                  "label": 'Revelados de fotos en una hora'
              }, {
                  "value": 1,
                  "label": 'Montaje de diapositivas Montaje de diapositivas'
              }, {
                  "value": 1,
                  "label": 'Servicios de envases y empaque'
              }, {
                  "value": 2,
                  "label": 'Embasado de líquidos, incluidos las bebidas y los alimentos'
              }, {
                  "value": 3,
                  "label": 'Embalaje de seguridad o preparados farmacéuticos'
              }, {
                  "value": 3,
                  "label": 'Etiquetado, estampado e impreso'
              }, {
                  "value": 1,
                  "label": 'Embalaje de paquetes y regalos'
              }, {
                  "value": 3,
                  "label": 'Servicios de impresión heliográfica, fotocopia y otras formas de reproducciones'
              }, {
                  "value": 2,
                  "label": 'Servicios empresariales N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Servicios relacionados con la prevención de incendios'
              }, {
                  "value": 2,
                  "label": 'Administración y operación de brigadas contra incendio auxiliares y regulares'
              }, {
                  "value": 2,
                  "label": 'ADMINISTRACIÓN PÚBLICA Y DEFENSA: PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA'
              }, {
                  "value": 2,
                  "label": 'Administración del estado y aplicación de la política económica y social de la comunidad'
              }, {
                  "value": 2,
                  "label": 'Servicios de la administración publica'
              }, {
                  "value": 2,
                  "label": 'Servicios generales de la administración pública (incluye el desempeño de funciones ejecutivas y legislativas de administración por parte de las entidades públicas)'
              }, {
                  "value": 1,
                  "label": 'Servicios para la regulación de las actividades sanitarias, educación y servicios sociales'
              }, {
                  "value": 2,
                  "label": 'Servicios para la regulación de la actividad económica (incluye la administración pública y la regulación de varios sectores económicos)'
              }, {
                  "value": 2,
                  "label": 'Servicios auxiliares para los servicios generales de la administración pública N. C. P. (incluye las actividades de servicios generales y de personal)'
              }, {
                  "value": 2,
                  "label": 'Prestación publica de servicios a la comunidad en general'
              }, {
                  "value": 3,
                  "label": 'Servicios de asuntos exteriores'
              }, {
                  "value": 3,
                  "label": 'Oficinas de organismos internacionales'
              }, {
                  "value": 3,
                  "label": 'Servicios de defensa'
              }, {
                  "value": 3,
                  "label": 'Fuerzas de combate de ejército, marina y fuerza aérea'
              }, {
                  "value": 3,
                  "label": 'Ingenieros, transporte, comunicaciones, inteligencia, personal y otras fuerzas no de combate y comandos'
              }, {
                  "value": 3,
                  "label": 'Reservas y fuerzas auxiliares de defensa'
              }, {
                  "value": 3,
                  "label": 'Logística militar (suministro de equipos y estructuras)'
              }, {
                  "value": 3,
                  "label": 'Actividades de salud para el personal militar en el campo'
              }, {
                  "value": 3,
                  "label": 'Servicios de justicia'
              }, {
                  "value": 3,
                  "label": 'Administración y operación de cortes de leyes civiles y criminales'
              }, {
                  "value": 3,
                  "label": 'Administración y operación de tribunales militares'
              }, {
                  "value": 3,
                  "label": 'Administración y operación del sistema judicial, incluso representación legal y asesoría en representación del gobierno'
              }, {
                  "value": 3,
                  "label": 'Prestación de los fallos y de la interpretación de la ley'
              }, {
                  "value": 3,
                  "label": 'Arbitraje de acciones civiles'
              }, {
                  "value": 3,
                  "label": 'Servicios para el orden público y la seguridad'
              }, {
                  "value": 3,
                  "label": 'Administración y operación de las fuerzas policiales y auxiliares apoyadas por autoridades públicas y de puerto, fronteras y guardacostas'
              }, {
                  "value": 3,
                  "label": 'Administración y operación de las otras fuerzas policiales especiales incluso regulación del tráfico, inscripción de extranjeros y mantenimiento de registro de arresto'
              }, {
                  "value": 3,
                  "label": 'Servicios de protección civil'
              }, {
                  "value": 3,
                  "label": 'Apoyo para la elaboración de planes de contingencia y la realización de ejercicios con la participación de instituciones y civiles'
              }, {
                  "value": 3,
                  "label": 'Administración de políticas de investigación y desarrollo relacionadas con la defensa y la administración de fondos pertinentes'
              }, {
                  "value": 3,
                  "label": 'Servicios de la seguridad social obligatoria'
              }, {
                  "value": 3,
                  "label": 'Servicios de la seguridad social obligatoria'
              }, {
                  "value": 3,
                  "label": 'Financiación por enfermedad, accidentes de trabajo y seguros de desempleo'
              }, {
                  "value": 2,
                  "label": 'Pensiones de jubilación'
              }, {
                  "value": 2,
                  "label": 'Programas que cubren la pérdida de ingresos debido a la maternidad, incapacidad temporal y viudez'
              }, {
                  "value": 1,
                  "label": 'ENSEÑANZA'
              }, {
                  "value": 1,
                  "label": 'Enseñanza primaria'
              }, {
                  "value": 1,
                  "label": 'Enseñanza inicial y primaria'
              }, {
                  "value": 1,
                  "label": 'Educación primaria'
              }, {
                  "value": 1,
                  "label": 'Educación especial para estudiantes discapacitados en este nivel'
              }, {
                  "value": 1,
                  "label": 'Provisión de programas de alfabetización para adultos'
              }, {
                  "value": 1,
                  "label": 'Enseñanza secundaria'
              }, {
                  "value": 1,
                  "label": 'Enseñanza secundaria de formación general'
              }, {
                  "value": 1,
                  "label": 'Educación escolar general en la segunda etapa del nivel secundario'
              }, {
                  "value": 1,
                  "label": 'Educación especial para estudiantes discapacitados en el nivel secundario'
              }, {
                  "value": 1,
                  "label": 'Enseñanza secundaria de formación técnica o profesional'
              }, {
                  "value": 1,
                  "label": 'Instrucción para guías turísticos'
              }, {
                  "value": 1,
                  "label": 'Instrucción para chef, hoteleros y dueños de restaurantes'
              }, {
                  "value": 1,
                  "label": 'Educación especial para estudiantes discapacitados en formación técnica o profesional'
              }, {
                  "value": 1,
                  "label": 'Escuelas de cosmetología y barbería'
              }, {
                  "value": 1,
                  "label": 'Entrenamiento para preparar computadoras'
              }, {
                  "value": 1,
                  "label": 'Escuelas de conducir para chóferes profesionales'
              }, {
                  "value": 1,
                  "label": 'Enseñanza superior y formación de posgrado'
              }, {
                  "value": 1,
                  "label": 'Servicios sociales con alojamiento'
              }, {
                  "value": 1,
                  "label": 'Enseñanza terciaria (escuelas o institutos técnicos, profesionales o comerciales)'
              }, {
                  "value": 1,
                  "label": 'Educación post secundaria no terciaria'
              }, {
                  "value": 1,
                  "label": 'Segunda etapa de educación terciaria'
              }, {
                  "value": 1,
                  "label": 'Actividades de escuelas de arte que entregan educación superior'
              }, {
                  "value": 1,
                  "label": 'Enseñanza universitaria excepto formación de postgrado'
              }, {
                  "value": 1,
                  "label": 'Formación de postgrado'
              }, {
                  "value": 1,
                  "label": 'Enseñanza para adultos y servicios de enseñanza N. C. P.'
              }, {
                  "value": 1,
                  "label": 'Escuelas especializadas en minusválidos'
              }, {
                  "value": 1,
                  "label": 'Escuelas de conducir'
              }, {
                  "value": 1,
                  "label": 'Escuela de vuelo y entrenamiento de salvavidas'
              }, {
                  "value": 1,
                  "label": 'Entrenamiento de supervivencia'
              }, {
                  "value": 1,
                  "label": 'Entrenamiento para oratoria'
              }, {
                  "value": 1,
                  "label": 'Entrenamiento computacional'
              }, {
                  "value": 1,
                  "label": 'Enseñanza para adultos y servicios de enseñanza N. C. P. (incluye instrucción impartida mediante programas de radio, televisión)'
              }, {
                  "value": 1,
                  "label": 'Educación que no es definida por niveles'
              }, {
                  "value": 1,
                  "label": 'Servicios de tutoría académica'
              }, {
                  "value": 1,
                  "label": 'Preparación para procesos de admisión'
              }, {
                  "value": 1,
                  "label": 'Centros de aprendizajes que ofrecen cursos de recuperación'
              }, {
                  "value": 1,
                  "label": 'Cursos de repaso para exámenes profesionales'
              }, {
                  "value": 1,
                  "label": 'Instrucción en habilidades para conversar e idiomáticas, cursos de lectura  rápida, instrucción religiosa'
              }, {
                  "value": 2,
                  "label": 'SERVICIOS SOCIALES Y RELACIONADOS CON LA SALUD HUMANA'
              }, {
                  "value": 2,
                  "label": 'Servicios relacionados con la salud humana'
              }, {
                  "value": 2,
                  "label": 'Servicios de internación'
              }, {
                  "value": 2,
                  "label": 'Servicios de hospital de día (incluye las actividades de tratamiento que no necesitan hospitalización a tiempo completo)'
              }, {
                  "value": 2,
                  "label": 'Servicios hospitalarios N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Servicio de atención ambulatoria (incluye las actividades de consultorios médicos de establecimiento sin internación)'
              }, {
                  "value": 2,
                  "label": 'Servicios de personal médico y paramédico'
              }, {
                  "value": 2,
                  "label": 'Servicio de atención domiciliaria (incluye las actividades llevadas a cabo en establecimiento que ofrecen atención por módulos a domicilio)'
              }, {
                  "value": 2,
                  "label": 'Servicios odontológicos'
              }, {
                  "value": 2,
                  "label": 'Actividades ortodoncistas'
              }, {
                  "value": 2,
                  "label": 'Servicios de diagnósticos (incluye las actividades de laboratorios de análisis clínicos y patológicos)'
              }, {
                  "value": 2,
                  "label": 'Servicios de tratamiento (incluye las actividades de centros de cobaltoterapia, de radiología convencional, de acelerador lineal)'
              }, {
                  "value": 2,
                  "label": 'Servicios de centros de planificación familiar que provee tratamiento medico'
              }, {
                  "value": 2,
                  "label": 'Servicios de emergencias y traslados'
              }, {
                  "value": 2,
                  "label": 'Pedicuros (podólogos)'
              }, {
                  "value": 2,
                  "label": 'Servicios relacionados con la salud humana N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Servicios veterinarios'
              }, {
                  "value": 2,
                  "label": 'Atención de la salud animal y las actividades de control para los animales de granja'
              }, {
                  "value": 2,
                  "label": 'Atención de la salud animal y las actividades de control para los animales domésticos'
              }, {
                  "value": 2,
                  "label": 'Actividades de asistentes de veterinarios u otro personal auxiliar'
              }, {
                  "value": 2,
                  "label": 'Actividades de diagnósticos clínicos patológicos y otros diagnósticos referentes a animales'
              }, {
                  "value": 2,
                  "label": 'Actividades de ambulancias para animales'
              }, {
                  "value": 2,
                  "label": 'Hogares de ancianos con cuidado de enfermería'
              }, {
                  "value": 2,
                  "label": 'Casas de convalecencia'
              }, {
                  "value": 2,
                  "label": 'Casas de reposo con atención de enfermería'
              }, {
                  "value": 2,
                  "label": 'Instalaciones de cuidado de enfermería'
              }, {
                  "value": 2,
                  "label": 'Servicios de atención a personas minusválidas con alojamiento'
              }, {
                  "value": 2,
                  "label": 'Hogares para ciegos'
              }, {
                  "value": 2,
                  "label": 'Albergue para enfermos mentales'
              }, {
                  "value": 2,
                  "label": 'Instalaciones para el tratamiento de alcoholismo y adicción a las drogas'
              }, {
                  "value": 2,
                  "label": 'Viviendas residenciales para el perturbado emocionalmente'
              }, {
                  "value": 2,
                  "label": 'Actividades de instalaciones para el retardado mental'
              }, {
                  "value": 2,
                  "label": 'Actividades de casas para personas con problemas mentales que no requieren internación'
              }, {
                  "value": 2,
                  "label": 'Reformatorios'
              }, {
                  "value": 2,
                  "label": 'Administración de prisiones y provisión de servicios correccionales'
              }, {
                  "value": 2,
                  "label": 'Servicios de rehabilitación, realizada por unidades del gobierno o por unidades privadas a cambio de una retribución o por contrato'
              }, {
                  "value": 2,
                  "label": 'Servicios de atención a mujeres con alojamiento'
              }, {
                  "value": 2,
                  "label": 'Orfanatos'
              }, {
                  "value": 2,
                  "label": 'Hogares y albergues infantiles'
              }, {
                  "value": 2,
                  "label": 'Refugios temporales para indigentes'
              }, {
                  "value": 2,
                  "label": 'Actividades de centros de grupos de rehabilitación para personas con problemas sociales o personales'
              }, {
                  "value": 2,
                  "label": 'Centros de rehabilitación para delincuentes y transgresores'
              }, {
                  "value": 2,
                  "label": 'Campamentos disciplinarios'
              }, {
                  "value": 2,
                  "label": 'Servicios sociales con alojamiento N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Otras actividades de servicios comunitarios, sociales y personales'
              }, {
                  "value": 2,
                  "label": 'Eliminación de desperdicios y aguas residuales, saneamiento y servicios similares'
              }, {
                  "value": 2,
                  "label": 'Recolección, reducción y eliminación de desperdicios'
              }, {
                  "value": 2,
                  "label": 'Servicios de depuración de aguas residuales, alcantarillado y cloacas'
              }, {
                  "value": 2,
                  "label": 'Administración de los programas de protección del medio ambiente'
              }, {
                  "value": 2,
                  "label": 'Servicios de saneamiento público N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Servicios de organizaciones empresariales, profesionales y de empleados'
              }, {
                  "value": 2,
                  "label": 'Servicios de federaciones, asociaciones, cámaras, gremios y organizaciones similares'
              }, {
                  "value": 2,
                  "label": 'Actividades de organizaciones que buscan el desarrollo y prosperidad de empresas'
              }, {
                  "value": 2,
                  "label": 'Actividades de federaciones de tales organizaciones'
              }, {
                  "value": 2,
                  "label": 'Difusión de la información, representación ante agencias estatales'
              }, {
                  "value": 2,
                  "label": 'Servicios de asociaciones de especialistas en disciplinas científicas, prácticas profesionales y esferas técnicas'
              }, {
                  "value": 2,
                  "label": 'Asociaciones medicas'
              }, {
                  "value": 3,
                  "label": 'Asociaciones jurídicas'
              }, {
                  "value": 3,
                  "label": 'Asociaciones de contadores'
              }, {
                  "value": 3,
                  "label": 'Asociaciones de ingenieros y arquitectos'
              }, {
                  "value": 3,
                  "label": 'Asociaciones de escritores, pintores, artistas, intérpretes, periodistas, etc.'
              }, {
                  "value": 3,
                  "label": 'Servicios de sindicatos'
              }, {
                  "value": 3,
                  "label": 'Servicios de sindicatos'
              }, {
                  "value": 3,
                  "label": 'Actividades de asociaciones cuyos miembros son empleados interesados principalmente en la representación de sus opiniones'
              }, {
                  "value": 3,
                  "label": 'Servicios de asociaciones N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Servicios de organizaciones religiosas'
              }, {
                  "value": 3,
                  "label": 'Actividades de organizaciones de servicio de equipamiento de monasterio y conventos'
              }, {
                  "value": 3,
                  "label": 'Actividades de retiro religioso'
              }, {
                  "value": 3,
                  "label": 'Actividades de servicios religioso fúnebre'
              }, {
                  "value": 3,
                  "label": 'Actividades de organizaciones políticas'
              }, {
                  "value": 3,
                  "label": 'Servicios de asociaciones N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Cooperativas'
              }, {
                  "value": 3,
                  "label": 'Iniciativas ciudadanas y movimientos de protesta'
              }, {
                  "value": 3,
                  "label": 'Movimientos ecologistas y de medio ambiente'
              }, {
                  "value": 2,
                  "label": 'Organizaciones de apoyo a la comunidad y centros de enseñanzas N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Organizaciones para la protección y mejoramiento de los grupos especiales'
              }, {
                  "value": 2,
                  "label": 'Asociaciones para fines patrióticos, incluyendo veteranos de guerra'
              }, {
                  "value": 2,
                  "label": 'Asociaciones de consumidores'
              }, {
                  "value": 2,
                  "label": 'Asociaciones para facilitar el contacto con otras personas con intereses similares como clubes de rotarios, logias, etc.'
              }, {
                  "value": 2,
                  "label": 'Actividades de cinematografía, radio y televisión y servicios de espectáculos artísticos y de diversión N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Producción de filmes y videocintas'
              }, {
                  "value": 2,
                  "label": 'Producción de videos'
              }, {
                  "value": 2,
                  "label": 'Producción de programas de televisión o comerciales de televisión'
              }, {
                  "value": 2,
                  "label": 'Edición, titilación, subtitulación y colocación de los créditos'
              }, {
                  "value": 3,
                  "label": 'Colocación de subtítulos ocultos'
              }, {
                  "value": 2,
                  "label": 'Equipos de producción grafica, animación y efectos especiales'
              }, {
                  "value": 2,
                  "label": 'Transferencia de películas/cintas'
              }, {
                  "value": 2,
                  "label": 'Desarrollo y la transformación de películas de cine'
              }, {
                  "value": 2,
                  "label": 'Reproducción de películas de cine para la distribución teatral'
              }, {
                  "value": 2,
                  "label": 'Actividades de bibliotecas de archivos fotográficos'
              }, {
                  "value": 2,
                  "label": 'Distribución de filmes y videocintas'
              }, {
                  "value": 2,
                  "label": 'Distribución de cintas de video, DVD'
              }, {
                  "value": 2,
                  "label": 'Distribución de producciones similares a los teatros de cine, televisión y redes de estaciones'
              }, {
                  "value": 2,
                  "label": 'Adquisición de los derechos de distribución de películas, cintas de video y DVD'
              }, {
                  "value": 2,
                  "label": 'Exhibición de filmes y videotecas'
              }, {
                  "value": 3,
                  "label": 'Actividades de cine clubes'
              }, {
                  "value": 2,
                  "label": 'Actividades de radio y televisión'
              }, {
                  "value": 2,
                  "label": 'Servicios de radio (no incluye la transmisión)'
              }, {
                  "value": 2,
                  "label": 'Producción y distribución por televisión (no incluye la transmisión)'
              }, {
                  "value": 2,
                  "label": 'Transmisión de programaciones radiales'
              }, {
                  "value": 2,
                  "label": 'Actividades de radiodifusión a través del Internet'
              }, {
                  "value": 3,
                  "label": 'Radiodifusión de datos integrada'
              }, {
                  "value": 3,
                  "label": 'Actividades teatrales y musicales y otras actividades artísticas'
              }, {
                  "value": 3,
                  "label": 'Producción de espectáculos teatrales y musicales'
              }, {
                  "value": 3,
                  "label": 'Composición y representación de obras teatrales, musicales y artísticas'
              }, {
                  "value": 2,
                  "label": 'Compositores'
              }, {
                  "value": 2,
                  "label": 'Autores'
              }, {
                  "value": 2,
                  "label": 'Músicos'
              }, {
                  "value": 2,
                  "label": 'Conferencistas'
              }, {
                  "value": 2,
                  "label": 'Pintores'
              }, {
                  "value": 3,
                  "label": 'Artistas plásticos'
              }, {
                  "value": 2,
                  "label": 'Actores'
              }, {
                  "value": 3,
                  "label": 'Actividades de grupo, circos o compañías, orquestas o bandas'
              }, {
                  "value": 3,
                  "label": 'Actividades de artistas individuales'
              }, {
                  "value": 3,
                  "label": 'Servicios conexos a la producción de espectáculos teatrales y musicales (incluyendo diseño y manejo de escenografía, montaje de iluminación)'
              }, {
                  "value": 2,
                  "label": 'Estudios de grabación o producción musical'
              }, {
                  "value": 2,
                  "label": 'Otras actividades de entretenimiento N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Servicios de salines de baile, discotecas y similares'
              }, {
                  "value": 3,
                  "label": 'Otras actividades recreativas no clasificadas en otra parte'
              }, {
                  "value": 2,
                  "label": 'Payasos'
              }, {
                  "value": 3,
                  "label": 'Servicios de organización de eventos'
              }, {
                  "value": 3,
                  "label": 'Promotores de espectáculos'
              }, {
                  "value": 1,
                  "label": 'Renta y venta de videos'
              }, {
                  "value": 3,
                  "label": 'Servicios de espectáculos artísticos y de diversión N. C. P. (incluye parques de diversión, centros similares)'
              }, {
                  "value": 1,
                  "label": 'Servicios de agencias de noticias'
              }, {
                  "value": 1,
                  "label": 'Actividades de agencias de noticias y de consorcios que proveen noticias, imágenes y reportajes en los medios de comunicación'
              }, {
                  "value": 1,
                  "label": 'Servicios de información por teléfono'
              }, {
                  "value": 3,
                  "label": 'Servicios de búsqueda de información a cambio de una retribución o por contrato'
              }, {
                  "value": 1,
                  "label": 'Servicios de recortes de noticias y prensa'
              }, {
                  "value": 1,
                  "label": 'Servicios de bibliotecas, archivos y museos y servicios culturales N. C. P.'
              }, {
                  "value": 1,
                  "label": 'Servicios de bibliotecas y archivos'
              }, {
                  "value": 1,
                  "label": 'Organización de colección, sean estas especializadas o no'
              }, {
                  "value": 1,
                  "label": 'Colecciones de catálogos'
              }, {
                  "value": 1,
                  "label": 'Prestamos y almacenaje de libros, mapas, periódicos, revistas, películas, expedientes, cintas y obras de arte'
              }, {
                  "value": 1,
                  "label": 'Actividades de recuperación conforme a la información solicitada'
              }, {
                  "value": 3,
                  "label": 'Servicios de museos y preservación de lugares de edificios históricos'
              }, {
                  "value": 3,
                  "label": 'Museos de arte, Museos de joyas, muebles, trajes, cerámica y platería'
              }, {
                  "value": 3,
                  "label": 'Museo de historia natural, museos tecnológicos, museos históricos incluyendo los museos militares'
              }, {
                  "value": 3,
                  "label": 'Otros museos especializados'
              }, {
                  "value": 3,
                  "label": 'Museos al aire libre'
              }, {
                  "value": 2,
                  "label": 'Servicios de jardines botánicos, zoológicos y de parques nacionales'
              }, {
                  "value": 2,
                  "label": 'Operación de reservas naturales, incluida la preservación de la fauna silvestre, etc.'
              }, {
                  "value": 3,
                  "label": 'Ferias y exposiciones'
              }, {
                  "value": 2,
                  "label": 'Operación de una variedad de atracciones mecánicas'
              }, {
                  "value": 2,
                  "label": 'Operación de una variedad de juegos'
              }, {
                  "value": 2,
                  "label": 'Alquiler de instalaciones como baños, taquillas, sillas, etc.'
              }, {
                  "value": 2,
                  "label": 'Servicios para la práctica deportiva y de entretenimiento N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Servicio de organización, dirección y gestión de prácticas deportivas y explotación de las instalaciones'
              }, {
                  "value": 2,
                  "label": 'Gimnasios'
              }, {
                  "value": 2,
                  "label": 'Promoción y producción de espectáculos deportivos'
              }, {
                  "value": 2,
                  "label": 'Actividades de ligas deportivas y la regulación de los órganos'
              }, {
                  "value": 3,
                  "label": 'Actividades de establos, perreras y garajes para carrera'
              }, {
                  "value": 3,
                  "label": 'Operación de pesca deportiva y de caza de preservación'
              }, {
                  "value": 2,
                  "label": 'Actividades de guía de montaña'
              }, {
                  "value": 2,
                  "label": 'Actividades de apoyo para el deporte o actividades recreativas'
              }, {
                  "value": 2,
                  "label": 'Servicios prestados por profesionales y técnicos, para la realización de prácticas deportivas (incluye las realizadas por deportistas)'
              }, {
                  "value": 2,
                  "label": 'Servicios náuticos (escuelas de buceo y alquiler de equipos náuticos)'
              }, {
                  "value": 2,
                  "label": 'Otras actividades de esparcimiento'
              }, {
                  "value": 3,
                  "label": 'Bancas de apuestas'
              }, {
                  "value": 3,
                  "label": 'Bancas deportivas'
              }, {
                  "value": 3,
                  "label": 'Casinos de apuesta'
              }, {
                  "value": 3,
                  "label": 'Lotería y otros juegos de azar'
              }, {
                  "value": 3,
                  "label": 'Servicios de salones de juegos (incluye salones de billar, pool, Bowling)'
              }, {
                  "value": 3,
                  "label": 'Operación (explotación) de juegos que funcionan con monedas'
              }, {
                  "value": 3,
                  "label": 'Operación de portales de Internet de apuestas'
              }, {
                  "value": 2,
                  "label": 'Servicios N. C. P.'
              }, {
                  "value": 2,
                  "label": 'Lavado y limpieza de artículos de tela, cuero y/o de piel, incluso la limpieza en seco'
              }, {
                  "value": 2,
                  "label": 'Recolección y distribución de ropas por lavanderías'
              }, {
                  "value": 2,
                  "label": 'Lavado de alfombras y tapices'
              }, {
                  "value": 2,
                  "label": 'Disposición de lino, uniformes de trabajo y prendas conexas por las lavanderías'
              }, {
                  "value": 1,
                  "label": 'Servicios de suministros de pañales'
              }, {
                  "value": 2,
                  "label": 'Peluquería y otros tratamientos de belleza'
              }, {
                  "value": 2,
                  "label": 'Servicios de peluquería'
              }, {
                  "value": 2,
                  "label": 'Servicios de tratamiento de belleza, excepto los de peluquería'
              }, {
                  "value": 2,
                  "label": 'Afeitado de barba y el corte de pelo'
              }, {
                  "value": 3,
                  "label": 'Pompas fúnebres y servicios conexos'
              }, {
                  "value": 2,
                  "label": 'Enterramiento o la prestación de servicios de cremación'
              }, {
                  "value": 3,
                  "label": 'Alquiler de espacios equipados como salas fúnebres'
              }, {
                  "value": 3,
                  "label": 'Alquiler o venta de tumbas'
              }, {
                  "value": 2,
                  "label": 'Mantenimiento de tumbas y mausoleos'
              }, {
                  "value": 2,
                  "label": 'Otras actividades de servicios N. C. P.'
              }, {
                  "value": 3,
                  "label": 'Servicios para el mantenimiento físico-corporal (incluye baños turcos, saunas, solarios, centros de masajes y adelgazamiento, etc.)'
              }, {
                  "value": 3,
                  "label": 'Actividades de astrología y espiritismo'
              }, {
                  "value": 3,
                  "label": 'Servicios de acompañantes, servicios de citas'
              }, {
                  "value": 3,
                  "label": 'Servicios de agencias matrimoniales'
              }, {
                  "value": 1,
                  "label": 'Servicios de cuidado y entrenamiento de animales domésticos'
              }, {
                  "value": 2,
                  "label": 'Organizaciones genealógicas'
              }, {
                  "value": 1,
                  "label": 'Limpiabotas, portadores de maleta y personas encargadas de estacionar vehículos'
              }, {
                  "value": 1,
                  "label": 'Cabinas de foto'
              }, {
                  "value": 2,
                  "label": 'Maquinas para el control de la presión arterial que funciona con moneda'
              }, {
                  "value": 1,
                  "label": 'Servicio de taquimecanografía'
              }, {
                  "value": 1,
                  "label": 'Subtitulación en tiempo real de televisión, de reuniones y conferencias'
              }, {
                  "value": 1,
                  "label": 'Servicios de direccionamiento de código de barras'
              }, {
                  "value": 1,
                  "label": 'Servicios de impresión de códigos de barras'
              }, {
                  "value": 3,
                  "label": 'Servicios de organización de colectas de fondos a cambio de una retribución o contrato'
              }, {
                  "value": 2,
                  "label": 'Servicios de preselección de correos'
              }, {
                  "value": 1,
                  "label": 'Servicios de recolección de monedas en los parquímetros'
              }, {
                  "value": 3,
                  "label": 'Actividades de subastadores independientes'
              }, {
                  "value": 1,
                  "label": 'Administración de programas de fidelización'
              }, {
                  "value": 2,
                  "label": 'Traductores'
              }, {
                  "value": 3,
                  "label": 'Servicios personales en general'
              }, {
                  "value": 1,
                  "label": 'Empleados y obreros'
              }, {
                  "value": 1,
                  "label": 'Otros servicios (estudiantes, amas de casa, pensionados)'
              }, {
                  "value": 3,
                  "label": 'Hipódromo'
              }, {
                  "value": 2,
                  "label": 'Estadios de futbol, hockey, cricket, baseball'
              }, {
                  "value": 2,
                  "label": 'Piscinas y centros de fitness'
              }, {
                  "value": 2,
                  "label": 'Estadios de pista y campo'
              }, {
                  "value": 2,
                  "label": 'Arenas y estadios para deportes de invierno'
              }, {
                  "value": 2,
                  "label": 'Autodromos'
              }, {
                  "value": 3,
                  "label": 'Arenas de boxeo'
              }, {
                  "value": 1,
                  "label": 'Campos de golf'
              }, {
                  "value": 1,
                  "label": 'Canchas de Bowling'
              }, {
                  "value": 1,
                  "label": 'ACTIVIDADES DE LOS HOGARES EN CALIDAD DE EMPLEADORES, ACTIVIDADES INDIFERENCIADAS DE PRODUCCIÓN DE BIENES Y SERVICIOS DE LOS HOGARES PARA USO PROPIO'
              }, {
                  "value": 1,
                  "label": 'Servicios de los hogares privados que contratan servicios domésticos'
              }, {
                  "value": 1,
                  "label": 'Servicios de los hogares privados que contratan servicios domésticos'
              }, {
                  "value": 1,
                  "label": 'Actividades de domesticas, cocineros, camareros, vallets, mayordomos, jardineros y guardianes'
              }, {
                  "value": 1,
                  "label": 'Actividades de chóferes, cuidadores, institutrices, niñeras y tutores'
              }, {
                  "value": 1,
                  "label": 'Empleados (asalariados)'
              }, {
                  "value": 1,
                  "label": 'Obreros'
              }, {
                  "value": 3,
                  "label": 'ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES'
              }, {
                  "value": 3,
                  "label": 'Actividades de organizaciones y órganos extraterritoriales'
              }, {
                  "value": 3,
                  "label": 'Servicios de organizaciones y órganos extraterritoriales'
              }, {
                  "value": 2,
                  "label": 'Impuesto sucesoral'
              }, {
                  "value": 3,
                  "label": 'Representantes de sucesiones y/o donaciones'
              }, {
                  "value": 3,
                  "label": 'Otros no clasificados'
              }, {
                  "value": 1,
                  "label": 'Operaciones de vehículos de motor'
              }, {
                  "value": 3,
                  "label": 'Diplomáticos y/o cónsules'
              }, {
                  "value": 3,
                  "label": 'Naciones Unidas y sus organismos especializados y órganos regionales'
              }, {
                  "value": 3,
                  "label": 'Fondo monetario internacional'
              }, {
                  "value": 3,
                  "label": 'Banco mundial'
              }, {
                  "value": 3,
                  "label": 'Organizaciones para la cooperación y el desarrollo económico'
              }, {
                  "value": 3,
                  "label": 'Organización de países exportadores de petróleo'
              }, {
                  "value": 2,
                  "label": 'Comunidad europea y la asociación de libre comercio europea'
              }
        ],
        "ocupacion":[
       {
           "value": 3,
           "label": 'ABOGADO'
       }, {
           "value": 3,
           "label": 'ADMINISTRADOR DE EMPRESAS'
       }, {
           "value": 3,
           "label": 'AGENTE ADUANAL'
       }, {
           "value": 2,
           "label": 'AGRICULTOR/A'
       }, {
           "value": 2,
           "label": 'AGRIMENSOR'
       }, {
           "value": 1,
           "label": 'ALBAÑIL'
       }, {
           "value": 3,
           "label": 'ALGUACIL'
       }, {
           "value": 1,
           "label": 'AMA DE CASA'
       }, {
           "value": 1,
           "label": 'ANALISTA'
       }, {
           "value": 1,
           "label": 'ARCHIVISTA'
       }, {
           "value": 2,
           "label": 'ARQUITECTO'
       }, {
           "value": 3,
           "label": 'ASESOR/A DE IMPUESTO SOBRE LA RENTA'
       }, {
           "value": 1,
           "label": 'ASISTENTE'
       }, {
           "value": 1,
           "label": 'AUXILIAR'
       }, {
           "value": 2,
           "label": 'AZAFATA'
       }, {
           "value": 1,
           "label": 'BIOANALISTA'
       }, {
           "value": 2,
           "label": 'CAJERO/A'
       }, {
           "value": 3,
           "label": 'CANTANTE'
       }, {
           "value": 1,
           "label": 'CARNICERO'
       }, {
           "value": 1,
           "label": 'CARPINTERO'
       }, {
           "value": 1,
           "label": 'CHEF'
       }, {
           "value": 1,
           "label": 'CHIRIPERO'
       }, {
           "value": 1,
           "label": 'CHOFER'
       }, {
           "value": 2,
           "label": 'COBRANZAS'
       }, {
           "value": 2,
           "label": 'COMERCIANTE'
       }, {
           "value": 3,
           "label": 'CONSULTOR/A'
       }, {
           "value": 3,
           "label": 'CONTADOR'
       }, {
           "value": 1,
           "label": 'CONTRATISTA'
       }, {
           "value": 2,
           "label": 'CORREDORES DE SEGUROS'
       }, {
           "value": 3,
           "label": 'CORREDORES INMOBILIARIOS'
       }, {
           "value": 1,
           "label": 'COSTURERO/A'
       }, {
           "value": 1,
           "label": 'DECORADOR/A'
       }, {
           "value": 2,
           "label": 'DEPORTISTA'
       }, {
           "value": 1,
           "label": 'DESABOLLADOR'
       }, {
           "value": 2,
           "label": 'DESOCUPADO/A'
       }, {
           "value": 2,
           "label": 'DIRECTOR/A DE INSTITUCIÓN EDUCATIVA'
       }, {
           "value": 1,
           "label": 'EBANISTA'
       }, {
           "value": 2,
           "label": 'ECONOMISTA'
       }, {
           "value": 1,
           "label": 'ELECTRICISTA'
       }, {
           "value": 1,
           "label": 'EMPLEADO/A PRIVADO'
       }, {
           "value": 2,
           "label": 'EMPLEADO/A PUBLICO'
       }, {
           "value": 2,
           "label": 'EMPRESARIO/A'
       }, {
           "value": 1,
           "label": 'ENCARGADO/A'
       }, {
           "value": 1,
           "label": 'ENFERMERO/A'
       }, {
           "value": 1,
           "label": 'ENTRENADOR/A FÍSICO'
       }, {
           "value": 1,
           "label": 'ESTADISTICO'
       }, {
           "value": 2,
           "label": 'ESTETICA'
       }, {
           "value": 1,
           "label": 'ESTILISTA DE BELLEZA'
       }, {
           "value": 1,
           "label": 'ESTUDIANTE'
       }, {
           "value": 2,
           "label": 'FARMACEUTICO/A'
       }, {
           "value": 2,
           "label": 'FERRETERO'
       }, {
           "value": 2,
           "label": 'FINANCIERO/A'
       }, {
           "value": 1,
           "label": 'FOTOGRAFO/A'
       }, {
           "value": 2,
           "label": 'GANADERO/A'
       }, {
           "value": 2,
           "label": 'GERENTE'
       }, {
           "value": 1,
           "label": 'GESTION DE CALIDAD'
       }, {
           "value": 1,
           "label": 'GUIA TURISTICO'
       }, {
           "value": 2,
           "label": 'HACENDADO'
       }, {
           "value": 1,
           "label": 'HERRERO'
       }, {
           "value": 3,
           "label": 'INFORMÁTICA'
       }, {
           "value": 3,
           "label": 'INGENIERO ELECTRICO'
       }, {
           "value": 2,
           "label": 'INGENIERO INDUSTRIAL'
       }, {
           "value": 2,
           "label": 'INGENIERO/A'
       }, {
           "value": 3,
           "label": 'INSPECTOR'
       }, {
           "value": 3,
           "label": 'INVERSIONISTA'
       }, {
           "value": 1,
           "label": 'JARDINERO'
       }, {
           "value": 2,
           "label": 'JUGADOR DE BEISBOL'
       }, {
           "value": 2,
           "label": 'LICENCIADO EN FISICA'
       }, {
           "value": 2,
           "label": 'LICENCIADO EN LETRAS'
       }, {
           "value": 3,
           "label": 'LOBISTA'
       }, {
           "value": 2,
           "label": 'LOCUTOR'
       }, {
           "value": 1,
           "label": 'MAESTRO CONSTRUCTOR'
       }, {
           "value": 2,
           "label": 'MAESTRO/A'
       }, {
           "value": 1,
           "label": 'MATEMATICO'
       }, {
           "value": 2,
           "label": 'MECANICO'
       }, {
           "value": 2,
           "label": 'MEDICO'
       }, {
           "value": 1,
           "label": 'MENSAJERO'
       }, {
           "value": 1,
           "label": 'MERCADOLOGO/A'
       }, {
           "value": 3,
           "label": 'MILITAR'
       }, {
           "value": 1,
           "label": 'MOTOCONCHISTA'
       }, {
           "value": 1,
           "label": 'MUSICO'
       }, {
           "value": 1,
           "label": 'NUTRICIONISTA'
       }, {
           "value": 1,
           "label": 'OBRERO'
       }, {
           "value": 1,
           "label": 'ODONTOLOGO/A'
       }, {
           "value": 1,
           "label": 'OFICIAL DE NEGOCIOS'
       }, {
           "value": 1,
           "label": 'OFICINISTA'
       }, {
           "value": 1,
           "label": 'OPERADOR EQUIPOS'
       }, {
           "value": 1,
           "label": 'OPERARIO'
       }, {
           "value": 1,
           "label": 'OPTOMETRISTA'
       }, {
           "value": 1,
           "label": 'ORGANIZADOR DE EVENTOS'
       }, {
           "value": 1,
           "label": 'PANADERO'
       }, {
           "value": 3,
           "label": 'PASTOR/A'
       }, {
           "value": 1,
           "label": 'PEDIATRA'
       }, {
           "value": 3,
           "label": 'PELUQUERO/A'
       }, {
           "value": 2,
           "label": 'PENSIONADO'
       }, {
           "value": 2,
           "label": 'PERIODISTA'
       }, {
           "value": 1,
           "label": 'PESCADOR'
       }, {
           "value": 2,
           "label": 'PILOTO'
       }, {
           "value": 1,
           "label": 'PINTOR'
       }, {
           "value": 1,
           "label": 'PLOMERO'
       }, {
           "value": 3,
           "label": 'POLICIA'
       }, {
           "value": 3,
           "label": 'POLITICO'
       }, {
           "value": 3,
           "label": 'PRESIDENTE DE EMPRESAS'
       }, {
           "value": 3,
           "label": 'PRODUCTOR TV'
       }, {
           "value": 2,
           "label": 'PROFESIONAL INDEPENDIENTE'
       }, {
           "value": 2,
           "label": 'PROFESIONAL OTROS'
       }, {
           "value": 3,
           "label": 'PROPIETARIO'
       }, {
           "value": 1,
           "label": 'PSICÓLOGO/A'
       }, {
           "value": 1,
           "label": 'PUBLICISTA'
       }, {
           "value": 1,
           "label": 'QUEHACERES DOMESTICOS'
       }, {
           "value": 2,
           "label": 'QUIMICO'
       }, {
           "value": 1,
           "label": 'RECEPCIONISTA'
       }, {
           "value": 2,
           "label": 'RECEPTOR DE REMESAS FAMILIARES'
       }, {
           "value": 2,
           "label": 'RECEPTOR DE RENTAS (ALQUILERES)'
       }, {
           "value": 1,
           "label": 'REPOSTERO'
       }, {
           "value": 1,
           "label": 'REPRESENTANTE TELEFONICO'
       }, {
           "value": 3,
           "label": 'SACERDOTE'
       }, {
           "value": 1,
           "label": 'SECRETARIA/O'
       }, {
           "value": 2,
           "label": 'SEGURIDAD'
       }, {
           "value": 1,
           "label": 'SERVICIOS'
       }, {
           "value": 1,
           "label": 'SOCIOLOGO'
       }, {
           "value": 1,
           "label": 'SOLDADOR'
       }, {
           "value": 2,
           "label": 'SUPERVISOR/A'
       }, {
           "value": 1,
           "label": 'TAXISTA'
       }, {
           "value": 1,
           "label": 'TECNICO'
       }, {
           "value": 1,
           "label": 'TOPOGRAFO/A'
       }, {
           "value": 1,
           "label": 'TRANSPORTISTA'
       }, {
           "value": 1,
           "label": 'VENDEDOR/A'
       }, {
           "value": 1,
           "label": 'VETERINARIO/A'
       }, {
           "value": 3,
           "label": 'VICE-PRESIDENTE DE EMPRESAS'
       }, {
           "value": 1,
           "label": 'VISITADOR/A MEDICO'
       },

    
]}

 return options[getSelectedInput]
}

export {GetOptions}
import React, { useState,Fragment } from 'react';
import * as Subheader from '../services/Subheader'
import * as Service from '../services/Create'
import  { API }  from '../config';
import axios from 'axios';
import Select from 'react-select';
import './Home.css'


export default function Admin (props){
 const fields = {"data":{
                        "Empresas":[
                            {"label":"RNC","name":"rnc","type":"text"},
                            {"label":"Nombre Comercial","name":"nombre_comercial","type":"text"},
                            {"label":"Razon Social","name":"razon_social","type":"text"},
                            {"label":"Dirección","name":"direccion","type":"text-area"},
                            {"label":"Telefono","name":"telefono","type":"text"},
                            {"label":"Tipo Sujeto Obligado","name":"id_sujeto_obligado","type":"select","options":[
                                {"value":1,"label":"Ventas de vehiculos"},
                                {"value":2,"label":"Corredores de seguros"},
                                {"value":3,"label":"Construcción"},
                                {"value":4,"label":"Bancos"},
                            ]},
                            
                        ],
                        "Usuarios":[
                            {"label":"Usuario","name":"usuario","type":"text"},
                            {"label":"Contraseña","name":"contrasena","type":"password"},
                            {"label":"Correo","name":"correo","type":"emaiil"},
                            {"label":"Empresa","name":"empresa","type":"text"},
                            {"label":"Rol","name":"rol","type":"select",
                            "options":[
                                {"value":0,"label":"Super Admin"},
                                {"value":1,"label":"Empresa"},
                                {"value":2,"label":"Cumplimiento"},
                                {"value":3,"label":"Usuario Basico"},
                            ]}
                        ]}
                        }
                        

const getForm = () => {
        let from = props.page
        return (
                     <div className='row'>
                   
                     <form id={from} >
                    {fields['data'][props.page].map(form => 
                        form.type === "select" ? //if
                         <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">{form.label}</label>
                            <div className="col-sm-8">
                                <Select Placeholder="Selecciona" name={form.name} className="selectWithSearch" id={form.name} options={form["options"]}/>
                              
                            </div>
                        </div>
                        : form.type === "text-area" ? //else-if
                          <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">{form.label}</label>
                            <div className="col-sm-8">
                                <textarea name={form.name} type={form.type} className="form-control" id={form.name} />
                            </div>
                        </div> 
                        : //else
                          <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">{form.label}</label>
                            <div className="col-sm-8">
                                <input name={form.name} type={form.type} className="form-control" id={form.name} />
                            </div>
                        </div>
                    )}
                     <a name={from} href="#" onClick={(e) => console.log(e)} className="card-link btn float-end" style={{"backgroundColor": "#f1824f", "color":"white"}} >Crear {from}</a>
                      </form>
                      </div>

)

}
    


        
        return (
           
            <div className="container mt-5 text-start">
                <Subheader.setHeader page="Usuarios" />
                <br/>
                <div className="row">
                    <div className='col-lg-6 col-sm-12'>
                         <div className='card p-4'>
                   {
                       getForm()
                   }
                    </div>
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                       
                     
                    
                    </div>

                    <div className='row'>
                   3
                    </div>
                </div>

                        
            </div>
           


        )
    }


import React, { useState,Fragment,useEffect } from 'react';
import  { API }  from '../config';
import axios from 'axios';
import GetOptions from '../dataLayer/Data';

 function ShowClientDetail(props) {
    const URL = API.URL;
    const formdata = new FormData();
    formdata.append("action", "getClientDetails");
    formdata.append("clientID", props.clientId);
    const [renderDom, setRenderDom] = useState("");
    
      useEffect(() => {
             axios.post(URL, formdata, {})
                .then(res => { 
                    const error = res.data.error ? res.data.message : false
                    if(error) { throw new Error(res.data.message) }
                    const fetchData = res.data.message[0]
                    const labelForData = GetOptions("clientDetailsLabel")
                    const clientTypeId = fetchData['tipo_de_cliente'] === 'Individual' ? 1 : 0
                    const clientDetailMergedData = labelForData[clientTypeId][fetchData['tipo_de_cliente']].map(({ key, value, position}) => ({ ["label"] : value,["value"] : fetchData[key],["position"] : position, }))
                   
                    const clientDataWrapper = (value,key) => 
                    value.value ?
                     value.position == 2 ?
                         <div className="row mb-3">
                            <label className="col-sm-5 col-form-label">{value.label}</label>
                            <div className="col-sm-7">
                            <a className="btn btn-success" href={value.value}>Descargar</a>
                            </div>
                        </div> 
                    : //else
                        <div className="row mb-3">
                        <label className="col-sm-5 col-form-label">{value.label}</label>
                        <div className="col-sm-7">
                        <input type="text" className="form-control" id={key} value={value.value} readOnly />
                        </div>
                    </div>  
                    : "" //else
                     const clientDeail = clientDetailMergedData.filter(pos => pos.position == 1).map(clientDataWrapper)
                     const clientAttachment = clientDetailMergedData.filter(pos => pos.position == 2).map(clientDataWrapper)
                     const clientOrder = clientDetailMergedData.filter(pos => pos.position == 3).map(clientDataWrapper)
                    console.log(clientAttachment)
                   
                      const cart = <div className='card my-4'>
                                        <form className='p-3 float-start'>
                                            <h4>Cliente</h4><hr />
                                            <div className='row p-3'>
                                            <div className='card shadow-sm col-xl-7 p-3'>
                                            <h5>Datos del cliente</h5>
                                            <hr />
                                            {clientDeail}
                                            </div>
                                            <div className='col-xl-5 '>
                                            <div className='card shadow-sm p-3'>
                                            <h5>Datos de la transacción</h5>
                                            <hr />
                                            {clientOrder}
                                            </div>
                                            <div className='card shadow-sm mt-4 p-3'>
                                                <h5>Adjuntos</h5>
                                                <hr />
                                                {clientAttachment}
                                                </div>

                                            </div>
                                           
                                            </div>
                                        </form>
                                    </div>
                    setRenderDom(cart)

                }).catch(function(e) {
                    console.error(e.message); })

      }, [props.clientId]);


     
  

    return <Fragment>{renderDom}</Fragment>
}   


 export { ShowClientDetail }
import React, { Component } from 'react'
import {ShowClientDetail} from '../services/GetData';
import './Home.css'


export default class Home extends Component {
  
    componentDidMount() {
   
    }

    render() {
        return (
            <div className="container-fluid">
                <ShowClientDetail clientId = '138' />
            </div>
    


        )
    }
}

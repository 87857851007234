import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from './containers/Login'
import NotFound from './containers/NotFound'
import Home from './containers/Home'
import Clientes from  './containers/Clientes'
import ClientesCreated from './containers/ClientesCreated'
import Reportes from './containers/Reportes'
import Preguntas from './containers/Preguntas'
import Usuarios from './containers/Usuarios'
import SujetoObligado from './containers/SujetoObligado'
import Alertas from './containers/Alertas'
import Respuestas from './containers/Respuestas'
import ReportesResults from './containers/ReportesResults'
import AppliedRoute from './componets/AppliedRoute'
import Admin from './containers/Admin'



export default ({ childProps }) => 

   
    <Switch>
        <AppliedRoute  path="/" exact component={Home} props={childProps} />
        <AppliedRoute  path="/login" exact component={Login} props={childProps} />
        <AppliedRoute  path="/Clientes" exact component={Clientes} props={childProps} />
        <AppliedRoute  path="/created" exact component={ClientesCreated} props={childProps} />
        <AppliedRoute  path="/Reportes" exact component={Reportes} props={childProps} />
        <AppliedRoute  path="/Results" exact component={ReportesResults} props={childProps} />
        <AppliedRoute  path="/Alertas" exact component={Alertas} props={childProps} />
        <AppliedRoute  path="/Preguntas" exact component={Preguntas} props={childProps} />
        <AppliedRoute  path="/Usuarios" exact component={Usuarios} props={childProps} />
        <AppliedRoute  path="/SujetoObligado" exact component={SujetoObligado} props={childProps} />
        <AppliedRoute  path="/Respuestas" exact component={Respuestas} props={childProps} />
        <AppliedRoute  path="/Admin" exact component={Admin} props={{"page":"home"}} />
        <AppliedRoute  path="/Admin/Usuarios" exact component={Admin} props={{"page":"Usuarios"}} />
        <AppliedRoute  path="/Admin/Empresas" exact component={Admin} props={{"page":"Empresas"}} />
        <Route component={NotFound} />
    </Switch>

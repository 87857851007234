import React, { Component } from 'react'
import * as Subheader from '../services/Subheader'
import * as Service from '../services/Create'
import './Home.css'





export default class Clientes extends Component {
  
        constructor(props) {
        super(props)
  
        this.state = {
            Form: "",
            Result: "",
            TableList: "",
           
        }
      
    
    }
   

    componentDidMount() {
        this.setState({Form: <Service.Controller action="showForm" page="Reportes" />})
   
    }


    render() {

        
        return (
            <div className=" mt-5 text-start">
                <div className="row">
                    
                    {this.state.Form}
                    
                </div>

                        
            </div>


        )
    }
}

import React, { Component } from 'react'
import '../containers/Home.css'
import { NavLink,NavLinkList } from 'react-router-dom'





export default class ApplicationHeader extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            userRol: "",
            userlogo: "../img/Empresa_logo/incompliance_long.png"
            
        }
        
    }

    async componentDidMount() {
   

      try {
        const USER_SESSION = JSON.parse(sessionStorage.getItem("ICUSERSESSION"))
        if (!USER_SESSION) throw "Session expired, please sign in again."
       this.setState({
         userRol: USER_SESSION.ROLEID,
         userlogo: USER_SESSION.LOGO
       })
       
      } catch (e) {
        console.log(e)
       
      }
      
     

    }
    handleLogout = async  event => {
        sessionStorage.removeItem('ICUSERSESSION')
        window.location.assign('/')
    }
      
       menu = (rol) => {
        // Rol, 0 = admin, 1 = business_admin, 2 = compliance_officer, 3 = basic_user 
        let navigation;
        switch (rol) {
          case 0: 
          navigation = ["Clientes", "Reportes", "Alertas"]
          break
          case 1:
            navigation = ["Clientes", "Reportes", "Alertas"]
            break;
          case 2:
            navigation = ["Clientes", "Reportes", "Alertas"]
            break;
          case 3:
            navigation = ["Clientes"]
            break;
          default:
            navigation = "";
            break;
        }

        return navigation
      }
    render() {
       
        const USER_SESSION = JSON.parse(sessionStorage.getItem("ICUSERSESSION"))
         const menu = Array.from(this.menu(parseInt(this.state.userRol)))
         const adminMEnu =  ["Empresas","Usuarios"]
         
        return (
<div className="container-fluid" style={{'width': '100%'}}>
    <nav  className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid" >
    <a className="navbar-brand" href="#" id="brandLogo"><img style={{'width': '210px'}} src={USER_SESSION.LOGO} /></a>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
        {
          parseInt(this.state.userRol) == 0 ? adminMEnu.map((val, index) =>
           
            <li  className="nav-item mx-2 mt-2" key={index}>
              <NavLink style={{'width': '100%'}} className={"p-2 btn btn-outline-primary m-1"} to={"../Admin/" + val}>{val}</NavLink>
              </li>
           
           )
           : ""
        }
      
      </ul>
      <ul className="navbar-nav">
       
           {
           menu.map((val, index) =>
           
            <li className="nav-item m-2" key={index}>
              <NavLink style={{'width': '100%'}} activeClassName="active" className={"p-2 btn btn-outline-primary m-1"} to={"../" + val}>{val}</NavLink>
              </li>
           
           )
           
           }
     <li className="dropdown nav-item m-2">
  <a style={{'width': '100%'}} className="btn btn-outline-success dropdown-toggle p-2 m-1" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    {USER_SESSION.USERFULLNAME}
  </a>

  <ul className="dropdown-menu">
    <li><a className="dropdown-item" href="#">Cambiar contraseña</a></li>
  </ul>
</li>
          <li className="nav-item m-2"><button style={{'width': '100%'}} onClick={this.handleLogout} className="p-2 btn btn-outline-danger m-1" type="button">Salir </button></li>

       
   
      </ul>
    </div>
  </div>
</nav>
</div> 
        )}
}


    
const REQUEST = {
     TYPE_JSON: 'application/json',
     METHOD_POST: 'post',
     METHOD_GET: 'get',
     METHOD_PUT: 'put',
     MODE: 'cors'
}
const API = {
     //URL: 'http://localhost/dealear/backend/'
   // URL: 'http://localhost:8888/matrix2-master/backend/'
   URL: 'https://mat.incomplianceglobalsolutions.com/backend/'
}


export {REQUEST, API}



